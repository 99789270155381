import { React, useState, memo } from 'react';
import Chart from '../../../components/Chart/chart';
import { options } from '../../../Constants/performance';
import '../style.scss';
import { formatPrice } from '../../../utils/formatPrice';
import { TbChartLine } from 'react-icons/tb';

const ChartSub = memo(
  ({ Equities, DrawDowns, Holds, Measures, Theme=true, InitialCap = 1000, loading=true }) => {
    return (
      <div>
        <div>
          <div>
            <div>
              {/* Performance Summary of the chart section*/}
              <div
                className="WizardPage-Inputs-div"
                style={{ paddingTop: '15px' }}
              >
              <div className="WizardPage-text-and-description">
                  <div
                    className={'WizardPage-Inputs-text flex'}
                  >
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {formatPrice(InitialCap)}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                    <p
                      className="flex px-1"
                      style={{
                        fontSize: '12px',
                        color: 'gray',
                        paddingTop: '2px',
                      }}
                    >
                      USDT
                    </p>
                  </div>
                  <p className="WizardPage-description">Initial Cap</p>
                </div>
                <div className="WizardPage-text-and-description">
                  <div
                    className={
                      Measures?.net_profit > 0
                        ? 'WizardPage-Inputs-text SP-C-green flex'
                        : 'WizardPage-Inputs-text SP-C-red flex'
                    }
                  >
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {Measures?.net_profit || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-400 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                    <p
                      className="flex px-1"
                      style={{
                        fontSize: '12px',
                        color: 'gray',
                        paddingTop: '2px',
                      }}
                    >
                      USDT
                    </p>
                  </div>
                  <p className="WizardPage-description">Net Profit</p>
                </div>
                <div className="WizardPage-text-and-description">
                  <b
                    className={
                      'WizardPage-Inputs-text'
                    }
                  >
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {Measures?.sharpe_ratio || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                  </b>
                  <p className="WizardPage-description">Sharpe Ratio</p>
                </div>
                <div className="WizardPage-text-and-description">
                  <div className={'WizardPage-Inputs-text flex'}>
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {Measures?.winning_ratio || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                    <p
                      className="flex px-1"
                      style={{
                        fontSize: '15px',
                        color: 'gray',
                        paddingTop: '2px',
                      }}
                    >
                      %
                    </p>
                  </div>
                  <p className="WizardPage-description">Percent Profitable</p>
                </div>
              </div>
              <div
                className="WizardPage-Inputs-div"
                style={{ paddingBottom: '15px' }}
              >
                <div className="WizardPage-text-and-description">
                  <div
                    className={
                      'WizardPage-Inputs-text SP-C-green flex text-black dark:text-white'
                    }
                  >
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {formatPrice(Equities ? Equities[Equities.length - 1] : 0) || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                    <p
                      className="flex px-1"
                      style={{
                        fontSize: '12px',
                        color: 'gray',
                        paddingTop: '2px',
                      }}
                    >
                      USDT
                    </p>
                  </div>
                  <p className="WizardPage-description">Equity</p>
                </div>
                <div className="WizardPage-text-and-description">
                  <div className={'WizardPage-Inputs-text flex'}>
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {Measures?.average_trades || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                    <p
                      className="flex px-1"
                      style={{
                        fontSize: '15px',
                        color: 'gray',
                        paddingTop: '2px',
                      }}
                    ></p>
                  </div>
                  <p className="WizardPage-description">Average Trades</p>
                </div>
                <div className="WizardPage-text-and-description">
                  <div className={'WizardPage-Inputs-text flex SP-C-red'}>
                    {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                    {Measures?.maximum_draw_down || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                    <p
                      className="flex px-1"
                      style={{
                        fontSize: '12px',
                        color: 'gray',
                        paddingTop: '2px',
                      }}
                    >
                      USDT
                    </p>
                  </div>
                  <p className="WizardPage-description">Max Drawdown</p>
                </div>
                <div className="WizardPage-text-and-description">
                  {!loading ? (
                      <div className={'WizardPage-Inputs-text flex '}>
                      {Measures?.profit_factor || 0}
                    </div>
                    ): (
                      <div className='animate-pulse bg-gray-200 rounded-md w-[4rem] h-2 my-2 flex justify-center items-center'>
                      </div>
                    )
                  }
                  <p className="WizardPage-description">Profit Factor</p>
                </div>
              </div>
            </div>
            {!loading ? (
              <Chart Options={options(Equities, DrawDowns, Holds, Theme)} />
            ) : (
            <div className="animate-pulse first-div text-black dark:text-white w-full p-2 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md flex flex-col">
              <div className="w-full h-[350px]">
                <div
                  className="animate-pulse"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <TbChartLine
                    style={{
                      color: 'gray',
                      height: '30%',
                      width: '30%',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    );
  }
);

export default ChartSub;
