import React from 'react';
import { useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Title from '../../components/Title';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic';
import './style.scss';
import { useSelector } from 'react-redux';
import { RecentSignals } from '../../components/RecentSignal';
import { sample_data } from '../../Constants/recentSignals';
import { columns } from '../../Constants/screenerTable';
import { Table } from '../../components/Table';
import { history } from '../../utils/history';
import {
  select_coins,
  select_page,
  select_strategies,
  select_feature_sets,
  select_learning_models,
  select_labeling_methods,
  select_position_types,
  select_timeframes,
  select_lookback,
  get_coins,
  get_strategies,
  getFeatureTransforms,
  getLabelTransforms,
  getModels,
  get_signals,
} from './slice';

function PulsePage() {
  const { dispatch } = history;
  // reducer state
  const theme = useSelector((state) => state.overall.theme);
  const state = useSelector((state) => state.pulse);
  const selected_coins = state.selected_coins;
  const available_coins = state.available_coins;
  const available_feature_sets = state.available_feature_sets;
  const selected_feature_sets = state.selected_feature_sets;
  const available_labeling_methods = state.available_labeling_methods;
  const selected_labeling_methods = state.selected_labeling_methods;
  const available_learning_models = state.available_learning_models;
  const selected_learning_models = state.selected_learning_models;
  const available_strategies = state.available_strategies;
  const selected_strategies = state.selected_strategies;
  const selected_position_types = state.selected_position_types;
  const available_position_types = state.available_position_types;
  const selected_timeframes = state.selected_timeframes;
  const available_timeframes = state.available_timeframes;
  const signals = state.signals;
  const get_signals_status = state.get_signals_status;
  const page = state.page;

  // Side Effects
  useEffect(() => {
    // get coins and strategies from backend
    dispatch(get_coins());
    dispatch(getFeatureTransforms());
    dispatch(getLabelTransforms());
    dispatch(getModels());
    dispatch(get_strategies());
    dispatch(get_signals());
  }, []);

  useEffect(() => {
    const data = {
      page,
      symbol: selected_coins
        .filter((x) => x.value !== 'all')
        .map((x) => x.label),
      timeframe: selected_timeframes
        .filter((x) => x.value !== 'all')
        .map((x) => x.value),
      lt: selected_labeling_methods
        .filter((x) => x.value !== 'all')
        .map((x) => x.value),
      ft: selected_feature_sets
        .filter((x) => x.value !== 'all')
        .map((x) => x.value),
      dlm: selected_learning_models
        .filter((x) => x.value !== 'all')
        .map((x) => x.value),
      strategy: selected_strategies
        .filter((x) => x.value !== 'all')
        .map((x) => x.value),
      side: selected_position_types
        .filter((x) => x.value !== 'all')
        .map((x) => x.value),
    };
    dispatch(get_signals(data));
  }, [
    selected_coins,
    selected_timeframes,
    selected_labeling_methods,
    selected_feature_sets,
    selected_learning_models,
    selected_strategies,
    selected_position_types,
    page,
  ]);

  return (
    <DashboardLayout>
      <div className="signal-scanner">
        <Title
          title="Trade Pulse"
          subtitle="Stay Informed with Rapid Updates on Trading Signals"
          isPageTitle
        />
        <div className="bg-dark_secondary rounded-md">
          <div className="first-div text-black dark:text-white w-full my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
            <Title
              title="Configuration Filter"
              subtitle="Personalized Insights: Customize Your Trading Strategy Signals"
            />
            <div className="flex-col py-3">
              <div className="flex justify-center gap-16 my-3">
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Symbol"
                  selectedOptions={selected_coins}
                  options={available_coins}
                  isMulti
                  className="select-analys-type"
                  onChange={(items) => {
                    // dispatch(select_coins({selected: items ? items : [], available: available_coins}));
                    dispatch(select_coins(items ? items : []));
                  }}
                />
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label='Time Frame'
                  selectedOptions={selected_timeframes}
                  options={available_timeframes}
                  isMulti
                  onChange={(items) =>
                    dispatch(select_timeframes(items ? items : []))
                  }
                />
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Position Type"
                  selectedOptions={selected_position_types}
                  options={available_position_types}
                  isMulti
                  onChange={(items) =>
                    dispatch(select_position_types(items ? items : []))
                  }
                />
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Labeling Method"
                  selectedOptions={selected_labeling_methods}
                  options={available_labeling_methods}
                  isMulti
                  onChange={(items) =>
                    dispatch(select_labeling_methods(items ? items : []))
                  }
                />
              </div>
              <div className="flex justify-center gap-16">
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Feature Set"
                  selectedOptions={selected_feature_sets}
                  options={available_feature_sets}
                  isMulti
                  onChange={(items) =>
                    dispatch(select_feature_sets(items ? items : []))
                  }
                />
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Learning Model"
                  selectedOptions={selected_learning_models}
                  options={available_learning_models}
                  isMulti
                  onChange={(items) =>
                    dispatch(select_learning_models(items ? items : []))
                  }
                />
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Strategy"
                  selectedOptions={selected_strategies}
                  options={available_strategies}
                  isMulti
                  onChange={(items) =>
                    dispatch(select_strategies(items ? items : []))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <Table
            title={'Recent Signal Report'}
            subtitle={
              'Stay Updated: Latest Entry Signals at a Glance'
            }
            loading={get_signals_status !== 'fulfilled'}
            ActiveLookBack
            setActiveLookBack
            current_page={page}
            height_in_px={60}
            set_current_page={(page) => {
              dispatch(select_page(page));
            }}
            page_size={10}
            total_items={signals.total_items}
            columns={columns}
            rows={signals?.results ? signals.results : []}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default PulsePage;
