import { useEffect } from 'react';
import LocalStorageService from '../../api/LocalStoragrService';
import { history } from '../../utils/history';

const localStorageService = LocalStorageService.getService();

const RequireAuth = ({ children }) => {
  const { navigate } = history;
  useEffect(() => {
    const token = localStorageService.getAccessToken();
    const isAuthenticated = !!token;
    if (!isAuthenticated) {
      navigate('/login');
    }
  });
  return children;
};

export default RequireAuth;
