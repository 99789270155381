import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../utils/history';
import {validate_time} from '../../utils/validators'
import { toast } from 'react-toastify';
import './style.scss';
import './subs/StrategyRow/style.scss';
import DashboardLayout from '../../components/DashboardLayout';
// import { options as scatter_options } from '../../Constants/scatter';
import { EfficientFrontierPlot } from '../../Constants/efficient_frontier';
import Chart from '../../components/Chart/chart';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic';
import BottomInput from '../../components/BottomInput';
import CalendarInput from '../../components/CalendarInput';
import StrategyRow from './subs/StrategyRow';
import ProgressBar from '../../components/ProgressBar';
import { BsPlusSquare } from 'react-icons/bs';
import { pieChartOptions } from '../../Constants/piechart';
import { BarChartOptions } from '../../Constants/bar';
import { lineOptions, monteCarloOptions } from '../../Constants/linechart';
import { PercentileChartPlot } from '../../Constants/bar';
import candleLoading from '../../assets/images/candleLoading.GIF';
import moment from 'moment';

import {
  get_analysis_types,
  getCoins,
  analyzePortfolio,
  select_analysis_type,
  select_method,
  select_lookback_method,
  select_start_timestamp,
  set_initial_capital,
  set_holding_period,
  remove_asset,
  add_asset,
  update_asset,
  set_risk_free_rate,
  select_holding_period_method,
} from './slice';
import Title from '../../components/Title';

function PortfolioAnalyzer() {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const bottomRef = useRef(null);
  const { dispatch } = history;

  // states
  const theme = useSelector((state) => state.overall.theme);
  const analyzer = useSelector((state) => state.analyzer);
  const analysis_types = analyzer.available_analysis_types;
  const methods = analyzer.available_methods;
  const position_types = analyzer.available_position_types;
  const analysis_type = analyzer.selected_analysis_type;
  const holding_period_methods = analyzer.available_holding_period_methods;
  const holding_period_method = analyzer.selected_holding_period_method;
  const method = analyzer.selected_method;
  const lookback_methods = analyzer.available_lookback_methods;
  const lookback_method = analyzer.selected_lookback_method;
  const start_timestamp = analyzer.start_timestamp;
  const initial_capital = analyzer.initial_capital;
  const risk_free_rate = analyzer.risk_free_rate;
  const assets = analyzer.assets;
  const total_percentage = analyzer.total_percentage;
  const holding_period = analyzer.holding_period;
  const coins = analyzer.coins;
  const results_status = analyzer.analyzePortfolio_status;
  const initial_weights = analyzer.initial_weights;
  const adjusted_weights = analyzer.adjusted_weights;
  const optimal_weights = analyzer.optimal_weights;
  const portfolio_equities = analyzer.portfolio_equities;
  const portfolio_equities_chart = analyzer.portfolio_equities_chart;
  const stdevs = analyzer.stdevs;
  const expected_returns = analyzer.expected_returns;
  const initial_point = analyzer.initial_point;
  const adjusted_point = analyzer.adjusted_point;
  const optimal_point = analyzer.optimal_point;
  const monte_carlo = analyzer.monte_carlo;
  const percentile_chart = analyzer.percentile_chart;

  const now = new Date();
  const aMonthAgo = new Date();
  aMonthAgo.setMonth(now.getMonth() - 1);

  // side effects
  useEffect(() => {
    dispatch(get_analysis_types());
    dispatch(getCoins());
    dispatch(select_start_timestamp(Math.floor(aMonthAgo.getTime() / 1000)));
  }, []);

  useEffect(() => {
    if (results_status === 'fulfilled') {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [results_status]);

  const submit = () => {

    const normal_assets = assets.map((x) => {
      return { ...x, asset_allocation: x.asset_allocation / 100 };
    });

    const start_date = new Date(start_timestamp * 1000);
    const is_forward = analysis_type.value == 'forward';

    const validation = validate_time(start_date, holding_period, is_forward)

    if (1 > holding_period || holding_period > 180) {
      toast.warn("Holding period should be a number between 2 and 180")
    } else if (start_date > new Date()) {
      toast.warn("Reference date cannot exceed today")
    } else if (!validation.is_valid) {
    if (is_forward) {
      toast.warn(
        'In forward analysis the starting date plus holding period should exceed today'
      );
    } else {
      toast.warn(
        'In backward anaysis the starting date plus holding period should not exceed today'
      );
    }
    } else {
      dispatch(
        analyzePortfolio({
          asset_list: normal_assets,
          analysis_type: analysis_type.value,
          risk_free_rate,
          lookback_method: lookback_method.value,
          samples_count: 50,
          method: method.value,
          holding_period,
          holding_period_method: holding_period_method.value,
          start_date: start_date.toISOString().split('T')[0],
          initial_cap: initial_capital,
        })
      );
    }
  };

  return (
    <DashboardLayout>
      <Title
        title="Portfolio Analyzer"
        subtitle="Empower Your Investments using Our Portfolio Simulator and Optimizer"
        isPageTitle
      />

      <div className="first-div text-black dark:text-white w-full my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
        <Title
          title="Custom Portfolio Configuration"
          subtitle="Tailor Your Investment Strategy: Input Parameters and Configuration Settings"
        />
        <div className="first-row-select flex-col model-config">
          <div className="flex justify-center gap-16">
            <ButtonSelectDynamic
              dark={theme}
              isRequired
              label="Analysis Type"
              selectedOptions={analysis_type}
              options={analysis_types}
              className="select-analys-type"
              onChange={(item) => {
                if (item.label == 'Forward') {
                  dispatch(select_start_timestamp(Math.floor(new Date().getTime() / 1000)));
                } else {
                  dispatch(select_start_timestamp(Math.floor(aMonthAgo.getTime() / 1000)));
                }
                dispatch(select_analysis_type(item ? item : ''));
              }
              }
            />
            <ButtonSelectDynamic
              dark={theme}
              isRequired
              label="Method"
              selectedOptions={method}
              options={methods}
              onChange={(item) => dispatch(select_method(item ? item : ''))}
            />
            <ButtonSelectDynamic
              dark={theme}
              isRequired
              label="Data Source"
              selectedOptions={lookback_method}
              options={lookback_methods}
              onChange={(item) => dispatch(select_lookback_method(item ? item : ''))}
            />
            <CalendarInput
              label="Reference Date"
              placeholder="Reference Date"
              show={showDatePicker}
              setShow={(value) => {
                setShowDatePicker(value)
              }}
              value={new Date(start_timestamp * 1000)}
              onFocus={(date) => {
                dispatch(select_start_timestamp(Math.floor(new Date(date).getTime() / 1000)))
                setShowDatePicker(false)
              }}
              onChangeFunc={(dateTime) =>
                dispatch(select_start_timestamp(Math.floor(dateTime / 1000)))
              }
              minDate='2021/01/01'
              maxDate={moment(new Date()).format("YYYY/MM/DD")}
              dark={theme}
            />
          </div>
          <div className="flex justify-center gap-16">
          {/* <ButtonSelectDynamic
              isRequired
              label="Holding Period Method"
              selectedOptions={holding_period_method}
              options={holding_period_methods}
              onChange={(item) => dispatch(select_holding_period_method(item ? item : ''))}
            /> */}
            <BottomInput
              label={'Holding Period'}
              type="number"
              value={holding_period}
              onChange={(event) =>
                dispatch(
                  set_holding_period(
                    event.target.value ? parseInt(event.target.value) : ''
                  )
                )
              }
            />
            {/* <BottomInput
              label={'Risk Free Rate'}
              endIcon={<>%</>}
              type="number"
              value={risk_free_rate != 0 ? parseFloat(risk_free_rate * 100) : null}
              onChange={(event) =>
                dispatch(set_risk_free_rate(parseFloat(event.target.value / 100)))
              }
            /> */}
            <BottomInput
              endIcon={<>USDT</>}
              label={'Initial Capital'}
              type="number"
              value={initial_capital}
              onChange={(event) =>
                dispatch(set_initial_capital(parseInt(event.target.value)))
              }
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center p-5 flex-col bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
        <div className='w-full'>
          <Title
            className={'mb-3'}
            title="Portfolio Composition Input"
            subtitle="Define Your Holdings: Enter Asset Names, Position Types, and Weights"
          />
        </div>
        <div className="portfolio-table text-black dark:text-white">
          {/* <DynamicTable /> */}
          <div className="smart-portfolio__row pr-6 bg-light-back dark:bg-dark-back w-5/6 rounded-md">
            <div className="smart-portfolio__row-left bf-black">
              <div className="smart-portfolio__row-col">Asset ID</div>
              <div className="smart-portfolio__row-col">Asset</div>
              <div className="smart-portfolio__row-col">Position Type</div>
              <div className="smart-portfolio__row-col">Weight (Percent)</div>
            </div>
          </div>
          {assets.map((asset, key) => (
            <StrategyRow
              dark={theme}
              key={key}
              index={key}
              coins={coins}
              asset={asset}
              positions={position_types}
              allocationMode={'manual'}
              removable={key !== 0 && key !== 1}
              removeRow={(id) => dispatch(remove_asset(id))}
              updateRow={(asset) =>
                dispatch(update_asset({ index: key, asset }))
              }
            />
          ))}
          <button
            onClick={() => dispatch(add_asset())}
            className="smart-portfolio__add-strategy w-3/6"
          >
            <BsPlusSquare className="smart-portfolio__add-strategy-icon" />
            <div>Add Asset</div>
          </button>
          <div className="my-4 flex w-5/6">
            <div className="w-full">
              <ProgressBar percentage={total_percentage} />
            </div>
            <button
              disabled={total_percentage !== 100}
              onClick={() => submit()}
              className={
                total_percentage === 100
                  ? 'w-1/5 ml-2 bg-dark-primary rounded-sm  text-white'
                  : 'w-1/5 ml-2 bg-white dark:bg-gray-700 rounded-sm disabled text-black dark:text-white'
              }
            >
              Analyze
            </button>
          </div>
        </div>
      </div>
      {results_status === 'fulfilled' ? (
        <div
          ref={bottomRef}
          className="mb-6 third-div py-5"
        >
          <div className="flex flex-col">
            <div className="Charts-div-portfolio flex w-100 flex-col justify-between bg-light-back_secondary dark:bg-dark-back_secondary rounded-md p-5">
              <div className='block mb-4'>
                <Title
                  title={"Asset Weight Analysis"}
                  subtitle={"Initial, Adjusted and Optimal Weights for the Portfolio"}
                />
              </div>
              <div className="flex w-100 flex-row justify-evenly">
                <div className="Charts-div-row bg-light-back dark:bg-dark-back rounded-md">
                  {initial_weights ? (
                    <div className="Chart-div-portfolio1">
                      <Chart
                        Options={pieChartOptions(
                          'Initial Weights',
                          initial_weights,
                          theme,
                          optimal_weights.length != 0 ? 370 : 450
                        )}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="Charts-div-row bg-light-back dark:bg-dark-back rounded-md">
                  {adjusted_weights ? (
                    <div className="Chart-div-portfolio1">
                      <Chart
                        Options={pieChartOptions(
                          'Adjusted Weights',
                          adjusted_weights,
                          theme,
                          optimal_weights.length != 0 ? 370 : 450
                        )}
                      />
                    </div>
                  ) : null}
                </div>
                  {optimal_weights.length != 0 ? (
                <div className="Charts-div-row bg-light-back dark:bg-dark-back rounded-md">
                    <div className="Chart-div-portfolio1">
                      <Chart
                        Options={pieChartOptions(
                          'Optimal Weights',
                          optimal_weights,
                          theme
                        )}
                      />
                    </div>
                </div>
                  ) : null}
              </div>
            </div>

            <div className="flex gap-3">
              <div className='my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md'>
                <Title
                  className={'mb-3'}
                  title="Equity Distribution Analysis"
                  subtitle="Exploring Portfolio Performance Through Bar Charts: Initial, Adjusted, and Optimal Weighted Equities"
                />
                {Object.keys(portfolio_equities).length > 1 && (
                  <div className="w-full">
                    <Chart
                      Options={BarChartOptions(
                        'Portfolio Equities',
                        '',
                        `Portfolio Equity (after ${holding_period} days)`,
                        Object.keys(portfolio_equities),
                        Object.values(portfolio_equities),
                        theme
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
              <Title
                  className={'mb-3'}
                  title="Efficient Frontier Exploration"
                  subtitle="Unveiling Portfolio Potential: Scatter Plot Analysis of Expected Return and Standard Deviation with Initial, Adjusted, and Optimal Weights"
                />
                <Chart
                  Options={EfficientFrontierPlot(
                    initial_point,
                    adjusted_point,
                    optimal_point?.expected_return ? optimal_point : null,
                    expected_returns,
                    stdevs,
                    theme
                  )}
                />
              </div>
            </div>
            {
              percentile_chart.length > 0 && portfolio_equities_chart?.initial_weights?.length > 0 ? (
                <div className='flex w-full gap-3'>
                  <div className='p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md w-full'>
                    <Title
                      className={'mb-3'}
                      title="Dynamic Portfolio Equity Trends"
                      subtitle="Tracking Growth Over Time: Line Chart Analysis of Portfolio Equity with Initial, Adjusted, and Optimal Weights"
                    />
                    <Chart Options={lineOptions(Object.entries(portfolio_equities_chart).map(([name, data], index) => ({ name, data: data.map((item, index) => [index, item]), color: index === 0 ? '#B80000' : index === 1 ? '#FF9800' : '#5F8670' })))} />
                  </div>
                  <div className='p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md w-full'>
                    <Title
                      title="Monte Carlo Simulation Percentiles"
                      subtitle="Visualizing Portfolio Equity Distribution: Bar Chart Analysis of Percentiles through Monte Carlo Simulation"
                    />
                    <Chart
                      Options={
                        PercentileChartPlot(["5%", "10%", "25%", "50%", "75%", "90%", "95%"], percentile_chart, theme)
                      }
                    />
                  </div>
                </div>
              ) : portfolio_equities_chart?.initial_weights?.length > 0 ?
                (
                  <div className='p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md'>
                    <Title
                      className={'mb-3'}
                      title="Dynamic Portfolio Equity Trends"
                      subtitle="Tracking Growth Over Time: Line Chart Analysis of Portfolio Equity with Initial, Adjusted, and Optimal Weights"
                    />
                    <Chart Options={lineOptions(Object.entries(portfolio_equities_chart).map(([name, data], index) => ({ name, data: data.map((item, index) => [index, item]), color: index === 0 ? '#B80000' : index === 1 ? '#FF9800' : '#5F8670' })), theme)} />
                  </div>
                ) : percentile_chart.length > 0 ? (
                  <div className='p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md w-full'>
                    <Title
                      title="Monte Carlo Simulation Percentiles"
                      subtitle="Visualizing Portfolio Equity Distribution: Bar Chart Analysis of Percentiles through Monte Carlo Simulation"
                    />
                    <Chart
                      Options={
                        PercentileChartPlot(["5%", "10%", "25%", "50%", "75%", "90%", "95%"], percentile_chart, theme)
                      }
                    />
                  </div>
                ) : (null)
            }
            {monte_carlo.length > 1
              ? (
                <div className='my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md'>
                  <Title
                    title="Monte Carlo Simulation: Portfolio Equity Dynamics"
                    subtitle="Exploring Potential Scenarios: Line Chart Analysis of Portfolio Equity Variation During the Holding Period"
                  />
                  <Chart
                    Options={monteCarloOptions(
                      monte_carlo,
                      theme
                    )}
                  />
                </div>
              )
              : null}
          </div>
        </div>
      ) : results_status === 'pending' ?
        (
          <div className="market-overview__page-loading">
            <img src={candleLoading} style={{ width: 300, height: 300 }}></img>
          </div>
        )
        : (null)}
      <br />
    </DashboardLayout>
  );
}
export default PortfolioAnalyzer;
