export const getArrowType = (type, side) => {
  if (type === 'enter' && side === 'long') {
    return 'url(/down-blue-arrow.png)';
  } else if (type === 'enter' && side === 'short') {
    return 'url(/down-red-arrow.png)';
  } else if (type === 'exit' && side === 'long') {
    return 'url(/down-red-arrow.png)';
  } else if (type === 'exit' && side === 'short') {
    return 'url(/down-blue-arrow.png)';
  }
};

export const getDashLineColor = (enterType, enterSide, exitType, exitSide) => {
  if (
    enterType === 'enter' &&
    enterSide === 'long' &&
    exitType === 'exit' &&
    exitSide === 'long'
  ) {
    return '#4287f5';
  } else if (
    enterType === 'enter' &&
    enterSide === 'short' &&
    exitType === 'exit' &&
    exitSide === 'short'
  ) {
    return '#f0291a';
  }
};


export function generateColorCodedArray(predictions, interval) {
  const colorCodedArray = [];
  let from = null;
  let to = null;
  let color = null;
  let current_actual;
  const half_interval = interval/2;
  
  for (let i = 0; i < predictions.length; i++) {
    const prediction = predictions[i];
    if (i == 0) {
      // Instantiation
      color = prediction.actual == 1 ? "rgba(33, 204, 109, 0.3)" : "rgba(204, 33, 33, 0.3)";
      current_actual = prediction.actual;
      from = (prediction.timestamp - half_interval) * 1000;
      to = (prediction.timestamp + half_interval) * 1000;
    }

    if (prediction.actual == current_actual){
      to = (prediction.timestamp + half_interval) * 1000;
    } else {
      colorCodedArray.push({color, from, to})
      from = to;
      to = (prediction.timestamp + half_interval) * 1000;
      current_actual = prediction.actual;
      color = prediction.actual == 1 ? "rgba(33, 204, 109, 0.3)" : "rgba(204, 33, 33, 0.3)";
    }

    // Closing the last prediction regardless of what it is
    if (i == predictions.length - 1){
      colorCodedArray.push({color, from, to})
    }
  }
  
  return colorCodedArray;
}
