import React from 'react';
import classNames from 'classnames';
import './style.scss';

function BottomInput({
  className: classNameProp,
  label,
  endIcon,
  ...restProps
}) {
  const className = classNames(classNameProp, 'bottom-input__input bg-light-back dark:bg-dark-back w-full');
  return (
    <div className="bottom-select__container">
      <label className="bottom-select__label mb-2">{label}</label>
      <div className="bottom-input__container border-black dark:border-white w-full">
        <input className={className} {...restProps} />
        {endIcon}
      </div>
    </div>
  );
}

export default BottomInput;
