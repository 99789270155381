import Highcharts from 'highcharts';
import { formatVolume } from '../utils/formatPrice';

export const options = (series) => {
  return {
    chart: {
      type: 'area',
      backgroundColor: 'transparent',
      height: 180,
      width: 250,
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          'Date: ' + new Date(this.point.x).toISOString().split('T')[0] +
          '</b> <br>' +
          '<b> ' +
          'Value: ' + formatVolume(this.point.y) +
          '<b/>'
        );
      },
    },
    plotOptions: {
      series: {
        fillColor: {
          linearGradient: {
            x1: 0.98,
            y1: 0,
            x2: 0.98,
            y2: 1,
          },
          stops: [
            [0, '#7D7AFF'],
            [1, Highcharts.color('#1C1A23').setOpacity(0).get('rgba')],
          ],
        },
      },
    },
    series: [
      {
        lineColor: '#5A4F9D',
        color: '#5A4F9D',
        // marker: true,
        data: series,
      },
    ],
  };
  
}