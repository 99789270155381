import React from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import DatePicker from '../DatePicker';
import './style.scss';

function CalendarInput({ label, onChangeFunc, defaultDateTime, onFocus, value = new Date(), show, setShow, dark=true, maxDate, minDate, hasTime=false, ...restProps }) {
  return (
    <div className="calendar-input">
      <label className="calendar-input__label text-black dark:text-white">{label}</label>
      <div className="calendar-input__container">
        <FaRegCalendarAlt className="calendar-input__icon text-black dark:text-white" size={24} />
        <DatePicker
          show={show}
          setShow={setShow}
          shouldCloseOnSelect={true}
          dateFormat = 'YYYY/MM/DD'
          defaultDateTime
          hasTime={hasTime}
          isDarkMode={dark}
          placeholder =''
          label =''
          rightIcon
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          inputClassName="calendar-input__input bg-light-back text-black dark:bg-dark-back dark:text-white"
          theme={DatePicker.themes.TEAL}
          onFocus={onFocus}
          onChange={onChangeFunc}
        />
      </div>
    </div>
  );
}

export default CalendarInput;
