import React from 'react';
import { useKeenSlider } from 'keen-slider/react'; // import from 'keen-slider/react.es' for to get an ES module
import 'keen-slider/keen-slider.min.css';
import './style.scss';
import OverallMarketHeaderCard from '../OverallMarketHeaderCard';
import { history } from '../../utils/history';
import useIsMobile from '../../hooks/useIsMobile';

const animation = { duration: 20000, easing: (t) => t };

function LoadingCard() {
  return (
    <div className="grid-cols-8 gap-4 flex">
      {[...Array(8).keys()].map((x, i) => (
        <div
          className={`animate-pulse col-span-${i} w-[10rem] bg-light-back_secondary dark:bg-dark-back_secondary rounded-md h-[6rem]`}
        ></div>
      ))}
    </div>
  );
}

function OverallMarketHeader({ items, loading }) {
  const { navigate } = history;
  const isMobile = useIsMobile(480);
  const isFablet = useIsMobile(768);
  const isTablet = useIsMobile(1000);

  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: 'free',
    drag: true,
    slides: {
      perView: isMobile ? 2 : isFablet ? 3 : isTablet ? 5 : 6,
      spacing: isFablet ? 8 : 14,
    },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });
  function handleCardClick(ticker) {
    navigate(`/coin/${ticker}`);
  }
  return (
    <div className="w-full mt-4">
      {loading ? (
        <LoadingCard />
      ) : (
        <div className="keen-slider" ref={sliderRef}>
          {items?.map((cardData, index) => (
            <div
              key={index}
              className="keen-slider__slide slide"
              onClick={() => handleCardClick(cardData.full_name)}
            >
              <OverallMarketHeaderCard {...cardData} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OverallMarketHeader;
