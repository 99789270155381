export const sentimentOptions = () => {
  return {
    chart: {
      type: 'spline', // Use a spline chart type for smoother lines
      backgroundColor: 'rgba(0,0,0,0)', // Make the chart background transparent
    },
    title: {
      text: '',
      style: {
        color: 'white', // Set the title color to white
      },
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      labels: {
        style: {
          color: 'white', // Set the x-axis labels color to white
        },
      },
    },
    yAxis: {
      title: {
        text: 'Sentiment',
        style: {
          color: 'white', // Set the y-axis title color to white
        },
      },
      min: -1,
      max: 1,
      gridLineWidth: 0, // Hide the grid lines
      labels: {
        style: {
          color: 'white', // Set the y-axis labels color to white
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false, // Remove the markers on data points
        },
        lineWidth: 2, // Increase the line width for better visibility
        states: {
          hover: {
            // Apply a brightness effect on hover
            brightness: 0.1,
          },
        },
        shadow: {
          color: 'rgba(0, 0, 0, 0.3)', // Add a shadow effect to the lines
          offsetX: 0,
          offsetY: 4,
          opacity: 0.4,
          width: 3,
        },
      },
    },
    series: [
      {
        name: 'Twitter',
        data: [-0.2, 0, 0.4, 0.6, 0.8, 0.6, 0.2, 0, -0.2, -0.4, -0.6, -0.8],
        color: 'rgba(29, 161, 242, 0.8)', // Use an rgba color for transparency
      },
      {
        name: 'News',
        data: [
          0.3, 0.6, 0.9, 0.7, 0.4, 0.2, -0.1, -0.3, -0.5, -0.8, -0.9, -0.6,
        ],
        color: 'rgba(255, 0, 0, 0.8)',
      },
      {
        name: 'Reddit',
        data: [-0.7, -0.5, -0.3, 0, 0.5, 0.8, 1, 0.8, 0.5, 0, -0.3, -0.7],
        color: 'rgba(255, 69, 0, 0.8)',
      },
    ],
    legend: {
      itemStyle: {
        color: 'white', // Set the legend item color to white
      },
    },
  };
};

export const lineOptions = (series, dark=true) => {
  const precise_series = series.map((x) => {return {...x, data: x.data.map((y)=> {return [y[0], parseFloat(y[1].toFixed(2))]})} })
  const holding_period = precise_series[0].data.length
  return {
    chart: {
      type: 'spline', // Use a spline chart type for smoother lines
      backgroundColor: 'rgba(0,0,0,0)', // Make the chart background transparent
    },
    title: {
      text: "",
      style: {
        color: dark ? 'white' : 'black', // Set the title color to white
      },
    },
    xAxis: {
      tickInterval: Math.floor(precise_series[0].data.length / 15) + 1,
      labels: {
        style: {
          color: dark ? 'white' : 'black', // Set the x-axis labels color to white
        },
      },
    },
    yAxis: {
      title: {
        text: 'Portfolio Equity',
        style: {
          color: dark ? 'white' : 'black', // Set the y-axis title color to white
        },
      },
      gridLineWidth: 0, // Hide the grid lines
      labels: {
        style: {
          color: dark ? 'white' : 'black', // Set the y-axis labels color to white
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false, // Remove the markers on data points
        },
        lineWidth: 2, // Increase the line width for better visibility
        states: {
          hover: {
            // Apply a brightness effect on hover
            brightness: 0.1,
          },
        },
        shadow: {
          color: 'rgba(0, 0, 0, 0.3)', // Add a shadow effect to the lines
          offsetX: 0,
          offsetY: 4,
          opacity: 0.4,
          width: 3,
        },
      },
    },
    series: precise_series,
    legend: {
      itemStyle: {
        color: dark ? 'white' : 'black', // Set the legend item color to white
      },
    },
  };
};

export const monteCarloOptions = (forward, dark=true) => {
  const precise_forward = forward.map((x)=> {return x.map((y)=>{return parseFloat(y.toFixed(2))})})
  const series = precise_forward.map((item) => {
    return {
      type: 'spline',
      name: 'expected return',
      data: [...item],
      yAxis: 0, // Keep the y-axis reference as it is
      xAxis: 0, // Adjust the x-axis reference
    };
  })
  return {
    chart: {
      type: 'spline',
      backgroundColor: 'rgba(0,0,0,0)', // Make the chart background transparent
    },
    title: {
      text: ``,
      style: {
        color: dark ? 'white' : 'black', // Set the title color to white
      },
    },
    tooltip: {
      formatter: function() {
        return "Day: " + this.x + '<br/>' +
          'Porfolio Equity: ' + this.y;
      }
    },
    xAxis: {
      labels: {
        style: {
          color: dark ? 'white' : 'black', // Set the x-axis labels color to white
        },
      },
      title: {
        text: 'Holding Period (Day)', // Adjust the x-axis title
        style: {
          color: dark ? 'white' : 'black',
        },
      },
    },
    yAxis: {
      gridLineWidth: 0, // Hide the grid lines
      labels: {
        style: {
          color: dark ? 'white' : 'black', // Set the y-axis labels color to white
        },
      },
      title: {
        style: {
          color: dark ? 'white' : 'black',
        },
        text: 'Portfolio Equity',
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false, // Remove the markers on data points
        },
        lineWidth: 2, // Increase the line width for better visibility
        states: {
          hover: {
            // Apply a brightness effect on hover
            brightness: 0.1,
          },
        },
        shadow: {
          color: 'rgba(0, 0, 0, 0.3)', // Add a shadow effect to the lines
          offsetX: 0,
          offsetY: 4,
          opacity: 0.4,
          width: 3,
        },
      },
    },
    series: series,
  };
};


//  Data sample for sentiment chart
const sentiment_sample_data = {
  "symbol": "there",
  "dates": [
    "2023-11-14",
    "2023-11-13",
    "2023-11-12",
    "2023-11-11",
    "2023-11-10",
    "2023-11-09",
    "2023-11-08",
    "2023-11-07",
    "2023-11-06",
    "2023-11-05",
    "2023-11-04",
    "2023-11-03",
    "2023-11-02",
    "2023-11-01",
    "2023-10-31",
    "2023-10-30",
    "2023-10-29",
    "2023-10-28",
    "2023-10-27",
    "2023-10-26",
    "2023-10-25",
    "2023-10-24",
    "2023-10-23",
    "2023-10-22",
    "2023-10-21",
    "2023-10-20",
    "2023-10-19",
    "2023-10-18",
    "2023-10-17",
    "2023-10-16"
  ],
  "tickers": [
    82337.3526107838,
    23615.9356234519,
    65936.3144947147,
    71380.3274297692,
    63691.4028332187,
    88469.7255231168,
    28201.0056796183,
    77798.7354894366,
    72285.0276932368,
    12269.1597674525,
    33223.2711671273,
    34472.2906336914,
    62402.7942965883,
    89503.5804822113,
    5234.82372734379,
    71991.5637466524,
    18969.187892537,
    26122.3163914263,
    57857.4549754617,
    5627.74016500998,
    45739.4741646367,
    52250.7033488263,
    58437.9535337566,
    42949.9024607121,
    45197.6607750068,
    97971.8826879216,
    28623.7898846309,
    95335.2140626661,
    80097.30774357,
    40061.7345098829
  ],
  "sources": {
    "overall": [
      0.0522,
      -0.0098,
      0.0367,
      -0.0814,
      0.0821,
      0.1935,
      -0.046,
      0.0645,
      0.0163,
      0.0357,
      -0.0243,
      0.0698,
      0.0588,
      0.1923,
      0.0278,
      -0.0184,
      -0.0117,
      0.0984,
      0.0443,
      0.1306,
      0.0777,
      0.0269,
      0.0091,
      -0.1632,
      0.0044,
      0.1013,
      -0.0175,
      0.0145,
      0.0893,
      0.069
    ],
    "tradingview": [
      -0.094,
      0.1001,
      0.019,
      -0.0782,
      0.0651,
      0.2334,
      -0.0717,
      -0.0263,
      -0.0272,
      -0.078,
      -0.0411,
      -0.0451,
      0.1351,
      0.1502,
      0.0621,
      0.1597,
      0.0509,
      -0.0204,
      0.0942,
      0.1295,
      0.1153,
      0.1625,
      -0.1108,
      -0.0413,
      0.0964,
      0.0768,
      -0.0515,
      -0.0064,
      0.1709,
      0.1942
    ],
    "cmc": [
      0.2204,
      -0.1144,
      0.0543,
      -0.084,
      0.1032,
      0.1579,
      -0.0036,
      0.1755,
      0.0607,
      0.1169,
      -0.0108,
      0.1784,
      -0.033,
      0.2322,
      -0.0065,
      -0.2125,
      -0.059,
      0.2034,
      -0.0128,
      0.1318,
      0.0268,
      -0.1159,
      0.0772,
      -0.2711,
      -0.094,
      0.1167,
      0.0071,
      0.0347,
      0.0046,
      -0.043
    ]
  }
}

export const LineChartWithDoubleAxis = (xAxisCategories, sources, price) => {
  const source_names = Object.keys(sources)
  const yAxisLabels = [
    {
      gridLineWidth: 0, // Hide the grid lines
      labels: {
        style: {
          color: 'white', // Set the y-axis labels color to white
        },
      },
      title: {
        text: "Price",
        style: {
          color: 'white', // Set the y-axis labels color to white
        },
      },
      opposite: true,
    },
    {
      gridLineWidth: 0, // Hide the grid lines
      labels: {
        style: {
          color: 'white', // Set the y-axis labels color to white
        },
      },
      opposite: false,
      title: {
        text: "sentiment",
        style: {
          color: 'white', // Set the y-axis labels color to white
        },
      },
    }
  ];
  const price_data = [
    {
      yAxis: 0,
      name: "price",
      data: price,
    }
  ]
  const sentiments_data = source_names.map((name)=> {
    return {
        yAxis: 1,
        name: name,
        data: sources[name],
    }
  })
  return {
    chart: {
      type: 'spline', // Use a spline chart type for smoother lines
      backgroundColor: 'rgba(0,0,0,0)', // Make the chart background transparent
    },
    title: {
      text: "",
      style: {
        color: 'white', // Set the y-axis labels color to white
      },
    },
    xAxis: {
      labels: {
        style: {
          color: 'white', // Set the x-axis labels color to white
        },
      },
      categories: xAxisCategories,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false, // Remove the markers on data points
        },
        lineWidth: 2, // Increase the line width for better visibility
        states: {
          hover: {
            // Apply a brightness effect on hover
            brightness: 0.1,
          },
        },
        shadow: {
          color: 'rgba(0, 0, 0, 0.3)', // Add a shadow effect to the lines
          offsetX: 0,
          offsetY: 4,
          opacity: 0.4,
          width: 3,
        },
      },
    },
    legend: {
      itemStyle: {
        color: 'white' // Set the legend item color to white
      }
    },
    yAxis: yAxisLabels,
    series: price_data.concat(sentiments_data),
  };
}
