import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/config';
import { validate_time } from '../../utils/validators';
import { toast } from 'react-toastify';

const validate_asset = (asset, assets, sum, index) => {
  const validationMessages = [];

  if (asset.asset_allocation) {
    if (!(asset.asset_allocation > 0 && asset.asset_allocation <= 100)) {
      validationMessages.push(
        'Invalid allocation percent. Please enter a value between 0 and 100.'
      );
    }
  }

  if (!(sum <= 100)) {
    validationMessages.push(
      'Sum of allocation percents exceeds 100%. Please adjust the allocation percentages.'
    );
  }

  if (asset.symbol_id) {
    assets.splice(index, 1);
    if (assets.some((x) => x.symbol_id === asset.symbol_id)) {
      validationMessages.push(
        'Duplicate coin ID found. Please use a unique coin ID.'
      );
    }
  }

  return validationMessages.length === 0 ? null : validationMessages;
};

const initialState = {
  toast_active: false,

  available_holding_period_methods: [
    { value: 0, label: '30 day method' },
    { value: 1, label: '15 day method' },
  ],

  selected_holding_period_method: { value: 0, label: '30 day method' },
  
  avaliable_analysis_types: [],
  selected_analysis_type: null,

  available_methods: [],
  selected_method: null,

  available_holding_periods: [],
  selected_holding_period: null,

  available_lookback_methods: [],
  selected_lookback_method: null,
  get_analysis_types_status: 'idle',

  available_position_types: [
    { value: 1, label: 'Long' },
    { value: -1, label: 'Short' },
  ],

  start_timestamp: null,
  initial_capital: 1000,
  holding_period: 20,
  risk_free_rate: 0.04,

  coins: [],
  getCoins_status: 'idle',

  assets: [
    {
      symbol_id: '',
      position_type: 1,
      asset_allocation: '',
    },
    {
      symbol_id: '',
      position_type: 1,
      asset_allocation: '',
    },
  ],

  analyzePortfolio_results: {},
  analyzePortfolio_status: 'idle',

  total_percentage: 0,

  monte_carlo: [],
  initial_weights: [],
  adjusted_weights: [],
  optimal_weights: [],
  initial_point: {},
  adjusted_point: {},
  optimal_point: {},
  portfolio_equities: [],
  stdevs: [],
  expected_returns: [],
  portfolio_equities_chart: [],
  percentile_chart: []
};

export const get_analysis_types = createAsyncThunk(
  'analyzer/get_analysis_types',
  async () => {
    const results = await axiosInstance.get('/portfolio/getAnalysisTypes/');
    return results.data;
  }
);

export const getCoins = createAsyncThunk('quickScan/getcoins', async () => {
  const response = await axiosInstance.get('/market/searchcoin/', {});
  return response.data;
});

export const analyzePortfolio = createAsyncThunk(
  'portfolio/portfolioAnalyzer',
  async (data) => {
    const response = await axiosInstance.post(
      '/portfolio/portfolioAnalyzer/',
      data
    );
    return response.data;
  }
);

export const analyzerSlice = createSlice({
  name: 'analyzer',
  initialState,
  reducers: {
    set_toast_state: (state, action) => {
      state.toast_active = action.payload;
    },
    remove_asset: (state, action) => {
      const index = action.payload;
      if (index > 0) {
        state.assets.splice(index, 1);
        state.total_percentage = state.assets.reduce(
          (total, asset) => total + asset.asset_allocation,
          0
        );
      }
    },
    add_asset: (state) => {
      if (state.assets.length <= 14) {
        state.assets = [
          ...state.assets,
          {
            symbol_id: state.coins[state.assets.length].value,
            position_type: 1,
            asset_allocation: '',
          },
        ];
      } else {
        toast.warn('Cannot have more than 15 assets in porftfolio optimizer');
      }
    },
    update_asset: (state, action) => {
      const asset = action?.payload?.asset;
      const assets = state.assets;
      const index = action?.payload?.index;
      const updatedAssets = [...assets];
      updatedAssets[index] = asset;
      const sum = updatedAssets.reduce(
        (total, asset) => total + asset.asset_allocation, 0
      );
      // Validation
      const validationMessage = validate_asset(
        asset,
        updatedAssets,
        sum,
        index
      );
      if (validationMessage) {
        for (const msg of validationMessage) {
          toast.warn(msg);
        }
      } else {
        // Update the asset
        state.assets[index] = asset;
        state.total_percentage = sum;
      }
    },
    select_analysis_type: (state, action) => {
      state.selected_analysis_type = action.payload;
    },
    select_method: (state, action) => {
      state.selected_method = action.payload;
    },
    select_lookback_method: (state, action) => {
      state.selected_lookback_method = action.payload;
    },
    select_holding_period: (state, action) => {
      state.selected_holding_period = action.payload;
    },
    select_holding_period_method: (state, action) => {
      state.selected_holding_period_method = action.payload;
    },
    select_start_timestamp: (state, action) => {
          state.start_timestamp = action.payload;
    },
    set_holding_period: (state, action) => {
      state.holding_period = action.payload
    },
    set_initial_capital: (state, action) => {
      state.initial_capital = action.payload;
    },
    set_risk_free_rate: (state, action) => {
      const risk_free_rate = action.payload;
      if (0.00 <= risk_free_rate && risk_free_rate <= 0.5) {
        state.risk_free_rate = action.payload;
      }
      else {
        toast.warn('The risk free rate should be between 0% to 50%.');
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      .addCase(get_analysis_types.pending, (state) => {
        state.get_analysis_types_status = 'pending';
      })
      .addCase(get_analysis_types.fulfilled, (state, action) => {
        const available_analysis_types =
          action.payload?.available_analysis_types;
        const available_methods = action.payload?.available_methods;
        const available_lookback_methods = action.payload?.available_lookback_methods;
        state.available_analysis_types = available_analysis_types?.map(
          (item) => {
            return {
              value: item[0],
              label: item[1],
            };
          }
        );
        state.available_methods = available_methods?.map((item) => {
          return {
            value: item[0],
            label: item[1],
          };
        });
        state.available_lookback_methods = available_lookback_methods?.map((item) => {
          return {
            value: item[0],
            label: item[1]
          }
        })
        state.selected_method = state.available_methods[0];
        state.selected_analysis_type = state.available_analysis_types[0];
        state.selected_lookback_method = state.available_lookback_methods[3];
        state.get_analysis_types_status = 'fulfilled';
      })
      .addCase(get_analysis_types.rejected, (state) => {
        state.get_analysis_types_status = 'rejected';
      })
      .addCase(getCoins.pending, (state) => {
        state.getCoins_status = 'pending';
      })
      .addCase(getCoins.fulfilled, (state, action) => {
        const output = action.payload.map((obj) => {
          const symbol = Object.keys(obj)[1];
          const id = Object.keys(obj)[0];
          return { value: obj[id], label: obj[symbol] };
        });
        state.coins = output;
        state.assets[0].symbol_id = state.coins[0].value;
        state.assets[1].symbol_id = state.coins[1].value;
        state.getCoins_status = 'fulfilled';
      })
      .addCase(getCoins.rejected, (state) => {
        state.getCoins_status = 'rejected';
      })
      .addCase(analyzePortfolio.pending, (state) => {
        state.initial_weights = [];
        state.adjusted_weights = [];
        state.optimal_weights = [];
        state.analyzePortfolio_status = 'pending';
      })
      .addCase(analyzePortfolio.fulfilled, (state, action) => {
        const initial_weights = action.payload?.initial_weights?.weights;
        const adjusted_weights = action.payload?.adjusted_weights?.weights;
        const optimal_weights = action.payload?.optimal_weights?.weights;
        initial_weights ? state.initial_weights = Object.keys(initial_weights).map((name) => ({
          name,
          y: initial_weights[name]
        })) : null;
        adjusted_weights ? state.adjusted_weights = Object.keys(adjusted_weights).map((name) => ({
          name,
          y: adjusted_weights[name]
        })) : null;
        optimal_weights ? state.optimal_weights = Object.keys(optimal_weights).map((name) => ({
          name,
          y: optimal_weights[name]
        })) : null;
        state.initial_point = { standard_deviation: action.payload?.initial_weights?.standard_deviation, expected_return: action.payload?.initial_weights?.expected_return }
        state.adjusted_point = { standard_deviation: action.payload?.adjusted_weights?.standard_deviation, expected_return: action.payload?.adjusted_weights?.expected_return }
        state.optimal_point = { standard_deviation: action.payload?.optimal_weights?.standard_deviation, expected_return: action.payload?.optimal_weights?.expected_return }
        state.portfolio_equities = action.payload?.portfolio_equities ? action.payload?.portfolio_equities : [];
        state.stdevs = action.payload?.efficient_frontier?.samples_stdev ? action.payload?.efficient_frontier?.samples_stdev.map(x => { return x[0] }) : [];
        state.expected_returns = action.payload?.efficient_frontier?.efficient_frontier_samples ? action.payload?.efficient_frontier?.efficient_frontier_samples : [];
        state.monte_carlo = action.payload?.monte_carlo ? action.payload?.monte_carlo : [];
        state.portfolio_equities_chart = action.payload?.portfolio_equities_chart ? action.payload?.portfolio_equities_chart : [];
        state.percentile_chart = action.payload?.percentiles ? action.payload?.percentiles : [];
        state.analyzePortfolio_status = 'fulfilled';
      })
      .addCase(analyzePortfolio.rejected, (state) => {
        state.analyzePortfolio_status = 'rejected';
      });
  },
});

export const {
  select_analysis_type,
  select_method,
  select_lookback_method,
  set_risk_free_rate,
  select_start_timestamp,
  select_holding_period_method,
  set_initial_capital,
  set_holding_period,
  remove_asset,
  add_asset,
  update_asset,
} = analyzerSlice.actions;

export default analyzerSlice.reducer;
