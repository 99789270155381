import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';
import { from_alpha_advantage } from '../../utils/formatTime'
import moment from 'moment';
import './style.scss';

const PAGE_SIZE = 5;

function MarketNewsTable({ columns, rows, title }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [formattedData, setFormattedData] = useState(rows);
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    const currentTableData = rows.slice(firstPageIndex, lastPageIndex);
    setFormattedData(currentTableData);
  }, [currentPage, rows]);

  return (
    <div className="news-table bg-light-back_secondary dark:bg-dark-back_secondary text-black dark:text-white">
      {title}
      <table className="news-table__table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={`${col.label}-${index}`}>
                <div className="news-table__th">{col.label}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {formattedData.map((row, index) => (
            <tr
              role="link"
              key={`${row.title}-${index}`}
              onClick={() => window.open(row.url)}
            >
              <td>
                <img
                  src={row.banner_image}
                  alt="Thumbnail"
                  className="news-table__thumbnail rounded-md"
                />
              </td>
              <td>{moment(row?.time).format("YYYY-MM-DD hh:mm")}</td>
              <td
                width={'50%'}
                onClick={() => {
                  setSelectedNews(row);
                  setIsDetailModalOpen(true);
                }}
              >
                {row.title}
              </td>
              <td className="news-table__badges gap-1">
                {row.tickers ? (
                  row.tickers.map((x) => {
                    if (x.ticker.includes('CRYPTO:')) {
                      return (
                        <div className="news-table__coin">
                          {x.ticker.split('CRYPTO:')[1]}
                        </div>
                      )
                    }
                    return null;
                  })
                ) : (
                  <div></div>
                )}
                <div
                  className={
                    row.sentiment === 'Somewhat-Bullish'
                      ? 'news-table__somewhat-Bullish'
                      : row.sentiment === 'Bullish'
                        ? 'news-table__bullish'
                        : row.sentiment === 'Neutral'
                          ? 'news-table__neutral'
                          : row.sentiment === 'Bearish'
                            ? 'news-table__bearish'
                            : row.sentiment === 'Somewhat-Bearish'
                              ? 'news-table__somewhat-Bearish'
                              : ''
                  }
                >
                  {row.sentiment}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="news-table__pagination-wrapper">
        <div>
          {`showing ${(currentPage - 1) * PAGE_SIZE + 1} to ${Math.min(
            currentPage * PAGE_SIZE,
            rows.length
          )} of ${rows.length} items`}
        </div>
        <Pagination
          className="news-table__pagination"
          currentPage={currentPage}
          totalCount={rows.length}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}

export default MarketNewsTable;
