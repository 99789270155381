import React from 'react';
import './style.css';

const NotFoundPage = () => {
  return (
    <div>
      <head>
        <link
          href="https://fonts.googleapis.com/css?family=Fredoka+One"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:400,700"
          rel="stylesheet"
        />
      </head>
      <div>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1>404</h1>
            </div>
            <h2>Oops, The Page you are looking for can't be found!</h2>
            <a href="/market-overview" className="notfound-return">
              <span></span>Return To Overall Market
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
