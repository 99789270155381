export const EfficientFrontierPlot = (
  initialPoint,
  adjustedPoint,
  optimalPoint,
  expectedReturn,
  stdevs,
  dark=true
) => {
  const stdevs_percise = stdevs.map((x)=>{
    return parseFloat((x * 100).toFixed(4));
  });
  const expected_returns_percise = expectedReturn.map((x)=>{
    return parseFloat((x * 100).toFixed(4));
  });
  let minX = Math.min(...stdevs_percise, initialPoint.standard_deviation * 100, adjustedPoint.standard_deviation * 100);
  let maxX = Math.max(...stdevs_percise, initialPoint.standard_deviation * 100, adjustedPoint.standard_deviation * 100);
  let minY = Math.min(...expected_returns_percise, initialPoint.expected_return * 100, adjustedPoint.expected_return * 100);
  let maxY = Math.max(...expected_returns_percise, initialPoint.expected_return * 100, adjustedPoint.expected_return * 100);
  const series = [
    {
      name: 'Frontier Portfolios',
      type: 'scatter',
      data: expected_returns_percise.map((returnVal, index) => ({
        x: stdevs_percise[index],
        y: returnVal,
      })),
      color: '#BDA0CB',
      marker: {
        symbol: 'circle',
      },
    },
    {
      name: 'Initial Portfolio',
      type: 'scatter',
      data: [
        {
          x: parseFloat((initialPoint.standard_deviation * 100).toFixed(4)),
          y: parseFloat((initialPoint.expected_return * 100).toFixed(4)),
        },
      ],
      color: '#B80000',
      marker: {
        symbol: 'star',
        radius: 3,
      },
    },
    {
      name: 'Adjusted Portfolio',
      type: 'scatter',
      data: [
        {
          x: parseFloat((adjustedPoint.standard_deviation * 100).toFixed(4)),
          y: parseFloat((adjustedPoint.expected_return * 100).toFixed(4)),
        },
      ],
      color: '#FF9800',
      marker: {
        symbol: 'star',
        radius: 3,
      },
    },   
  ];
  if (optimalPoint) {
    minX = Math.min(minX, optimalPoint.standard_deviation * 100)
    maxX = Math.max(maxX, optimalPoint.standard_deviation * 100)
    minY = Math.min(minY, optimalPoint.expected_return * 100)
    maxY = Math.max(maxY, optimalPoint.expected_return * 100)
    series.push({
      name: 'Optimal Portfolio',
      type: 'scatter',
      data: [
        {
          x: parseFloat((optimalPoint.standard_deviation * 100).toFixed(4)),
          y: parseFloat((optimalPoint.expected_return * 100).toFixed(4)),
        },
      ],
      color: '#5F8670',
      marker: {
        symbol: 'star',
        radius: 3,
      },
    },)
  }
  return {
    chart: {
      type: 'scatter',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
      style: {
        color: dark ? '#ffffff' : '111',
        fontWeight: 'bold',
        fontSize: '16px',
      },
    },
    xAxis: {
      title: {
        text: 'Standard Deviation',
        style: {
          color: dark ? '#ffffff' : '111',
          fontWeight: 'bold',
        },
      },
      gridLineWidth: 0,
      labels: {
        style: {
          color: dark ? '#ffffff' : '111',
        },
      },
      min: minX - minX * 0.03,
      max: maxX + maxX * 0.03,
    },
    yAxis: {
      title: {
        text: 'Expected Return',
        style: {
          color: dark ? '#ffffff' : '111',
          fontWeight: 'bold',
        },
      },
      gridLineWidth: 0,
      labels: {
        style: {
          color: dark ? '#ffffff' : '111',
        },
      },
      min: minY - minY * 0.03,
      max: maxY + maxY * 0.03,
    },
    tooltip: {
      formatter: function() {
        return 'Expected Return: ' + this.point.y + '<br/>Standard Deviation: ' + this.point.x;
      }
    },    
    plotOptions: {
      scatter: {
        marker: {
          radius: 2,
        },
      },
    },
    legend: {
      itemStyle: {
        color: dark ? 'white' : 'black' // Set the legend item color to white
      }
    },
    series: series
  };
};
