import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/config';
import { PRED_INTERVALS } from '../../Constants/consts';
import { generateColorCodedArray } from "../../utils/chart_helpers"
import { validateDates } from '../../utils/validators';
// TODO: move all the symbols lists to one slice and use them globally

const now = new Date(new Date());
const threeMonthAgo = new Date(new Date().setUTCHours(0,0,0));
threeMonthAgo.setDate(now.getDate() - 90);

const initialState = {
  get_coins_results: [],
  get_coins_status: 'idle',
  selected_coin: '',

  get_feature_transforms_status: 'idle',
  get_label_transforms_status: 'idle',
  get_models_status: 'idle',

  timeframes: PRED_INTERVALS,
  selected_timeframe: PRED_INTERVALS[1],

  start_date: Math.floor(threeMonthAgo.getTime() / 1000),
  end_date: Math.floor(now.getTime() / 1000),

  selected_labeling_method: '',
  selected_feature_set: '',
  selected_learning_model: '',

  labeling_method_options: [],
  feature_set_options: [],
  learning_model_options: [],

  get_prediction_chart_results: [],
  get_prediction_chart_status: 'idle',

  get_recent_predictions_results: [],
  get_recent_predictions_page: 1,
  get_recent_predictions_status: 'idle',

  get_recommender_symbol_timeframe_results: [],
  get_recommender_symbol_timeframe_page: 1,
  get_recommender_symbol_timeframe_status: 'idle',

  get_recommender_symbol_results: [],
  get_recommender_symbol_page: 1,
  get_recommender_symbol_status: 'idle',

  get_recommender_timeframe_results: [],
  get_recommender_timeframe_page: 1,
  get_recommender_timeframe_status: 'idle',

  get_overall_metrics_results: [],
};

export const getCoins = createAsyncThunk('deepScan/getcoins', async () => {
  const response = await axiosInstance.get('/core/stocks/?has_predictions=true', {});
  return response.data;
});

export const getFeatureTransforms = createAsyncThunk(
  'deepScan/getfeaturetransforms',
  async () => {
    const response = await axiosInstance.get(
      '/prediction/featureTransforms/',
      {}
    );
    return response.data;
  }
);

export const getLabelTransforms = createAsyncThunk(
  'deepScan/getlabeltransforms',
  async () => {
    const response = await axiosInstance.get(
      '/prediction/labelTransforms/',
      {}
    );
    return response.data;
  }
);

export const getModels = createAsyncThunk('deepScan/getmodels', async () => {
  const response = await axiosInstance.get('/prediction/models/', {});
  return response.data;
});

export const getOverallMetrics = createAsyncThunk(
  'deepScan/getoverallmetrics',
  async (params) => {
    const response = await axiosInstance.get('/prediction/overallMetrics/', {
      params: params,
    });
    return response.data;
  }
);

export const getPredictionChart = createAsyncThunk(
  'deepScan/getpredictionchart',
  async (params) => {
    const response = await axiosInstance.get('/prediction/predictionChart/', {
      params: params,
    });
    return response.data;
  }
);

export const getRecentPredictions = createAsyncThunk(
  'deepScan/getrecentprediction',
  async (params) => {
    const response = await axiosInstance.get('/prediction/recentPredictions/', {
      params: params,
    });
    return response.data;
  }
);

export const getRecommendedSymbolTimeframe = createAsyncThunk(
  'deepScan/getrecommendersymboltimeframe',
  async (params) => {
    const response = await axiosInstance.get(
      '/prediction/recommendedSymbolTimeframe/',
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const getRecommendedPredictorsForSymbol = createAsyncThunk(
  'deepScan/getrecommendedpredictorforsymbol',
  async (params) => {
    const response = await axiosInstance.get(
      '/prediction/recommendedPredictorForSymbol/',
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const getRecommendedPredictorsForTimeframe = createAsyncThunk(
  'deepScan/getrecommendedPredictorfortimeframe',
  async (params) => {
    const response = await axiosInstance.get(
      '/prediction/recommendedPredictorForTimeframe/',
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const inDepthSlice = createSlice({
  name: 'deepScan',
  initialState,
  reducers: {
    set_coin: (state, action) => {
      state.selected_coin = action.payload;
    },
    set_timeframe: (state, action) => {
      state.selected_timeframe = action.payload;
    },
    // Your existing code
    set_start_date: (state, action) => {
      if (validateDates(action.payload, state.end_date)) {
        state.start_date = action.payload;
      }
    },
    set_end_date: (state, action) => {
      if (validateDates(state.start_date, action.payload)) {
        state.end_date = action.payload;
      }
    },
    set_labeling_method: (state, action) => {
      state.selected_labeling_method = action.payload;
    },
    set_learning_model: (state, action) => {
      state.selected_learning_model = action.payload;
    },
    set_feature_set: (state, action) => {
      state.selected_feature_set = action.payload;
    },
    set_recent_predictions_current_page: (state, action) => {
      state.get_recent_predictions_page = action.payload;
    },
    set_recommender_symbol_timeframe_page: (state, action) => {
      state.get_recommender_symbol_timeframe_page = action.payload;
    },
    set_recommender_symbol_page: (state, action) => {
      state.get_recommender_symbol_page = action.payload;
    },
    set_recommender_timeframe_page: (state, action) => {
      state.get_recommender_timeframe_page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoins.pending, (state) => {
        state.get_coins_status = 'pending';
      })
      .addCase(getCoins.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.symbol, icon: item.icon };
        });
        const btc = output.find((obj) => obj.label === 'BTC-USDT');
        state.selected_coin = btc;
        state.get_coins_results = output;
        state.get_coins_status = 'fulfilled';
      })
      .addCase(getCoins.rejected, (state) => {
        state.get_coins_status = 'rejected';
      })
      .addCase(getFeatureTransforms.pending, (state) => {
        state.get_feature_transforms_status = 'pending';
      })
      .addCase(getFeatureTransforms.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_feature_set = output[0];
        state.feature_set_options = output;
        state.get_feature_transforms_status = 'fulfilled';
      })
      .addCase(getFeatureTransforms.rejected, (state) => {
        state.get_feature_transforms_status = 'rejected';
      })
      .addCase(getLabelTransforms.pending, (state) => {
        state.get_label_transforms_status = 'pending';
      })
      .addCase(getLabelTransforms.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_labeling_method = output[0];
        state.labeling_method_options = output;
        state.get_label_transforms_status = 'fulfilled';
      })
      .addCase(getLabelTransforms.rejected, (state) => {
        state.get_label_transforms_status = 'rejected';
      })
      .addCase(getModels.pending, (state) => {
        state.get_models_status = 'pending';
      })
      .addCase(getModels.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_learning_model = output[0];
        state.learning_model_options = output;
        state.get_models_status = 'fulfilled';
      })
      .addCase(getModels.rejected, (state) => {
        state.get_models_status = 'rejected';
      })
      // Compontent Data
      .addCase(getOverallMetrics.pending, (state) => {
        state.get_overall_metrics_status = 'pending';
      })
      .addCase(getOverallMetrics.fulfilled, (state, action) => {
        const data = action.payload;
        state.get_overall_metrics_results = data;
        state.get_overall_metrics_status = 'fulfilled';
      })
      .addCase(getOverallMetrics.rejected, (state) => {
        state.get_overall_metrics_status = 'rejected';
      })

      .addCase(getPredictionChart.pending, (state) => {
        state.get_prediction_chart_status = 'pending';
      })
      .addCase(getPredictionChart.fulfilled, (state, action) => {
        const data = action.payload;
        const filtered_data = data.predictions.filter((x)=> {
          return x.actual != null
        })
        const color_coded_labels = generateColorCodedArray(filtered_data, state.selected_timeframe.value)
        data.color_coded_labels = color_coded_labels;
        data.color_coded_predictions = data.predictions.map((x) => {
          return {x: x.timestamp * 1000, y: 0, color: x.prediction == 1 ? 'green' : x.prediction == 0 ? 'red' : 'gray', prediction: x.prediction == 1 ? "Up" : "Down", actual: x.actual === 0 ? "Down": x.actual === 1 ? "Up" : "N/A", probability: x.probability ? x.probability.toFixed(2) : "N/A"}
        })
        state.get_prediction_chart_results = data;
        state.get_prediction_chart_status = 'fulfilled';
      })
      .addCase(getPredictionChart.rejected, (state) => {
        state.get_prediction_chart_status = 'rejected';
      })

      .addCase(getRecentPredictions.pending, (state) => {
        state.get_recent_predictions_status = 'pending';
      })
      .addCase(getRecentPredictions.fulfilled, (state, action) => {
        const data = action.payload;
        state.get_recent_predictions_results = data;
        state.get_recent_predictions_status = 'fulfilled';
      })
      .addCase(getRecentPredictions.rejected, (state) => {
        state.get_recent_predictions_status = 'rejected';
      })

      .addCase(getRecommendedSymbolTimeframe.pending, (state) => {
        state.get_recommender_symbol_timeframe_status = 'pending';
      })
      .addCase(getRecommendedSymbolTimeframe.fulfilled, (state, action) => {
        const data = action.payload;
        state.get_recommender_symbol_timeframe_results = data
        state.get_recommender_symbol_timeframe_status = 'fulfilled';
      })
      .addCase(getRecommendedSymbolTimeframe.rejected, (state) => {
        state.get_recommender_symbol_timeframe_status = 'rejected';
      })

      .addCase(getRecommendedPredictorsForSymbol.pending, (state) => {
        state.get_recommender_symbol_status = 'pending';
      })
      .addCase(getRecommendedPredictorsForSymbol.fulfilled, (state, action) => {
        const data = action.payload;
        state.get_recommender_symbol_results = data;
        state.get_recommender_symbol_status = 'fulfilled';
      })
      .addCase(getRecommendedPredictorsForSymbol.rejected, (state) => {
        state.get_recommender_symbol_status = 'rejected';
      })

      .addCase(getRecommendedPredictorsForTimeframe.pending, (state) => {
        state.get_recommender_timeframe_status = 'pending';
      })
      .addCase(
        getRecommendedPredictorsForTimeframe.fulfilled,
        (state, action) => {
          const data = action.payload;
          state.get_recommender_timeframe_results = data;
          state.get_recommender_timeframe_status = 'fulfilled';
        }
      )
      .addCase(getRecommendedPredictorsForTimeframe.rejected, (state) => {
        state.get_recommender_timeframe_status = 'rejected';
      });
  },
});

export const {
  set_coin,
  set_timeframe,
  set_start_date,
  set_end_date,
  set_labeling_method,
  set_learning_model,
  set_feature_set,
  set_recent_predictions_current_page,
  set_recommender_symbol_timeframe_page,
  set_recommender_symbol_page,
  set_recommender_timeframe_page
} = inDepthSlice.actions;

export default inDepthSlice.reducer;
