import React from 'react';
import './style.scss';

function Footer() {
  return (
    <div className="footer__copyright text-black dark:text-white">
      Copyright © 2024{' '}
      <a href="https://finbright.org" alt="finbright">
        FinBright
      </a>
      . All rights reserved.
    </div>
  );
}

export default Footer;
