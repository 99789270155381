// Generate some sample data for ratios
// [[0,0,-0.8],[0,1,-0.1],[0,2,-0.2],[0,3,-0.2],[0,4,-0.2],[0,5,-0.30000000000000004],[0,6,-0.30000000000000004],[0,7,-0.30000000000000004],[1,0,-0.4],[1,1,-0.4],[1,2,-0.4],[1,3,-0.5],[1,4,-0.5],[1,5,-0.5],[1,6,-0.6],[1,7,-0.6],[2,0,-0.6],[2,1,-0.7000000000000001],[2,2,-0.7000000000000001],[2,3,-0.7000000000000001],[2,4,-0.8],[2,5,-0.8],[2,6,-0.1],[2,7,-0.1]]
// ["uNULipEGMulQCPPlMKZXgWTVLJhlcoaA_KTfSFJvSVQKlCMvPWXuzrKeOTHuTdpVO","uNULipEGMulQCPPlMKZXgWTVLJhlcoaA_PwLlQprMZWwQvlszZcNGTgzBSzDNSuUw","uNULipEGMulQCPPlMKZXgWTVLJhlcoaA_WGwmLoBdZFafwjrrFsbEIOmWBYSTlYTm","uNULipEGMulQCPPlMKZXgWTVLJhlcoaA_wZyofLQxCdZrnGOjfTtAbKZaOlZgKfIm","pitfJLRfzuRwVfZONCeoXjpeDVmIPDZR_KTfSFJvSVQKlCMvPWXuzrKeOTHuTdpVO","pitfJLRfzuRwVfZONCeoXjpeDVmIPDZR_PwLlQprMZWwQvlszZcNGTgzBSzDNSuUw","pitfJLRfzuRwVfZONCeoXjpeDVmIPDZR_WGwmLoBdZFafwjrrFsbEIOmWBYSTlYTm","pitfJLRfzuRwVfZONCeoXjpeDVmIPDZR_wZyofLQxCdZrnGOjfTtAbKZaOlZgKfIm"]
// ["Mr","little","wind"]
export const heatmap_options = (x_categories, y_categories, ratios, dark) => {
  const color = dark ? '#000000' : '#FAFAFA'; // Set dark or light background color
  return {
    chart: {
      backgroundColor: 'transparent',
      type: 'heatmap',
    },
    title: {
      text: ' ',
    },
    plotOptions: {
      boxplot: {
        enabled: true,
      },
    },
    xAxis: {
      lineColor: 'transparent',
      categories: x_categories,
      max: x_categories.length - 1,
      opposite: true,
      labels: {
        style: {
          color: dark ? '#FFF': '#111',
          fontSize: '14px',
        },
        allowOverlap: true,
        overflow: 'allow',
        rotation: 0,
      },
    },
    yAxis: {
      categories: y_categories,
      title: null,
      max: y_categories.length - 1,
      min: 0,
      gridLineWidth: 0,
      reversed: true,
      labels: {
        style: {
          color: dark ? '#FFF': '#111',
          fontSize: '14px',
        },
      },
    },
    colorAxis: {
      stops: [
        [0, '#8B1414'],  // Red
        [0.1, '#A42323'],
        [0.2, '#CE3B3B'],
        // [0.3, '#EF4444'],
        [0.4, '#FF6262'],
        // [0.5, '#6AFF9C'],  // Yellow
        [0.6, '#40E978'],
        // [0.7, '#2ecc63'],
        [0.8, '#22A44D'],
        [0.9, '#0B8533'],
        [1, '#005C1F']  // Green
      ],
    },
    legend: {
      enabled: false,
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      symbolHeight: 120,
    },
    tooltip: {
      formatter: function () {
        return (
          `<br />Symbol: ${this.series.xAxis.categories[this.point.x]}` +
          `<br />Prediction: ${this.point.value > 0 ? "Up" : "Down"}` +
          `<br />Avg Probability: ${Math.abs(this.point.value?.toFixed(2))}`
        );
      },
    },
    series: [
      {
        name: 'ratio per strategy and coin',
        borderWidth: 0,
        borderRadius: 10,
        margin: 0,
        data: ratios,
        rowsize: 0.7,
        colsize: 0.9,
        dataLabels: {
          enabled: false,
          color: dark ? '#FFF' : '#111',
        },
      },
    ],
    radius: function () {
      return this.point.value / 10;
    },
  };
};
