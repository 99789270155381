import moment from 'moment';

export function timeframe_formatter(timeframe) {
  switch (timeframe) {
    case 900:
      return '15 Minutes';
    case 3600:
      return '1 Hour';
    case 14400:
      return '4 Hours';
    case 86400:
      return '1 Day';
    default:
      break;
  }
}

export function from_alpha_advantage(time) {
const year = time.substr(0, 4);
const month = parseInt(time.substr(4, 2)); // Month is zero-based in JavaScript
const day = time.substr(6, 2);
const hours = time.substr(9, 2);
const minutes = time.substr(11, 2);
const seconds = time.substr(13, 2);

// const date = new Date(year, month, day, hours, minutes, seconds);
const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
return formattedDate
}

export function formatPythonTimestamp(pythonTimestamp, long = true) {
  const utcDate = moment.utc(pythonTimestamp * 1000);
  
  if (long) {
    return utcDate.format('MMM D, YYYY HH:mm:ss');
  } else {
    return utcDate.format('HH:mm:ss');
  }
}

