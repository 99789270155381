const sample_data = [
  [22, 23, 13, 12, 11, 22, 23, 12, 11, 12, 13, 22, 11, 12, 13, 12],
  [2, 2, 3, 2, 11, 2, 3, 2, 1, 2, 3, 2],
];

export const options = (green = sample_data[0], red = sample_data[1]) => {
  return {
    chart: {
      type: 'area',
      backgroundColor: 'transparent',
      height: 150,
      width: 250,
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        borderWidth: 0.1,
        borderRadius: 0,
        pointWidth: 0.1,
      },
      area: {
        stacking: 'percent',
        marker: {
          enabled: false,
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          'Date: ' +
          new Date(this.point.x).toISOString().split('T')[0] +
          '</b> <br>' +
          '<b> ' +
          'Value: ' +
          this.point.y +
          '<b/>'
        );
      },
    },
    series: [
      {
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(0, 128, 0, 0)'], // fully transparent green at the top
            [0.5, 'rgba(0, 128, 0, 0.5)'], // semi-transparent green in the middle
            [1, 'rgba(0, 128, 0, 1)'] // green at the bottom
          ]
        },
        name: 'Long Accounts',
        data: green,
      },
      {
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(255, 0, 0, 1)'], // red at the top
            [0.5, 'rgba(255, 0, 0, 0.5)'], // semi-transparent red in the middle
            [1, 'rgba(255, 0, 0, 0)'] // fully transparent red at the bottom
          ]
        },
        name: 'Short Accounts',
        data: red,
      },
    ]
    
  };
};
