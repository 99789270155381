import React, { useState } from 'react';
import HighchartsTreeChart from 'highcharts/modules/treemap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './style.scss';
import Chart from '../Chart/chart';
import Title from "../Title";

HighchartsTreeChart(Highcharts);

function TreeMap({Options, setMethod, Method, Methods, Status, ChartType, ChartTypes, SetChartTypes, TitleContent, SubTitle}) {
  return (
        Status === 'fulfilled' ? (
          <div className="col-span-2">
          <Chart key={JSON.stringify(Options)} Options={Options} />
        </div>
        ) : 
        (
          <div className="col-span-2 flex items-center justify-center">
          <div className="animate-pulse w-11/12 h-[25rem] bg-side"></div>
        </div>
        )
  );
}

export default TreeMap;
