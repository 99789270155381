import React from 'react';
import './style.scss';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useIsMobile from '../../hooks/useIsMobile';

const animation = { duration: 15000, easing: (t) => t };

function LoadingCard({ key }) {
  return (
    <div className={`col-span-${key} mx-2 w-[12rem] rounded-md h-[10rem]`}>
      <div className="flex justify-between mt-2">
        <div className="animate-pulse rounded-md bg-white w-full h-2 m-4"></div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="animate-pulse rounded-md bg-white w-full h-2 m-4"></div>
      </div>
      <div className="flex justify-between mt-2">
      <div className="animate-pulse rounded-md bg-white w-full h-2 m-4"></div>
      </div>
    </div>
  );
}

function PerformanceCards({ metrics, loading }) {
  const isMobile = useIsMobile(480);
  const isFablet = useIsMobile(768);
  const isTablet = useIsMobile(1000);

  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: 'free',
    drag: true,
    slides: {
      perView: isMobile ? 2 : isFablet ? 3 : isTablet ? 5 : 6,
      spacing: isFablet ? 8 : 14,
    },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });
  return (
    <div className="w-full">
      {loading ? (
        <div className="keen-slider w-full mt-2">
          {[...Array(6).keys()].map((x, i) => (
            <LoadingCard key={i} />
          ))}
        </div>
      ) : (
        <div className="keen-slider w-full" ref={sliderRef}>
          {metrics.map((metric) => (
            <div
              key={metric.name}
              className="keen-slider__slide slide mt-4 flex flex-col justify-between dark:bg-dark-back bg-light-back p-5 rounded-md col-span-1"
            >
              <div className="flex w-full justify-center items-center mb-2">
                <p className="font-bold">{metric.name}</p>
              </div>
              <div className="flex justify-between mt-2">
                <p className="text-[#21cc6d]">Long</p>
                <p>{metric.long || 0}</p>
              </div>
              <div className="flex justify-between mt-2">
                <p className="text-[#ef4444]">Short</p>
                <p>{metric.short || 0}</p>
              </div>
              <div className="flex justify-between mt-2">
                <p>All</p>
                <p>{metric.all || 0}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PerformanceCards;
