import { useEffect } from 'react';
import LocalStorageService from '../../api/LocalStoragrService';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    LocalStorageService.clearToken();
    navigate('/login');
  }, []);
  return <div className="w-full flex justify-center mt-8">Exiting...</div>;
};
