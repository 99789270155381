import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/config';
import axios from 'axios';
import { toast } from 'react-toastify';


const initialState = {
  value: 0,
  status: 'idle',
  theme: true,
  biggestLosers: [],
  biggestGainers: [],
  biggestGainers_status: 'idle',
  biggestLosers_status: 'idle',

  percentchangecardsResult: [],
  percentchangecards_status: 'idle',

  annualMarketCapCoingeckoResult: [],
  annualMarketCapCoingecko_status: 'idle',

  gainersOrder: 'desc',
  gainersSortField: 'percent_change',

  losersOrder: 'asc',
  losersSortField: 'percent_change',

  news: [],
  news_status: 'idle',

  marketTreemapMethod: { label: 'Market Cap', value: 'cap' },
  marketTreemapMethods: [
    { label: 'Market Cap', value: 'cap' },
    { label: 'Volume', value: 'volume' },
  ],

  MarketTreemapData: [],
  MarketTreemapStatus: 'idle',

  sentimentTreemapMethod: { label: 'Market Cap', value: 'cap' },
  sentimentTreemapMethods: [
    { label: 'Market Cap', value: 'cap' },
    { label: 'Volume', value: 'volume' },
  ],

  sentimentTreemapData: [],
  sentimentTreemapStatus: 'idle',

  marketChartOptions: [
    { label: 'Treemap', value: 'treemap' },
    { label: 'Bubble', value: 'bubble' }
  ],
  marketSelectedChart: { label: 'Treemap', value: 'treemap' },

  sentimentChartOptions: [
    { label: 'Treemap', value: 'treemap' },
    { label: 'Bubble', value: 'bubble' }
  ],
  sentimentSelectedChart: { label: 'Treemap', value: 'treemap' },

  sentimentSources: [
    { label: 'Overall', value: 'overall' },
    { label: 'Tradingview', value: 'tradingview' },
    { label: 'Coin Market Cap', value: 'cmc' }
  ],
  sentimentSelectedSource: { label: 'Overall', value: 'overall' },

  sentimentValueTypes: [
    { label: 'Title', value: 'title' },
    { label: 'Content', value: 'content' }
  ],

  overall_news_results: [],
  overall_news_status: 'idle',

  sentimentSelectedValueType: { label: 'Title', value: 'title' },
  source_weight: 0.5,
  tag_weight: 0.99,
};

export const getMarketTreemapData = createAsyncThunk(
  'data/getMarketTreemapData',
  async (data) => {
    const response = await axiosInstance.get(
      '/market/treemapByDefaultInterval/',
      { params: { method: data.method } }
    );
    return response.data;
  }
);

export const getSentimentTreemapData = createAsyncThunk(
  'data/getSentimentTreemapData',
  async (data) => {
    const response = await axiosInstance.get(
      '/sentiment/getSentimentTreemap/',
      { params: { method: data.method, source: data.source, value_type: data.value_type, source_weight: data.source_weight, tag_weight: data.tag_weight} }
    );
    return response.data;
  }
);

export const annualMarketCapCoingecko = createAsyncThunk(
  'data/annualMarketCapCoingecko',
  async () => {
    const response = await axiosInstance.get(
      '/market/annualMarketCapCoingecko/'
    );
    return response.data;
  }
);

export const percentchangecards = createAsyncThunk(
  'data/percentchangecards',
  async () => {
    const response = await axiosInstance.get('/market/percentChangeCards/');
    return response.data;
  }
);

export const getGainers = createAsyncThunk(
  'data/getGainers',
  async (data) => {
    const response = await axiosInstance.get(
      '/market/biggestPercentChangeByInterval/', {
        params: {
            interval: data['interval'] ? data['interval'] : 900,
            sort_field: data['sort_field'] ? data['sort_field'] : 'percent_change',
            order: data['order'] ? data['order'] : 'desc',
            type: 'gainers',
        }
      });
    return response.data;
  }
);

export const getLosers = createAsyncThunk(
  'data/getLosers',
  async (data) => {
    const response = await axiosInstance.get(
      '/market/biggestPercentChangeByInterval/', {
        params: {
            interval: data['interval'] ? data['interval'] : 900,
            sort_field: data['sort_field'] ? data['sort_field'] : 'percent_change',
            order: data['order'] ? data['order'] : 'asc',
            type: 'losers',
        }
      });
    return response.data;
  }
);

// export const getNews = createAsyncThunk('data/getNews', async () => {
//   const response = await axios.get(
//     'https://www.alphavantage.co/query?function=NEWS_SENTIMENT&apikey=6KG2XIDYHPTCO965&topics=blockchain&sort=LATEST'
//   );
//   return response.data;
// });

export const getOverallNews = createAsyncThunk('data/getOverallNews', async () => {
  const response = await axiosInstance.get(
    "/sentiment/getOverallNews/", {
      params: {
        page: 1
      }
    }
  );
  return response.data;
});

export const overallMarketSlice = createSlice({
  name: 'overallMarket',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    set_market_treemap_method: (state, action) => {
      state.marketTreemapMethod = action.payload;
    },
    set_sentiment_treemap_method: (state, action) => {
      state.sentimentTreemapMethod = action.payload;
    },
    set_market_chart_type: (state, action) => {
      state.marketSelectedChart = action.payload;
    },
    set_sentiment_chart_type: (state, action) => {
      state.sentimentSelectedChart = action.payload;
    },
    set_sentiment_source: (state, action) => {
      state.sentimentSelectedSource = action.payload;
    },
    set_sentiment_value_type: (state, action) => {
      state.sentimentSelectedValueType = action.payload;
    },
    set_toggle_state: (state, action) => {
      state.theme = action.payload
    },
    set_gainers_sort: (state, action) => {
      state.gainersSortField = action.payload.sortField;
      state.gainersOrder = action.payload.order;
    },
    set_losers_sort: (state, action) => {
      state.losersSortField = action.payload.sortField;
      state.losersOrder = action.payload.order;
    },
    set_source_weight: (state, action) => {
      state.source_weight = action.payload;
    },
    set_tag_weight: (state, action) => {
      state.tag_weight = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(percentchangecards.pending, (state) => {
        state.percentchangecards_status = 'pending';
      })
      .addCase(percentchangecards.rejected, (state) => {
        state.percentchangecards_status = 'rejected';
      })
      .addCase(percentchangecards.fulfilled, (state, action) => {
        state.percentchangecardsResult = action.payload.data;
        state.percentchangecards_status = 'fulfilled';
      })
      .addCase(getOverallNews.pending, (state) => {
        state.overall_news_status = 'pending';
      })
      .addCase(getOverallNews.rejected, (state) => {
        state.overall_news_status = 'rejected';
      })
      .addCase(getOverallNews.fulfilled, (state, action) => {
        const news = action.payload.results;
        const filteredNews = news.filter((item) => {
          const tickerSentiments = item.tickers;
          // Use Array.every() to check if all ticker sentiments contains "CRYPTO"
          return tickerSentiments.every((sentiment) =>
            sentiment.ticker.includes('CRYPTO')
          );
        });
        filteredNews.map(item => {
          if (item.tickers) {
              item.tickers = item.tickers.map(tickerItem => {
                  if (tickerItem.ticker.startsWith('CRYPTO:')) {
                      tickerItem.ticker = tickerItem.ticker.replace('CRYPTO:', '');
                  }
                  return tickerItem;
              });
          }
          return item;
      });
        state.overall_news_results = filteredNews;
        state.overall_news_status = 'fulfilled';
      })
      .addCase(getGainers.pending, (state) => {
        state.biggestGainers_status = 'pending';
      })
      .addCase(getGainers.rejected, (state) => {
        state.biggestGainers_status = 'rejected';
      })
      .addCase(getGainers.fulfilled, (state, action) => {
        state.biggestGainers = action.payload['pct_change'];
        state.biggestGainers_status = 'fulfilled';
      })

      .addCase(getLosers.pending, (state) => {
        state.biggestLosers_status = 'pending';
      })
      .addCase(getLosers.rejected, (state) => {
        state.biggestLosers_status = 'rejected';
      })
      .addCase(getLosers.fulfilled, (state, action) => {
        state.biggestLosers = action.payload['pct_change'];
        state.biggestLosers_status = 'fulfilled';
      })

      .addCase(annualMarketCapCoingecko.pending, (state) => {
        state.annualMarketCapCoingecko_status = 'pending';
      })
      .addCase(annualMarketCapCoingecko.rejected, (state) => {
        state.annualMarketCapCoingecko_status = 'rejected';
      })
      .addCase(annualMarketCapCoingecko.fulfilled, (state, action) => {
        var marketcapData = [];
        for (let cap of action.payload) {
          marketcapData.push([cap.time * 1000, parseFloat(cap.marketcap)]);
        }
        state.annualMarketCapCoingeckoResult = marketcapData;
        state.annualMarketCapCoingecko_status = 'fulfilled';
      })

      .addCase(getMarketTreemapData.pending, (state) => {
        state.treemapStatus = 'pending';
      })
      .addCase(getMarketTreemapData.rejected, (state) => {
        state.treemapStatus = 'rejected';
      })
      .addCase(getMarketTreemapData.fulfilled, (state, action) => {
        const data = action.payload;
      
        // Define color palettes for positive and negative changes
        const positiveColors = ['#6AFF9C', '#40E978', '#2ecc63', '#29AD55', '#22A44D', '#1B9C46', '#15903E', '#0B8533', "#02782A", "#005C1F"];
        const negativeColors = ['#FF6262', '#EF4444', "#DE3F3F", '#CE3B3B', '#B72F2F', '#A42323', '#AD2A2A', '#8B1414', '#821010', '#780606'];
        const formattedData = data.map(item => {
          const percentChange = item.percent_change;
          let color;
          if (percentChange >= 0) {
            if (percentChange >= 20) {
              color = positiveColors[9];
            } else if (percentChange >= 12) {
              color = positiveColors[8];
            } else if (percentChange >= 8) {
              color = positiveColors[7];
            } else if (percentChange >= 6) {
              color = positiveColors[6];
            } else if (percentChange >= 5) {
              color = positiveColors[5];
            } else if (percentChange >= 4) {
              color = positiveColors[4];
            } else if (percentChange >= 3) {
              color = positiveColors[3];
            } else if (percentChange >= 2) {
              color = positiveColors[2];
            } else if (percentChange >= 1) {
              color = positiveColors[1];
            } else {
              color = positiveColors[0];
            }
          } else {
            if (Math.abs(percentChange) >= 20) {
              color = negativeColors[9];
            } else if (Math.abs(percentChange) >= 12) {
              color = negativeColors[8];
            } else if (Math.abs(percentChange) >= 8) {
              color = negativeColors[7];
            } else if (Math.abs(percentChange) >= 6) {
              color = negativeColors[6];
            } else if (Math.abs(percentChange) >= 5) {
              color = negativeColors[5];
            } else if (Math.abs(percentChange) >= 4) {
              color = negativeColors[4];
            } else if (Math.abs(percentChange) >= 3) {
              color = negativeColors[3];
            } else if (Math.abs(percentChange) >= 2) {
              color = negativeColors[2];
            } else if (Math.abs(percentChange) >= 1) {
              color = negativeColors[1];
            } else {
              color = negativeColors[0];
            }
          }
          return { ...item, color };
        });
        state.MarketTreemapData = formattedData;
        state.MarketTreemapStatus = 'fulfilled';
      })
      .addCase(getSentimentTreemapData.pending, (state) => {
        state.sentimentTreemapStatus = 'pending';
      })
      .addCase(getSentimentTreemapData.rejected, (state) => {
        state.sentimentTreemapStatus = 'rejected';
      })
      .addCase(getSentimentTreemapData.fulfilled, (state, action) => {
        const data = action.payload;

        // Define color palettes for positive and negative changes
        const positiveColors = ['#6AFF9C', '#40E978', '#2ecc63', '#29AD55', '#22A44D', '#1B9C46', '#15903E', '#0B8533', "#02782A", "#005C1F"];
        const negativeColors = ['#FF6262', '#EF4444', "#DE3F3F", '#CE3B3B', '#B72F2F', '#A42323', '#AD2A2A', '#8B1414', '#821010', '#780606'];

        const formattedData = data.map(item => {
          let sentiment = item.percent_change;
      
          let color;
          if (sentiment >= 0) {
            if (sentiment >= 0.6) {
              color = positiveColors[9];
            } else if (sentiment >= 0.5) {
              color = positiveColors[8];
            } else if (sentiment >= 0.4) {
              color = positiveColors[7];
            } else if (sentiment >= 0.3) {
              color = positiveColors[6];
            } else if (sentiment >= 0.2) {
              color = positiveColors[5];
            } else if (sentiment >= 0.15) {
              color = positiveColors[4];
            } else if (sentiment >= 0.1) {
              color = positiveColors[3];
            } else if (sentiment >= 0.05) {
              color = positiveColors[2];
            } else if (sentiment >= 0.0) {
              color = positiveColors[1];
            } else {
              color = positiveColors[0];
            }
          } else {
            sentiment = Math.abs(sentiment)
            if (Math.abs(sentiment) >= 0.6) {
              color = negativeColors[9];
            } else if (sentiment >= 0.5) {
              color = negativeColors[8];
            } else if (sentiment >= 0.4) {
              color = negativeColors[7];
            } else if (sentiment >= 0.3) {
              color = negativeColors[6];
            } else if (sentiment >= 0.2) {
              color = negativeColors[5];
            } else if (sentiment >= 0.15) {
              color = negativeColors[4];
            } else if (sentiment >= 0.1) {
              color = negativeColors[3];
            } else if (sentiment >= 0.05) {
              color = negativeColors[2];
            } else if (sentiment >= 0.0) {
              color = negativeColors[1];
            } else {
              color = negativeColors[0];
            }
          }
      
          return { ...item, color };
        });
      
        state.sentimentTreemapData = formattedData;
        state.sentimentTreemapStatus = 'fulfilled';
      });
  },
});

export const { set_sentiment_source, set_sentiment_value_type, set_market_treemap_method, set_sentiment_treemap_method, set_market_chart_type, set_sentiment_chart_type, set_toggle_state, set_gainers_sort, set_losers_sort, set_source_weight, set_tag_weight } = overallMarketSlice.actions;
export default overallMarketSlice.reducer;
