import React from 'react';
import * as d3 from 'd3';

export function generateAreaChart(points, colorOption = 'green', id=1, borderRadius = 2) {
  const viewBoxWidth = points.length * 20;
  const viewBoxHeight = 60;
  const maxY = Math.max(...points);
  const minY = Math.min(...points) - ( 0.02 * Math.min(...points) );
  const yScale = viewBoxHeight / (maxY - minY);
  const area = d3
    .area()
    .x((_, i) => i * 20)
    .y0(viewBoxHeight)
    .y1((d) => (maxY - d) * yScale)
    .curve(d3.curveCatmullRom.alpha(0.1));

  const path = area(points);
  
  const gradientColors = {
    red: {
      start: '#FF0000',
      end: '#ef4444'
    },
    green: {
      start: '#003400',
      end: '#21cc6d'
    }
  };

  let selectedColor = gradientColors[colorOption];
  let chart = (
    <div style={{ width: '100%', height: '100%' }}>
      <svg
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className="chart"
        style={{ width: '100%', height: '100%' }}
      >
        <defs>
          <linearGradient id={`gradientFill${id}`} x1="100%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={selectedColor.start} stopOpacity="100%" />
            <stop offset="80%" stopColor={selectedColor.end} stopOpacity="100%" />
          </linearGradient>
        </defs>
        <path d={path} fill={`url(#gradientFill${id})`} stroke="none" />
      </svg>
    </div>
  )
  return chart;
}
