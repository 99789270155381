import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import Highcharts3D from 'highcharts/highcharts-3d';
HighchartsHeatmap(Highcharts);
HighchartsMore(Highcharts);
Highcharts3D(Highcharts);

const Chart = memo(({ Options }) => {
  return (
    <HighchartsReact
      className="highchart-component"
      highcharts={Highcharts}
      options={Options}
    />
  );
})

export default Chart