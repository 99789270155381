import { React, useEffect, useState } from 'react';
import { Table } from '../../../components/Table';
import { order_columns } from '../../../Constants/strategyTable';

function OrderSub({ Orders, Loading }) {
  const page_size = 10;
  const [page, set_page] = useState(1);
  const [orders, set_orders] = useState([]);

  useEffect(() => {
    if (Orders.length > 1) {
      const current_orders = Orders.slice((page - 1) * page_size, page * page_size);
      set_orders(current_orders)
    }
  }, [page, Orders])

  return (
    <Table
      title={''}
      subtitle={''}
      loading={Loading}
      ActiveLookBack
      setActiveLookBack
      bordered={true}
      current_page={page}
      set_current_page={(page) => { set_page(page) }}
      page_size={page_size}
      total_items={Orders.length}
      columns={order_columns}
      rows={orders}
    />
  );
}

export default OrderSub;
