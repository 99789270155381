import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Input from '../../components/Input';
import Loading from 'react-loading';
import axiosInstance from '../../api/config';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../utils/history';
import { humanReadableNumber, formatFundingRate } from '../../utils/formatPrice';
import DashboardLayout from '../../components/DashboardLayout';
import Title from '../../components/Title';
import Comparison from '../../components/ComparisonChart';
import MarketNewsTable from '../../components/MarketNewsTable';
import { sentimentOptions, LineChartWithDoubleAxis } from '../../Constants/linechart';
import { options as area_chart_options } from '../../Constants/liquidation';
import { fundingRateOptions } from '../../Constants/fundingRateOptions';
import { getStackedColumnOptions } from '../../Constants/bar';
import './style.scss';

import { CorrelationTable } from '../../components/CorrelationTable';
import { RiBarChartGroupedFill } from 'react-icons/ri';
import { TbChevronsDownRight, TbChevronsUpRight } from 'react-icons/tb';
import { options as open_interest_options } from '../../Constants/OpenInterest';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic'


import candleLoading from '../../assets/images/candleLoading.GIF';

import {
  getNews,
  getCoinCandles,
  getInitialBaseData,
  getInitialComparedData,
  getCausalities,
  getBinanceMetrics,
  technicalAnalysis,
  getCointegrations,
  getCorrelations,
  coinData,
  searchCoinPrice,
  searchCoinVolume,
  setComparedCoinPriceSearch,
  setComparedCoinVolumeSearch,
  setComparedCoinPriceInfo,
  setComparedCoinVolumeInfo,
  getCoinVolumes,
  getSentimentLineChart,
  setSentimentValueType,
  getCoinNews,
  getOverallNews
} from './slice';

import { lineChartComparisonOptions } from '../../Constants/chartsOptions';

import { CoinSummary } from '../../components/CoinSummary';
import Chart from '../../components/Chart/chart';
import TradingView from '../../components/TradingView';

// Variables
// TODO: move these to the constant modules
export const TIME_FRAMES = ['15 Min', '1 Hour', '4 Hour', '1 Day'];
const SAMPLE_NEWS_TABLE_COLUMNS = [
  { label: 'Thumbnail' },
  { label: 'Published' },
  { label: 'Title' },
  { label: 'Tags' },
];

export function Coin() {
  // states
  const { dispatch, navigate } = history;

  const overall_selector = useSelector((state) => state.overall);
  const theme = overall_selector.theme;
  const coin_selector = useSelector((state) => state.coin);
  const news_status = coin_selector.news_status;
  const coin_news = coin_selector.news;
  const base_initial_data_status = coin_selector.baseInitialDataStatus;
  const compared_initial_data_status = coin_selector.comparedInitialDataStatus;
  const coin_data = coin_selector.coinData;
  const coin_data_status = coin_selector.coinData_status;
  const binance_metrics_status = coin_selector.binanceMetricsStatus;
  const base_coin_candles = coin_selector.baseCoinCandles;
  const base_coin_volumes = coin_selector.baseCoinVolumes;
  const compared_coin_candles = coin_selector.comparedCoinCandles;
  const compared_coin_volumes = coin_selector.comparedCoinVolumes;
  const compared_coin_price_search_status = coin_selector.comparedCoinPriceSearchStatus;
  const compared_coin_price_search_results = coin_selector.comparedCoinPriceSearchResults;
  const compared_coin_volume_search_status = coin_selector.comparedCoinVolumeSearchStatus;
  const compared_coin_volume_search_results = coin_selector.comparedCoinVolumeSearchResults;
  const correlated_data = coin_selector.correlated;
  const unCorrelated_data = coin_selector.unCorrelated;
  const cointegrated_data = coin_selector.cointegrated;
  const unCointegrated_data = coin_selector.unCointegrated;
  const caus = coin_selector.causes;
  const caused_by = coin_selector.causedby;
  const causal_status = coin_selector.causality_status;
  const cointeg_status = coin_selector.cointegration_status;
  const corre_status = coin_selector.correlation_status;
  const buy_volume = coin_selector.buyVol;
  const sell_volume = coin_selector.sellVol;
  const oInterest = coin_selector.openInterest;
  const DayChange = coin_selector.oneDayChange;
  const fundRateValues = coin_selector.fundingRate;
  const fundRateLastValue = coin_selector.fundingRateLastValue;
  const base_coin_info = coin_selector.baseCoinInfo;
  const compared_coin_price_info = coin_selector.comparedCoinPriceInfo;
  const compared_coin_volume_info = coin_selector.comparedCoinVolumeInfo;
  const compared_coin_price_search = coin_selector.comparedCoinPriceSearch;
  const compared_coin_volume_search = coin_selector.comparedCoinVolumeSearch;
  const sentiment_line_chart_status = coin_selector.getSentimentLineChartStatus;
  const sentiment_line_chart_data = coin_selector.getSentimentLineChartData;
  const sentiment_value_types = coin_selector.sentimentValueTypes;
  const sentiment_value_type = coin_selector.sentimentValueType;
  const short_accounts = coin_selector.shortAccounts;
  const long_accounts = coin_selector.longAccounts;
  const top_trader_short_accounts = coin_selector.topTraderShortAccounts;
  const top_trader_long_accounts = coin_selector.topTraderLongAccounts;
  const last_accounts_ratio = coin_selector.lastAccountsRatio;
  const last_top_trader_ratio = coin_selector.lastTopTraderRatio;
  const coin_news_status = coin_selector.coin_news_status;
  const coin_news_results = coin_selector.coin_news_results;
  const open_interest_status = coin_selector.open_interest_status;
  const funding_rate_status = coin_selector.funding_rate_status;
  const top_trader_status = coin_selector.top_trader_status;
  const accounts_status = coin_selector.accounts_status;

  // TODO: handle these in the slice
  const [correlationActiveTimeFrame, setCorrelationActiveTimeFrame] = useState(
    TIME_FRAMES[3]
  );
  const [cointegradeActiveTimeFrame, setCointegradeActiveTimeFrame] = useState(
    TIME_FRAMES[3]
  );
  const [causalityActiveTimeFrame, setCausalityActiveTimeFrame] = useState(
    TIME_FRAMES[3]
  );
  const [technicalActiveTimeFrame, setTechnicalActiveTimeFrame] = useState(
    TIME_FRAMES[3]
  );
  let { coin_name } = useParams();
  coin_name = coin_name?.toUpperCase()

  // Side Effects
  useEffect(() => {
    // get initial data
    dispatch(getInitialBaseData(coin_name));
    dispatch(
      getInitialComparedData(coin_name === 'BTC-USDT' ? 'ETH-USDT' : 'BTC-USDT')
    );
    // Get news
    dispatch(getNews());
    dispatch(getOverallNews());
    // Get initial price
  }, []);

  useEffect(() => {
    const symbolId = base_coin_info?.id;
    if (symbolId) {
      dispatch(getCoinCandles({ symbolId, type: 'base' }));
      dispatch(getCoinVolumes({ symbolId, type: 'base' }));
      dispatch(getBinanceMetrics(symbolId));
      dispatch(coinData(symbolId));
      dispatch(getSentimentLineChart({ symbol_id: symbolId, value_type: sentiment_value_type.value }))
      dispatch(getCoinNews({
        symbol_id: symbolId
      }));
    }
  }, [base_initial_data_status]);

  useEffect(() => {
    const symbolId = base_coin_info?.id;
    if (symbolId) {
      dispatch(getSentimentLineChart({ symbol_id: symbolId, value_type: sentiment_value_type.value }))
    }
  }, [sentiment_value_type])

  useEffect(() => {
    const comparedPriceSymbolId = compared_coin_price_info?.id;
    const comparedVolumeSymbolId = compared_coin_volume_info?.id;
    if (comparedPriceSymbolId && comparedVolumeSymbolId) {
      dispatch(
        getCoinCandles({ symbolId: comparedPriceSymbolId, type: 'compared' })
      );
      dispatch(
        getCoinVolumes({ symbolId: comparedVolumeSymbolId, type: 'compared' })
      );
    }
  }, [compared_initial_data_status]);

  // TODO: remove this
  const getIntervalValue = (name) => {
    switch (name) {
      case TIME_FRAMES[0]:
        return 900;
      case TIME_FRAMES[1]:
        return 3600;
      case TIME_FRAMES[2]:
        return 14400;
      default:
        return 86400;
    }
  };

  // Fetch Correlations, Cointegrations, Causalities, and Technical Indicators for the first render
  useEffect(() => {
    if (base_coin_info.id) {
      dispatch(
        getCorrelations({
          symbolId: base_coin_info.id,
          interval: getIntervalValue(correlationActiveTimeFrame),
        })
      );
    }
  }, [correlationActiveTimeFrame, base_initial_data_status]);
  useEffect(() => {
    if (base_coin_info.id) {
      dispatch(
        getCointegrations({
          symbolId: base_coin_info.id,
          interval: getIntervalValue(cointegradeActiveTimeFrame),
        })
      );
    }
  }, [cointegradeActiveTimeFrame, base_initial_data_status]);

  useEffect(() => {
    if (base_coin_info.id) {
      dispatch(
        getCausalities({
          symbolId: base_coin_info.id,
          interval: getIntervalValue(causalityActiveTimeFrame),
        })
      );
    }
  }, [causalityActiveTimeFrame, base_initial_data_status]);

  useEffect(() => {
    if (base_coin_info.id) {
      dispatch(
        technicalAnalysis({
          symbolId: base_coin_info.id,
          interval: getIntervalValue(technicalActiveTimeFrame),
        })
      );
    }
  }, [technicalActiveTimeFrame, base_initial_data_status]);

// Memorizations
const oi_options = useMemo(() => {
  return open_interest_options(oInterest)
}, [open_interest_status]);

const fr_options = useMemo(() => {
  return fundingRateOptions(fundRateValues)
}, [funding_rate_status]);

const accounts_options = useMemo(() => {
  return area_chart_options(long_accounts, short_accounts)
}, [accounts_status]);

const tt_options = useMemo(() => {
  return getStackedColumnOptions(top_trader_long_accounts, top_trader_short_accounts)
}, [top_trader_status]);


  // local handlers and variables
  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      searchAction();
    }
  }
  function handleChange(event) {
    setSearchInputValue(event.target.value);
    if (!!event.target.value) {
      searchAction(event.target.value);
    } else {
      setSearchResult([]);
    }
  }
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const searchAction = (q) => {
    setIsSearchLoading(true);
    axiosInstance
      .get('/market/searchcoin/', {
        params: { search: !!q ? q : searchInputValue },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setSearchResult(res.data.slice(0, 3));
          if (!q) {
            navigate(`/coin/${res.data[0].symbol}`);
            navigate(0);
          }
        } else {
          setSearchResult([]);
        }
      })
      .catch((error) => alert(error.message))
      .finally(() => setIsSearchLoading(false));
  };

  // TODO: check if coin name exists in the database. if not, show not found page.
  // TODO: add spiner
  return (
    <DashboardLayout>
      {base_initial_data_status == 'fulfilled' && compared_initial_data_status == 'fulfilled' ? (
        <div className="flex flex-col justify-center">
          <Helmet>
            <title>{coin_name} Overview</title>
          </Helmet>
          <div>
            <div className='market-overview__header'>
              <Title
                className={'text-black'}
                title={`Coin Overview`}
                subtitle={`Dive into the Depths of ${base_coin_info.symbol}: Historical Prices, Sentiment Analysis, Trading Metrics, and Statistical Insights`}
                isPageTitle
              />
              <div className="market-overview__searchbar">
                <Input
                  placeholder="search ..."
                  onKeyDown={handleKeyDown}
                  value={searchInputValue}
                  onChange={handleChange}
                  search={true}
                  className="text-black dark:text-white bg-light-back_secondary dark:bg-dark-back_secondary"
                />
                <div className="absolute top-10 z-50 w-56">
                  {isSearchLoading ? (
                    <div className="flex bg-light-back_secondary dark:bg-dark-back_secondary rounded-xl justify-center h-8 ">
                      <Loading width={20} height={10} className="mt-1" />
                    </div>
                  ) : (
                    searchResult.map((item, i) => (
                      <div
                      key={i}
                        onClick={() => {
                          setSearchResult([])
                          navigate(`/coin/${item.symbol}`);
                          navigate(0);
                        }}
                        className="flex p-2 mt-1 bg-light-back_secondary text-black dark:bg-dark-side dark:text-white border-b dark:border-neutral-800 rounded-xl  gap-2 items-center cursor-pointer"
                      >
                        <img
                          src={item.icon}
                          alt="coin-icon"
                          className="h-7 w-7 rounded-full"
                        />
                        <p className="">{item.symbol}</p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
          {coin_data_status === 'fulfilled' ? (
            <CoinSummary coinData={coin_data} coinInfo={base_coin_info} />
          ) : null}
          <div className='mt-4 p-4 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md'>
            <div className='flex flex-col'>
              <Title
                className={'text-black'}
                title="Historical Price Chart"
                subtitle={`Explore ${coin_name} Price Trends Over Time with Interactive Candlestick Plot`}
              />
              <div className='w-full flex justify-center'>
                <div className='w-[90%]'>
                  <TradingView symbol={base_coin_info.symbol.replace('-', '') + '.P'} theme={theme} />
                </div>
              </div>
            </div>
          </div>
          <div className="sectionOne__marketInfo mt-4">
            {open_interest_status === 'fulfilled' && (
              <div className="sectionOne__marketInfo-info bg-light-back_secondary dark:bg-dark-back_secondary">
                <Title
                  title="Open Interest"
                  subtitle={`Visualizing Open Long/Short Positions for ${coin_name} Derivatives`}
                />
                <div className="infoContainer__infoHeader-chart">
                  <Chart Options={oi_options} />
                </div>
                <div className="infoContainer__badges">
                  <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                    <div className="infoContainer__badges-badge--key">
                      Last Value:
                    </div>
                    <div className="infoContainer__badges-badge--value">
                      {humanReadableNumber(oInterest.slice(-1)[0][1])}
                    </div>
                  </div>
                  <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                    <div className="infoContainer__badges-badge--key">
                      1D:
                    </div>
                    <div
                      className={
                        DayChange > 0
                          ? 'infoContainer__badges-badge--greenValue'
                          : 'infoContainer__badges-badge--redValue'
                      }
                    >
                      {DayChange}%
                    </div>
                  </div>
                </div>
              </div>
            )}

            {funding_rate_status === 'fulfilled' && (
              <div className="sectionOne__marketInfo-info bg-light-back_secondary dark:bg-dark-back_secondary">
                <Title
                  title="Funding Rate"
                  subtitle={`Understanding Market Convergence: ${coin_name} Perpetual Futures Funding Rate Analysis`}
                />
                <div className="infoContainer__infoHeader-chart">
                  <Chart Options={fr_options} />
                </div>
                <div className="infoContainer__badges">
                  <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                    <div className="infoContainer__badges-badge--key">
                      Last Rate:
                    </div>
                    <div className={`infoContainer__badges-badge--value ${fundRateLastValue >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {formatFundingRate(fundRateLastValue)}
                    </div>

                  </div>
                  {/* <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                      <div className="infoContainer__badges-badge--key">
                        24H%:
                      </div>

                      <div
                        className={
                          fundRateOneDayChange >= 0
                            ? 'infoContainer__badges-badge--greenValue'
                            : 'infoContainer__badges-badge--redValue'
                        }
                      >
                        {fundRateOneDayChange}%
                      </div>
                    </div>
                    <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                      <div className="infoContainer__badges-badge--value">
                        7D%:
                      </div>
                      <div
                        className={
                          fundRateOneWeekChange >= 0
                            ? 'infoContainer__badges-badge--greenValue'
                            : 'infoContainer__badges-badge--redValue'
                        }
                      >
                        {fundRateOneWeekChange}%
                      </div> */}
                  {/* </div> */}
                </div>
              </div>
            )}

            {accounts_status === 'fulfilled' && (
              <div className="sectionOne__marketInfo-info bg-light-back_secondary dark:bg-dark-back_secondary">
                <Title
                  title="Global Long/Short Ratio (Accounts)"
                  subtitle={`Exploring Long/Short Dynamics for ${coin_name} in the Market`}
                />
                <div className="infoContainer__infoHeader-chart rounded-xl">
                  <Chart Options={accounts_options} />
                </div>
                <div className="infoContainer__badges">
                  <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                    <div className="infoContainer__badges-badge--key">
                      Last Ratio:
                    </div>
                    <div className="infoContainer__badges-badge--value">
                      {last_accounts_ratio}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {top_trader_status === 'fulfilled' && (
              <div className="sectionOne__marketInfo-info bg-light-back_secondary dark:bg-dark-back_secondary">
                <Title
                  title="Top Trader Long/Short Ratio (Accounts)"
                  subtitle={`Analyzing Long/Short Ratio Trends Among Top Traders for ${coin_name}`}
                />
                <div className="infoContainer__infoHeader-chart">
                  <Chart Options={tt_options} />
                </div>
                <div className="infoContainer__badges">
                  <div className="infoContainer__badges-badge bg-light-back dark:bg-dark-back">
                    <div className="infoContainer__badges-badge--key">
                      Last Ratio:
                    </div>
                    <div className="infoContainer__badges-badge--value">
                      {last_top_trader_ratio}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
          <div className="flex mt-4 gap-x-5">
            <Comparison
              dark={theme}
              title={'Price Comparison'}
              subtitle={`Dynamic Analysis of Three-Month Trends: ${coin_name} vs. ${compared_coin_price_info.symbol}`}
              searchedVlaue={compared_coin_price_search}
              searchResult={
                compared_coin_price_search
                  ? compared_coin_price_search_results
                  : []
              }
              isSearchLoading={
                compared_coin_price_search_status == 'pending'
              }
              handleChange={(search_value) => {
                dispatch(setComparedCoinPriceSearch(search_value));
                dispatch(searchCoinPrice(search_value));
              }}
              handleClick={(search_item) => {
                dispatch(setComparedCoinPriceInfo(search_item));
                dispatch(
                  getCoinCandles({ symbolId: search_item.id, type: 'compared' })
                );
              }}
              // TODO: first check if the coin exists with the search api, throw error if it didn't exist and call get coinCandles if it did.
              Options={lineChartComparisonOptions(
                // base_coin_info.symbol || 'hamid',
                // compared_coin_price_info.symbol || 'hamid','
                base_coin_info.symbol,
                compared_coin_price_info.symbol,
                base_coin_candles.slice(0, 90),
                compared_coin_candles.slice(0, 90)
              )}
            />
            <Comparison
              title={'Volume Comparison'}
              subtitle={`Dynamic Analysis of Three-Month Trading Volumes: ${coin_name} vs. ${compared_coin_volume_info.symbol}`}
              searchedVlaue={compared_coin_volume_search}
              searchResult={
                compared_coin_volume_search
                  ? compared_coin_volume_search_results
                  : []
              }
              isSearchLoading={
                compared_coin_volume_search_status == 'pending'
              }
              handleChange={(search_value) => {
                dispatch(setComparedCoinVolumeSearch(search_value));
                dispatch(searchCoinVolume(search_value));
              }}
              handleClick={(search_item) => {
                dispatch(setComparedCoinVolumeInfo(search_item));
                dispatch(
                  getCoinVolumes({ symbolId: search_item.id, type: 'compared' })
                );
              }}
              // TODO: first check if the coin exists with the search api, throw error if it didn't exist and call get coinVolumes if it did.
              Options={lineChartComparisonOptions(
                base_coin_info.symbol,
                compared_coin_volume_info.symbol,
                base_coin_volumes.slice(0, 90),
                compared_coin_volumes.slice(0, 90)
              )}
            />
          </div>
          {/* <div className='w-full mt-4 p-4 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md'>
            <div className="mt-5 rounded-md grid grid-cols-2 p-5 gap-x-4 gap-y-0">
              <div className="col-span-1">
                <Title
                  title={'Sentiment and Price Comparison'}
                  subtitle={`Exploring Multi-Source Sentiment and Price Dynamics for ${coin_name}`}
                />
              </div>
              <div className="col-span-1 justify-end items-center flex my-1">
                <div className="text-base mx-1">
                  <ButtonSelectDynamic
                    onChange={(item) => {
                      dispatch(item ? setSentimentValueType(item) : null);
                    }}
                    options={sentiment_value_types}
                    value={sentiment_value_type}
                    defaultValue={sentiment_value_type}
                    label="Value Type"
                    isRequired
                  />
                </div>
                {/* <div className="text-base mx-1">
                  <ButtonSelectDynamic
                    options={market_chart_types}
                    value={market_chart_type}
                    defaultValue={market_chart_type}
                    onChange={(item) => {
                      item ? dispatch(set_market_chart_type(item)) : null;
                    }}
                    label="Chart Type"
                    isRequired
                  />
                </div>
              </div>
              {
                sentiment_line_chart_status == "fulfilled" ? (
                  <div className="col-span-2">
                    <Chart Options={LineChartWithDoubleAxis(sentiment_line_chart_data.dates, sentiment_line_chart_data.sources, sentiment_line_chart_data.tickers, 'Sentiment and Price')} />
                  </div>
                ) : (null)
              }
            </div>
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 my-4">
            <div>
              <div className="card card bg-transparent">
                <CorrelationTable
                  firstList={correlated_data}
                  secondList={unCorrelated_data}
                  firstListHeader={['Coin', 'Value']}
                  firstTitle="Most Correlated"
                  secondTitle="Least Correlated"
                  headingText="Correlation Analysis"
                  desc={`Understanding Relationships: ${coin_name} Correlations Across Different Time Frames`}
                  headingIcon={<TbChevronsUpRight size={19} color="#b6b6b6" />}
                  iconByAmount
                  setActiveTimeFrame={setCorrelationActiveTimeFrame}
                  activeTimeFrame={correlationActiveTimeFrame}
                  isLoading={corre_status !== 'fulfilled'}
                />
              </div>
            </div>
            <div>
              <div className="card bg-transparent">
                <CorrelationTable
                  firstList={cointegrated_data}
                  secondList={unCointegrated_data}
                  firstListHeader={['Coin', 'Value']}
                  firstTitle="Most Cointegrated"
                  secondTitle="Least Cointegrated"
                  headingText="Cointegration Analysis"
                  desc={`Long-Term Harmony: Exploring ${coin_name}'s Integration with Other Assets Over Time`}
                  headingIcon={
                    <TbChevronsDownRight size={19} color="#b6b6b6" />
                  }
                  iconByAmount
                  setActiveTimeFrame={setCointegradeActiveTimeFrame}
                  activeTimeFrame={cointegradeActiveTimeFrame}
                  isLoading={cointeg_status !== 'fulfilled'}
                />
              </div>
            </div>
            <div>
              <div className="card bg-transparent">
                <CorrelationTable
                  firstList={caus}
                  secondList={caused_by}
                  firstListHeader={['Coin', 'Value']}
                  firstTitle="Causing"
                  secondTitle="Caused By"
                  headingText="Granger Causality Analysis"
                  desc={`Forecasting Relationships: Coins Influenced by and Influencing ${coin_name}`}
                  headingIcon={
                    <RiBarChartGroupedFill size={16} color="#b6b6b6" />
                  }
                  setActiveTimeFrame={setCausalityActiveTimeFrame}
                  activeTimeFrame={causalityActiveTimeFrame}
                  isLoading={causal_status !== 'fulfilled'}
                />
              </div>
            </div>
          </div>
          <div>
            {coin_news_status == 'fulfilled' ? (
              <MarketNewsTable
                rows={coin_news_results.results}
                columns={SAMPLE_NEWS_TABLE_COLUMNS}
                title={(<Title
                  title="News Hub"
                  subtitle={`Stay Informed: Latest News and Sentiment Tags for ${coin_name}`}
                />)}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div className="coin__page-loading">
          <img src={candleLoading} style={{ width: 300, height: 300 }}></img>
        </div>
      )}
    </DashboardLayout>
  );
}

export default Coin;
