import React, { useRef, useState } from 'react';
import ReactMultiDatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import moment from 'moment';

import Input from '../Input';

import 'react-multi-date-picker/styles/layouts/mobile.css';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import 'react-multi-date-picker/styles/colors/green.css';
import 'react-multi-date-picker/styles/colors/purple.css';
import 'react-multi-date-picker/styles/colors/red.css';
import 'react-multi-date-picker/styles/colors/teal.css';
import 'react-multi-date-picker/styles/colors/yellow.css';

import './style.scss';

const DATEPICKER_THEMES = {
  GREEN: 'green',
  PURPLE: 'purple',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
};

export function getFormattedDateTime(date, dateFormat = 'YYYY/MM/DD hh:mm:ss') {
  return date ? date.format(dateFormat) : '';
}
export function getDiffDate(startDate, endDate) {
  const startDateObj = new Date(
    getFormattedDateTime(startDate, 'YYYY-MM-DDThh:mm:ss')
  );
  const endDateObj = new Date(
    getFormattedDateTime(endDate, 'YYYY-MM-DDThh:mm:ss')
  );
  const diff = endDateObj - startDateObj;
  return { day: diff / (1000 * 3600 * 24) };
}

function DatePicker({
  show,
  setShow,
  theme,
  dateFormat = 'YYYY/MM/DD',
  defaultDateTime,
  onFocus,
  hasTime = true,
  onChange,
  isDarkMode = true,
  placeholder = '',
  value,
  label = '',
  maxDate,
  minDate,
  rightIcon,
  inputClassName,
}) {
  const ref = useRef();

  return (
    <div>
      <Input
        onFocus={() => {
          setShow(true)
          if (show) {
            ref.current.openCalendar()
          }
        }}
        onClick={() => {
          setShow(true)
          ref.current.openCalendar()
        }}
        value={value.toLocaleDateString()}
        placeholder={placeholder}
        label={label}
        rightIcon={rightIcon}
        className={inputClassName}
      />
      {
        show &&
        (
        <div>
        <ReactMultiDatePicker
        inputClass="date-input"
        className={`rmdp-mobile ${isDarkMode ? 'bg-dark' : 'bg-gray'}`}
        shouldCloseOnSelect={true}
        ref={ref}
        value={value}
        onChange={onChange}
        onFocusedDateChange={onFocus}
        maxDate={maxDate}
        minDate={minDate}
        // range
        defaultDateTime={defaultDateTime}
        plugins={hasTime ? [<TimePicker position="bottom" />] : []}
      />
    </div>
        )
      }
    </div>
  );
}

DatePicker.themes = DATEPICKER_THEMES;
export default DatePicker;
