import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { BiSearch } from 'react-icons/bi';
import './style.scss';

function Input(
  {
    label,
    error,
    type = 'text',
    className,
    width,
    height,
    containerClassName,
    onChange = () => {},
    placeholder,
    readOnly,
    disabled,
    value,
    rightIcon,
    search,
    ...props
  },
  ref
) {
  const isEditable = !readOnly && !disabled;
  const input = (
    <div className="fin-input">
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        {!!rightIcon && rightIcon}
      </div>
      <div
        className="input-div flex"
        style={{
          height: `${height}px`,
          width: `${width}px`,
          borderRadius: '10px',
        }}
      >
        <input
          ref={ref}
          width={width}
          height={height}
          readOnly={readOnly}
          disabled={disabled}
          type={type}
          value={value}
          onChange={onChange}
          className={classNames(
            'input',
            { 'input--disabled': !isEditable },
            { 'input--error': error },
            className
          )}
          placeholder={placeholder}
          {...props}
        />
        {search && (
          <BiSearch
            size={25}
            style={{ color: 'gray', paddingTop: '8PX', paddingRight: '4PX' }}
          />
        )}
      </div>
    </div>
  );
  return (
    <div
      className={classNames('input__container flex', containerClassName)}
      style={{ textAlign: 'center' }}
    >
      {label && (
        <label
          style={{
            fontSize: '10px',
            textAlign: 'center',
            fontFamily: 'Monaco',
          }}
        >
          <span className="input__label dark:text-white ">{label}</span>
          {input}
        </label>
      )}
      {!label && input}
      {error && <div className="input__text--error">{error}</div>}
    </div>
  );
}

export default forwardRef(Input);
