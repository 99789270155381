import axios from 'axios';
import LocalStorageService from './LocalStoragrService';
import { history } from '../utils/history';

const axiosInstance = axios.create({
  baseURL: 'https://api.finbright.org',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  const refresh = LocalStorageService.getRefreshToken();
  if (refresh) {
    try {
      const resp = await axiosInstance.post('/auth/jwt/refresh/', {
        refresh: refresh,
      });
      return resp.data?.access;
    } catch (e) {
      return Promise.reject(e);
    }
  } else {
    throw 'refresh token not found';
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // Here we want to check if refresh token is expired or not and in case that it is expired we redirect to login page
    if (error.response?.status === 401) {
      LocalStorageService.clearToken();
      history.navigate('/login');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
