import { SelectInput } from '../Select';
import cx from 'classnames';
import Pagination from '../Pagination/index';
import { useState } from 'react';
import './style.scss';
import Title from '../Title';

export const Table = ({
  title,
  subtitle,
  columns,
  rows,
  set_current_page = (page) => {
  },
  page_size = 5,
  total_items = 5,
  current_page = 1,
  height_in_px = 40,
  bordered = false,
  icon = null,
  loading = true,
  onRowClick = null,
}) => {
  const totalColumns = columns.reduce(
    (total, column) => total + (column.width || 1),
    0
  );
  return (
    <div className="profitable-time-frames w-full card max-md:overflow-x-scroll rounded-lg p-6 bg-light-back_secondary dark:bg-dark-back_secondary">
      <div className="h-full">
        <Title title={title} subtitle={subtitle} end_icon={icon || null} />
        {loading ? (
          <div>
            <div className="flex justify-between max-md:w-96">
              <div
                style={{gridTemplateColumns: `repeat(${totalColumns}, minmax(0, 1fr))`}}
                className={`grid p-3 mt-3 gap-5 max-md:w-96 w-full justify-center align-center`}
              >
                {columns.map((item, i) => (
                  <div
                    key={i}
                    className={`flex text-black dark:text-white uppercase text-xs justify-center items-center w-full h-3 rounded-md ${
                      item.width ? `col-span-${item.width}` : ''
                    }`}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            {[...Array(page_size).keys()].map((_, i) => (
              <div
                style={{gridTemplateColumns: `repeat(${totalColumns}, minmax(0, 1fr))`, height: `${height_in_px}px`}}
                key={i}
                className={`grid h-[40px] gap-5 max-md:w-96 animate-pulse`}
                // border-b-[0.5px] border-gray-500
              >
                {columns.map((item, j) => (
                  <div
                    key={j}
                    className={`flex justify-center items-center ${
                      item.width ? `col-span-${item.width}` : ''
                    }`}
                  >
                    <div className="bg-gray-200 w-4/5 h-2 rounded-md"></div>
                  </div>
                ))}
              </div>
            ))}
            <div className="profitableCombination__pagination-wrapper text-black dark:text-white flex justify-between items-center p-4 mt-3 max-md:w-96">
              <div className="profitableCombination__pagination-text text-xs">
                {`showing ${(current_page - 1) * page_size + 1} to ${
                  page_size * current_page
                } of ${total_items} items`}
              </div>
              <Pagination
                className="profitableCombination__pagination"
                currentPage={current_page}
                totalCount={total_items}
                pageSize={page_size}
                siblingCount={1}
                onPageChange={(page) => set_current_page(page)}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-between max-md:w-96">
              <div
                style={{gridTemplateColumns: `repeat(${totalColumns}, minmax(0, 1fr))`}}
                className={`grid border-b border-gray-500 p-3 mt-3 gap-5 max-md:w-96 w-full`}
              >
                {columns.map((item, i) => (
                  <div
                    key={i}
                    className={`flex text-black dark:text-white uppercase text-xs w-full justify-center items-center ${
                      item.width ? `col-span-${item.width}` : ''
                    }`}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            {[...Array(page_size).keys()].map((_, i) => (
              <div
                style={{gridTemplateColumns: `repeat(${totalColumns}, minmax(0, 1fr))`, height: `${height_in_px}px`}}
                key={i}
                onClick={() => onRowClick && onRowClick(rows[i])}
                className={`grid gap-5 max-md:w-96 ${bordered ? 'border-b border-gray-500' : ''} ${onRowClick ? 'cursor-pointer' : ''}`}
                // border-b-[0.5px] border-gray-500
              >
                {columns.map((item, j) => (
                  <div
                    key={j}
                    className={`flex ${
                      item?.width ? `col-span-${item?.width}` : ''
                    } ${
                      rows[i] ? item?.classNameFn != null
                        ? item.classNameFn(rows[i][j])
                        : 'justify-center items-center' : ''
                    }`}
                  >
                    {rows[i]
                      ? rows[i][j]
                        ? item.transformFn
                          ? item.transformFn(rows[i][j])
                          : rows[i][j]
                        : '-'
                      : ' '}
                  </div>
                ))}
              </div>
            ))}
            <div className="profitableCombination__pagination-wrapper text-black dark:text-white flex justify-between items-center p-4 mt-3 max-md:w-96">
              <div className="profitableCombination__pagination-text text-xs">
                {`showing ${(current_page - 1) * page_size + 1} to ${
                  page_size * current_page
                } of ${total_items} items`}
              </div>
              <Pagination
                className="profitableCombination__pagination"
                currentPage={current_page}
                totalCount={total_items}
                pageSize={page_size}
                siblingCount={1}
                onPageChange={(page) => set_current_page(page)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
