import moment from 'moment';
import {
  HiChevronDoubleUp,
  HiChevronDoubleDown
} from 'react-icons/hi';
import { INTERVALS } from './consts';

// Define your transform and className functions
const predictionTransform = (x) =>
  { return x == 1 ?
  (<div className='flex justify-center items-center'>
    <HiChevronDoubleUp className='text-[#21cc6d]'/>
    <p>
      UP
    </p>
  </div>) : x == -1 ?
  (<div className='flex justify-center items-center'>
    <HiChevronDoubleDown className='text-[#ef4444]'/>
    <p>
      DOWN
    </p>
  </div>) : 
    (<div className='flex justify-center items-center'>
    <p>
      N/A
    </p>
  </div>)
  };

  const probabilityTransform = (x) => {
    return x ? parseFloat(x).toFixed(3) : 0;
  };
  
  const priceChangeTransform = (x) => {
    return x ? `${parseFloat(x).toFixed(2)}%` : "0.00%";
  };
  
  const priceChangeClassTransform = (x) => {
    return `${x == 0 ? "" : x < 0 ? "text-[#ef4444]" : "text-[#21cc6d]"} justify-center items-center`
  };
  
// Define your columns
export const recent_predictions_columns = [
  { name: 'Time', transformFn: (x) => moment(x).format("MM-DD HH:mm") },
  { name: 'Prediction', transformFn: predictionTransform },
  { name: 'Probability', transformFn: probabilityTransform },
  { name: 'Actual Value', transformFn: predictionTransform },
  { name: 'Price Change', transformFn: priceChangeTransform, classNameFn: priceChangeClassTransform },
];


// Define your transform functions
const symbolTransform = (x) => {
  return x ? (
    <div className="flex gap-2 items-center">
      <img
        className="rounded-full"
        width={30}
        height={30}
        alt={x.label.split('-')[0]}
        src={x.icon}
      />
      <p className="text-black dark:text-white font-normal">
        {x.label.split('-')[0]}
      </p>
    </div>
  ) : 'N/A';
};
const symbolClassFn = (x) => {
  return "justify-left pl-[3rem]";
};

const timeframeTransform = (x) => {
  return x ? INTERVALS.find((y) => y.value == x).label : 'N/A';
};

const accuracyTransform = (x) => {
  return x ? `${x}%` : "0%";
};

// Define your columns
export const recommended_symbols_timeframes_columns = [
  { name: 'Symbol', transformFn: symbolTransform, classNameFn: symbolClassFn },
  { name: 'Time Frame', transformFn: timeframeTransform },
  { name: 'Accuracy', transformFn: accuracyTransform },
];

// Define your columns
export const timeframe_recommendation_columns = [
  { name: 'Symbol', transformFn: symbolTransform, classNameFn: symbolClassFn },
  { name: 'Labeling Method' },
  { name: 'Feature Set' },
  { name: 'Learning Model' },
  { name: 'Accuracy', transformFn: accuracyTransform },
  { name: 'Precision Up', transformFn: accuracyTransform },
  { name: 'Precision Down', transformFn: accuracyTransform },
];


export const symbol_recommendation_columns = [
  { name: 'Time Frame', transformFn: timeframeTransform },
  { name: 'Labeling Method' },
  { name: 'Feature Set' },
  { name: 'Learning Model' },
  { name: 'Accuracy', transformFn: accuracyTransform },
  { name: 'Precision Up', transformFn: accuracyTransform },
  { name: 'Precision Down', transformFn: accuracyTransform },
];
