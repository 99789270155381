import Highcharts from 'highcharts/highstock';

export const stockOptions = {
  chart: {
    height: (5 / 16) * 100 + '%',
    // zoomType: "x",
    backgroundColor: '#151515',
    borderColor: '#151515',
  },

  plotOptions: {
    series: {
      dataGrouping: {
        enabled: false,
      },
      mapNavigation: {
        enabled: true,
        enableDoubleClickZoomTo: true,
      },
      marker: {
        enabled: false,
        symbol: 'diamond',
      },
      animation: {
        duration: 2000,
      },
      states: {
        hover: {
          enabled: true,
        },
        normal: {
          animation: {
            duration: 50,
          },
        },
        select: {
          enabled: false,
        },
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
  stockTools: {
    gui: {
      buttons: [
        'typeChange',
        'simpleShapes',
        'lines',
        'advanced',
        'fullScreen',
      ],
    },
  },
  navigator: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  xAxis: [
    {
      visible: true,
      min: null,
      type: 'datetime',
      categories: [],
    },
  ],
  yAxis: [
    {
      opposite: false,
      gridLineWidth: 0,
      lineColor: '#cccccc',
      lineWidth: 1,
      labels: {
        align: 'right',
      },
      height: '90%',
      resize: {
        enabled: false,
      },
    },
    {
      gridLineWidth: 0,
      opposite: false,
      labels: {
        align: 'left',
      },
      top: '90%',
      height: '10%',
      offset: 0,
    },
    {},
  ],
  tooltip: {
    shape: 'square',
    headerShape: 'callout',
    borderWidth: 0,
    shadow: false,
    positioner: function () {
      return {
        x: this.chart.plotLeft,
        y: this.chart.plotTop,
      };
    },
  },
  series: [
    {
      type: 'candlestick',
      id: 'candle-ohlc',
      data: [],
      color: '#e24648',
      upColor: '#21cc6d',
      lineColor: '#e24648',
      upLineColor: '#21cc6d',
      pointPadding: 0.5,
      boostThreshold: 5000,
      maxPointWidth: 5,
    },
    {
      type: 'column',
      id: 'volume',
      data: [],
      yAxis: 1,
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 800,
        },
        chartOptions: {
          rangeSelector: {
            inputEnabled: false,
          },
        },
      },
    ],
  },
  title: {},
};

export const lineChartComparisonOptionsVolume = {
  chart: {
    backgroundColor: 'transparent',
    height: 300,
  },
  plotOptions: {
    series: {
      pointStart: 1644550200, // point strat getting dynmic from data staring value
      pointInterval: 24 * 3600 * 1000, // Granularity need to pass dyamicly to plot chart
    },
  },
  title: {
    text: ' ',
    align: 'left',
  },
  subtitle: {
    // text: 'Source: WorldClimate.com',
    align: 'left',
  },
  xAxis: [
    {
      categories: [],
      type: 'datetime',
      labels: {
        format: '{value:%Y-%m-%d}',
        style: {
          color: '#b6b6b6',
        },
      },
      lineColor: 'transparent',
      rotation: -50,
      align: 'right',
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      gridLineWidth: 0,
      labels: {
        // format: "{value}",
        style: {
          color: '#b6b6b6',
        },
      },
      title: {
        text: ' ',
        style: {
          color: 'red',
          // color: Highcharts.getOptions().colors[2]
        },
      },
      opposite: true,
    },
    {
      // Secondary yAxis
      // gridLineColor: '#ffffff',
      gridLineWidth: 0,
      title: {
        text: ' ',
        style: {
          color: ' ',
        },
      },
      labels: {
        // format: "{value}",
        style: {
          color: '#b6b6b6',
        },
      },
    },
  ],
  tooltip: { enabled: false },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: 'Ra nfall',
      type: 'area',
      yAxis: 1,
      data: [],
      marker: false,
      color: '#b6b6b6',
      enableMouseTracking: false,
      fillColor: 'transparent',
    },
    {
      name: 'Temperature',
      type: 'line',
      data: [],
      marker: false,
      enableMouseTracking: false,
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            floating: false,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0,
          },
          yAxis: [
            {
              labels: {
                align: 'right',
                x: 0,
                y: -6,
              },
              showLastLabel: false,
            },
            {
              labels: {
                align: 'left',
                x: 0,
                y: -6,
              },
              showLastLabel: false,
            },
            {
              visible: false,
            },
          ],
        },
      },
    ],
  },
};

export const lineChartComparisonOptions = (first_series_symbol, second_series_symbol, first_series, second_series) => {
  // Calculate the maximum and minimum values for each series
  const firstSeriesMax = Math.max(...first_series.map((point) => point[1])) * 1.02;
  const firstSeriesMin = Math.min(...first_series.map((point) => point[1])) * 0.95;
  const secondSeriesMax = Math.max(...second_series.map((point) => point[1])) * 1.02;
  const secondSeriesMin = Math.min(...second_series.map((point) => point[1])) * 0.95;

  return {
    chart: {
      backgroundColor: 'transparent',
      height: 300,
    },
    title: {
      text: ' ',
      align: 'left',
    },
    subtitle: {
      align: 'left',
    },
    tooltip: { enabled: true },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Base Coin',
        type: 'area',
        color: '#b6b6b6',
        data: first_series,
        marker: true,
        fillColor: 'transparent',
        enableMouseTracking: true,
        yAxis: 0, // Assign this series to the first y-axis
      },
      {
        name: 'Compared Coin',
        type: 'line',
        color: "#7673FF",
        data: second_series,
        marker: true,
        fillColor: 'transparent',
        enableMouseTracking: true,
        yAxis: 1, // Assign this series to the second y-axis
      },
    ],
    yAxis: [
      {
        gridLineWidth: 0,
        labels: {
          style: {
            color: '#b6b6b6',
          },
        },
        title: {
          text: first_series_symbol,
          style: {
            color: '#b6b6b6',
          },
        },
        opposite: true,
        min: firstSeriesMin, // Set the minimum value for the first y-axis
        max: firstSeriesMax, // Set the maximum value for the first y-axis
      },
      {
        gridLineWidth: 0,
        title: {
          text: second_series_symbol,
          style: {
            color: '#7673FF',
          },
        },
        labels: {
          style: {
            color: '#7673FF',
          },
        },
        min: secondSeriesMin, // Set the minimum value for the second y-axis
        max: secondSeriesMax, // Set the maximum value for the second y-axis
      },
    ],
    xAxis: {
      lineColor: '#151515',
      visible: true,
      type: 'datetime',
      tickInterval: 7 * 24 * 3600 * 1000,
      labels: {
        format: '{value:%Y-%m-%d}',
      } 
    }
  };
};

export const newsOptions = {
  chart: {
    zoomType: 'x',
    backgroundColor: '#151515',
    borderColor: '#151515',
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    // gridLineColor: null,
    gridLineWidth: 0,
    series: {
      marker: {
        enabled: false,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  xAxis: {
    crosshair: true,
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'XBTUSD',
      },
      height: '50%',
      // lineWidth: 2,
    },
    {
      gridLineWidth: 0,
      title: {
        text: 'Sources',
      },
      top: '50%',
      height: '50%',
      offset: 0,
      // lineWidth: 2,
    },
  ],
  // tooltip: {
  //   split: true,
  //   distance: 30,
  //   padding: 5,
  //   valueDecimals: 2,
  // },
  series: [
    {
      type: 'line',
      name: 'XBTUSD',
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 6, 3, 2, 4, 6, 3, 1],
      tooltip: {
        pointFormatter: function () {
          return '<span style="color:{series.color}">●</span>First <strong>{data[0]}</strong> series.';
        },
      },
    },
    {
      type: 'line',
      name: 'XBTUSD',
      data: [2, 3, 4, 5, 6, 2, 3, 4, 5, 2, 1, 3, 5, 3, 2, 1],
      yAxis: 0,
    },
    {
      type: 'spline',
      name: 'Twitter',
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 6, 3, 2, 4, 6, 3, 1],
      yAxis: 1,
    },
    {
      type: 'spline',
      name: 'Reddit',
      data: [2, 3, 4, 5, 6, 2, 3, 4, 5, 2, 1, 3, 5, 3, 2, 1],
      yAxis: 1,
    },
    {
      type: 'spline',
      name: 'Bitcointalk',
      data: [2, 4, 4, 6, 6, 2, 3, 8, 9, 3, 1, 15, 5, 4, 2, 1],
      yAxis: 1,
    },
  ],
};
