import {humanReadableNumber} from '../utils/formatPrice'
import moment from 'moment';

export const fundingRateOptions = (series) => {
  return {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: 157,
      width: 250,
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        borderWidth: 0.1,
        pointWidth: 1,
        groupPadding: 0.1,
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          'Date: ' + moment.utc(new Date(this.point.x)).format("YYYY-MM-DD HH:mm") +
          '</b> <br>' +
          '<b> ' +
          'Value: ' + this.point.y +
          '<b/>'
        );
      },
    },
    series: [
      {
        color: 'green',
        negativeColor: 'red',
        name: 'Funding Rate',
        data: series,
      },
    ],
  };
  
}