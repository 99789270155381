import { React, useEffect, useState } from 'react';
import { Table } from '../../../components/Table';
import { position_columns } from '../../../Constants/strategyTable';

function PositionSub({ Positions, Loading=true }) {
  const page_size = 5;
  const [page, set_page] = useState(1);
  const [positions, set_positions] = useState([]);

  useEffect(() => {
    if (Positions.length > 1) {
      const current_positions = Positions.slice((page - 1) * page_size, page * page_size);
      set_positions(current_positions)
    }
  }, [page, Positions])

  return (
    <Table
      title={''}
      subtitle={''}
      loading={Loading}
      ActiveLookBack
      setActiveLookBack
      current_page={page}
      height_in_px={80}
      bordered={true}
      set_current_page={(page) => set_page(page) }
      page_size={page_size}
      total_items={Positions.length}
      columns={position_columns}
      rows={positions}
    />
  );
}

export default PositionSub;
