import './style.scss';
import Chart from '../../components/Chart/chart';
import { HiAdjustments } from 'react-icons/hi';
import { MdStackedLineChart } from 'react-icons/md';
import { TbChartLine } from 'react-icons/tb';
import Title from '../Title';
import { tailwindColors } from '../../tailwind';
import Loading from 'react-loading';

function Comparison({Options, isSearchLoading=false, searchResult=[], handleChange, searchedVlaue, handleClick, title, subtitle, dark=true}) {
  return (
      <div className="coin-comparisons__Chart bg-light-back_secondary dark:bg-dark-back_secondary">
        <div className="m-7">
          <div className="coin-comparisons__Chart-header">
            <Title
              title={title}
              subtitle={subtitle}
              icon={
                <MdStackedLineChart size={20} color={tailwindColors.gray[400]} />
              }
              className="py-0"
            />
            <div className="coin-comparisons__Chart-header--search ">
              <div className="relative">
                <input
                  className="comparison_button rounded-md bg-white dark:bg-black"
                  type="text"
                  placeholder="search coin ..."
                  onChange={(e) => handleChange(e.target?.value)}
                  value={searchedVlaue}
                />
                <div className="absolute top-10 z-50 w-56 ">
                  {isSearchLoading ? (
                    <div className="flex bg-black rounded-xl justify-center h-8 ">
                      <Loading width={20} height={10} className="mt-1" />
                    </div>
                  ) : (
                    searchResult.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleClick(item)}
                        className="flex p-1 bg-black text-white border-b border-neutral-800 rounded-xl  gap-2 items-center cursor-pointer"
                      >
                        <img
                          src={item.icon}
                          alt="coin-icon"
                          className="h-7 w-7"
                        />
                        <p className="">{item.symbol}</p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {Options ? (
          <div className="comparison_chart_holder">
            <Chart Options={Options} />
          </div>
        ) : (
          <div
            className="animate-pulse"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TbChartLine
              style={{ color: 'gray', height: '50%', width: '50%' }}
            />
          </div>
        )}
      </div>
  );
}

export default Comparison;
