import Highcharts from 'highcharts';
import { tailwindColors } from '../tailwind';

export const QuickScanOption = (categories, longs, shorts, theme = true) => {
  const min_value = Math.min(
    ...shorts.map((x) => {
      return x.y;
    })
  );
  const max_value = Math.max(
    ...longs.map((x) => {
      return x.y;
    })
  );
  return {
    chart: {
      type: 'bar',
      events: {
        render: function () {
          var points = this.series[0].points;

          Highcharts.each(points, function (point) {
            var label = point?.dataLabel;
            if (point.shapeArgs.width < label?.width) {
              if (label?.visibility !== 'hidden') {
                label.hide();
              }
            } else {
              if (label?.visibility === 'hidden') {
                label.show();
              }
            }
          });
        },
      },
      backgroundColor: 'transparent',
      animation: true,
      borderColor: tailwindColors.neutral[900],
      height: 200,
      width: 340,
    },
    title: '',
    legend: {
      enabled: false,
    },
    // tooltip: {
    //   enabled: false,
    // },
    // accessibility: {
    //   point: {
    //     valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.',
    //   },
    // },
    xAxis: [
      {
        gridLineWidth: 0,
        categories: categories,
        reversed: true,
        labels: {
          style: {
            color: theme ? tailwindColors.white : tailwindColors.dark,
          },
        },
        // accessibility: {
        //   description: 'Age (male)',
        // },
      },
    ],

    yAxis: {
      max: max_value, // Add your dynamic max value here
      min: min_value, // Add your dynamic min value here
      gridLineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        formatter: function () {
          return Math.abs(this.value);
        },
        style: {
          color: theme ? tailwindColors.white : tailwindColors.dark,
        },
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointPadding: 0,
        pointWidth: 8,
        states: {
          hover: {
            brightness: 0.5, // darken
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      bar: {},
    },

    tooltip: {
      backgroundColor: 'rgba(21, 21, 21, 0.7)',
      style: {
        color: tailwindColors.neutral[400],
      },
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ' ' +
          this.point.category +
          '</b><br/>' +
          'Number of Signals: ' +
          Math.abs(this.point.y?.toFixed(2))
        );
      },
    },
    series: [
      {
        borderColor: tailwindColors.green[500],
        color: tailwindColors.green[500],
        name: 'Buy',
        data: longs,
      },
      {
        borderColor: tailwindColors.red[500],
        color: tailwindColors.red[500],
        name: 'Sell',
        data: shorts,
      },
    ],
  };
};
