import './style.scss';
import { HiNewspaper, HiCalendar } from 'react-icons/hi';
import { MdSource } from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { from_alpha_advantage } from '../../utils/formatTime';
import moment from 'moment';
import Title from '../Title'
import { tailwindColors } from '../../tailwind';

export const MarketNews = ({ rows, loading=true }) => {
  const loadingItem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="market-news p-5 card h-144 overflow-y-scroll rounded-md bg-light-back_secondary dark:bg-dark-back_secondary scroller">
      <Title
        title="News Pulse"
        // icon={<HiNewspaper size={20} color={tailwindColors.gray[400]} />}
        subtitle={"Stay Informed with Real-time Updates and Sentiment Analysis"}
      />
      { loading ? (
        loadingItem.map((item) => (
          <div className="px-3 py-2 my-3 w-auto mx-3  bg-neutral-800 rounded-md ">
            <div className="flex gap-4">
              <svg
                className="rounded-md animate-pulse"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 640 512"
                height={18}
                width={85}
                style={{ marginTop: '20px', color: 'white' }}
              >
                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
              </svg>
              <div className="w-full ">
                <div className="flex items-center  gap-1 animate-pulse">
                  <MdSource size={20} color="#b0b2b2" />
                  <div
                    style={{
                      backgroundColor: 'gray',
                      width: '150px',
                      height: '10px',
                      borderRadius: '10px',
                    }}
                  ></div>
                </div>
                <div className="flex items-center gap-1 mt-1 animate-pulse">
                  <HiCalendar size={20} color="#b0b2b2" />
                  <div
                    style={{
                      backgroundColor: 'gray',
                      width: '150px',
                      height: '10px',
                      borderRadius: '10px',
                    }}
                  ></div>
                </div>
                <div className="flex items-center gap-1 text-xs mt-1 animate-pulse">
                  <BsFillPersonFill size={20} color="#b0b2b2" />
                  <p className="text-gray-800 dark:text-neutral-400 text-xs">
                    <div
                      style={{
                        backgroundColor: 'gray',
                        width: '150px',
                        height: '10px',
                        borderRadius: '10px',
                      }}
                    ></div>
                  </p>
                </div>
              </div>
            </div>
            <p className="text-white text-justify text-xs mt-2 text-over">
              <div
                className="animate-pulse"
                style={{
                  backgroundColor: 'gray',
                  width: '150px',
                  height: '10px',
                  borderRadius: '10px',
                  marginTop: '10px',
                }}
              ></div>
              <div
                className="animate-pulse"
                style={{
                  backgroundColor: 'gray',
                  width: '300px',
                  height: '10px',
                  borderRadius: '10px',
                  marginTop: '10px',
                }}
              ></div>
              <div
                className="animate-pulse"
                style={{
                  backgroundColor: 'gray',
                  width: '250px',
                  height: '10px',
                  borderRadius: '10px',
                  marginTop: '10px',
                }}
              ></div>
            </p>
            <div className="flex justify-between w-full">
              <div className="flex mt-1 items-center justify-between w-full ">
                <div className="flex w-full items-center"></div>
              </div>
              <div
                className="animate-pulse"
                style={{
                  backgroundColor: 'gray',
                  width: '150px',
                  height: '10px',
                  borderRadius: '10px',
                  marginTop: '10px',
                }}
              ></div>
            </div>
          </div>
      ))
      ) : (
          rows.map((item, index) => (
            <div
              key={index}
              className=" py-2 my-3 w-auto bg-light-back_secondary dark:bg-dark-back_secondary rounded-md"
            >
              <div className="flex gap-4">
                <img
                  className=" rounded-md"
                  height={18}
                  width={85}
                  src={item?.banner_image}
                  alt="banner"
                />
                <div className="w-full ">
                  <div className="flex items-center  gap-1">
                    <MdSource size={20} color="#b0b2b2" />
                    <p className="text-gray-800 dark:text-neutral-400 text-xs">{item?.source}</p>
                  </div>
    
                  <div className="flex items-center gap-1 mt-1">
                    <HiCalendar size={20} color="#b0b2b2" />
                    <p className="text-gray-800 dark:text-neutral-400 text-xs">{moment(item?.time).format("YYYY-MM-DD hh:mm")}</p>
                  </div>
    
                  <div className="flex items-center gap-1 text-xs mt-1">
                    <BsFillPersonFill size={20} color="#b0b2b2" />
                    <p className="text-gray-800 dark:text-neutral-400 text-xs">
                      {!!item?.authors ? item?.authors[0] : 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
              <p className="text-gray-800 dark:text-neutral-400 text-justify text-xs mt-2 text-over">
                {item?.title}
              </p>
              <div className="flex justify-between w-full">
                <div className="flex mt-1 items-center justify-between w-full ">
                  <div className="flex w-full items-center">
                    {item.tickers[0] ? (
                      <div className="news-table__coin text-xs mx-1">
                        {item.tickers[0].ticker}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div
                      className={
                        item.sentiment === 'Somewhat-Bullish'
                          ? 'news-table__somewhat-Bullish items-center text-xs'
                          : item.sentiment === 'Bullish'
                          ? 'news-table__bullish text-xs'
                          : item.sentiment === 'Neutral'
                          ? 'news-table__neutral text-xs'
                          : item.sentiment === 'Bearish'
                          ? 'news-table__bearish text-xs'
                          : item.sentiment === 'Somewhat-Bearish'
                          ? 'news-table__somewhat-Bearish w-fit text-xs'
                          : ''
                      }
                    >
                      {item.sentiment}
                    </div>
                  </div>
                </div>
                <a
                  className="text-left underline text-sm flex w-full justify-end mt-2  text-sky-500"
                  href={item?.url}
                  target="_blank"
                >
                  Read More
                </a>
              </div>
            </div>
          )))}
    </div>
  ) 
  // : (
  //   <div className="market-news p-5 card h-144 overflow-y-scroll rounded-md bg-light-back_secondary dark:bg-dark-back_secondary scroller">
  //     <h2 className="title text-white px-6 py-5 flex gap-2 items-center sticky top-0 w-full card">
  //       <HiNewspaper size={20} color="#b0b2b2" />
  //       <span>Latest News</span>
  //     </h2>
  //     {loadingItem.map((item) => (
  //       <div className="px-3 py-2 my-3 w-auto mx-3  bg-neutral-800 rounded-md ">
  //         <div className="flex gap-4">
  //           <svg
  //             className="rounded-md animate-pulse"
  //             xmlns="http://www.w3.org/2000/svg"
  //             aria-hidden="true"
  //             fill="currentColor"
  //             viewBox="0 0 640 512"
  //             height={18}
  //             width={85}
  //             style={{ marginTop: '20px', color: 'white' }}
  //           >
  //             <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
  //           </svg>
  //           <div className="w-full ">
  //             <div className="flex items-center  gap-1 animate-pulse">
  //               <MdSource size={20} color="#b0b2b2" />
  //               <div
  //                 style={{
  //                   backgroundColor: 'gray',
  //                   width: '150px',
  //                   height: '10px',
  //                   borderRadius: '10px',
  //                 }}
  //               ></div>
  //             </div>
  //             <div className="flex items-center gap-1 mt-1 animate-pulse">
  //               <HiCalendar size={20} color="#b0b2b2" />
  //               <div
  //                 style={{
  //                   backgroundColor: 'gray',
  //                   width: '150px',
  //                   height: '10px',
  //                   borderRadius: '10px',
  //                 }}
  //               ></div>
  //             </div>
  //             <div className="flex items-center gap-1 text-xs mt-1 animate-pulse">
  //               <BsFillPersonFill size={20} color="#b0b2b2" />
  //               <p className="text-gray-800 dark:text-neutral-400 text-xs">
  //                 <div
  //                   style={{
  //                     backgroundColor: 'gray',
  //                     width: '150px',
  //                     height: '10px',
  //                     borderRadius: '10px',
  //                   }}
  //                 ></div>
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //         <p className="text-white text-justify text-xs mt-2 text-over">
  //           <div
  //             className="animate-pulse"
  //             style={{
  //               backgroundColor: 'gray',
  //               width: '150px',
  //               height: '10px',
  //               borderRadius: '10px',
  //               marginTop: '10px',
  //             }}
  //           ></div>
  //           <div
  //             className="animate-pulse"
  //             style={{
  //               backgroundColor: 'gray',
  //               width: '300px',
  //               height: '10px',
  //               borderRadius: '10px',
  //               marginTop: '10px',
  //             }}
  //           ></div>
  //           <div
  //             className="animate-pulse"
  //             style={{
  //               backgroundColor: 'gray',
  //               width: '250px',
  //               height: '10px',
  //               borderRadius: '10px',
  //               marginTop: '10px',
  //             }}
  //           ></div>
  //         </p>
  //         <div className="flex justify-between w-full">
  //           <div className="flex mt-1 items-center justify-between w-full ">
  //             <div className="flex w-full items-center"></div>
  //           </div>
  //           <div
  //             className="animate-pulse"
  //             style={{
  //               backgroundColor: 'gray',
  //               width: '150px',
  //               height: '10px',
  //               borderRadius: '10px',
  //               marginTop: '10px',
  //             }}
  //           ></div>
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );
};



{/* 
(
      rows.slice(2, 20).map((item, index) => (
        <div
          key={index}
          className=" py-2 my-3 w-auto bg-light-back_secondary dark:bg-dark-back_secondary rounded-md"
        >
          <div className="flex gap-4">
            <img
              className=" rounded-md"
              height={18}
              width={85}
              src={item?.banner_image}
              alt="banner"
            />
            <div className="w-full ">
              <div className="flex items-center  gap-1">
                <MdSource size={20} color="#b0b2b2" />
                <p className="text-gray-800 dark:text-neutral-400 text-xs">{item?.source}</p>
              </div>

              <div className="flex items-center gap-1 mt-1">
                <HiCalendar size={20} color="#b0b2b2" />
                <p className="text-gray-800 dark:text-neutral-400 text-xs">{from_alpha_advantage(item?.time_published)}</p>
              </div>

              <div className="flex items-center gap-1 text-xs mt-1">
                <BsFillPersonFill size={20} color="#b0b2b2" />
                <p className="text-gray-800 dark:text-neutral-400 text-xs">
                  {!!item?.authors[0] ? item?.authors[0] : 'Unkown'}
                </p>
              </div>
            </div>
          </div>
          <p className="text-gray-800 dark:text-neutral-400 text-justify text-xs mt-2 text-over">
            {item?.summary}
          </p>
          <div className="flex justify-between w-full">
            <div className="flex mt-1 items-center justify-between w-full ">
              <div className="flex w-full items-center">
                {item.ticker_sentiment[0] ? (
                  <div className="news-table__coin text-xs">
                    {item.ticker_sentiment[0].ticker}
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  className={
                    item.overall_sentiment_label === 'Somewhat-Bullish'
                      ? 'news-table__somewhat-Bullish items-center text-xs'
                      : item.overall_sentiment_label === 'Bullish'
                      ? 'news-table__bullish text-xs'
                      : item.overall_sentiment_label === 'Neutral'
                      ? 'news-table__neutral text-xs'
                      : item.overall_sentiment_label === 'Bearish'
                      ? 'news-table__bearish text-xs'
                      : item.overall_sentiment_label === 'Somewhat-Bearish'
                      ? 'news-table__somewhat-Bearish w-fit text-xs'
                      : ''
                  }
                >
                  {item.overall_sentiment_label}
                </div>
              </div>
            </div>
            <a
              className="text-left underline text-sm flex w-full justify-end mt-2  text-sky-500"
              href={item?.url}
              target="_blank"
            >
              Read More
            </a>
          </div>
        </div>
      )))} */}



      {/*  */}