import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/config';
import { PRED_INTERVALS } from '../../Constants/consts';

const initialState = {
  get_coins_results: [],
  get_coins_status: 'idle',

  get_heatmap_status: 'idle',
  get_cards_status: 'idle',

  get_heatmap_results: [],
  get_cards_results: [],

  timeframes: PRED_INTERVALS,
  selected_heatmap_timeframe: PRED_INTERVALS[0],
  selected_cards_timeframe: PRED_INTERVALS[0],
};

export const getCoins = createAsyncThunk('quickScan/getcoins', async () => {
  const response = await axiosInstance.get('/core/stocks/?has_predictions=true', {});
  return response.data;
});

export const getHeatmap = createAsyncThunk('quickScan/getHeatmap', async (params) => {
  const response = await axiosInstance.get('/prediction/rapidInsightHeatmap/', {
    params: params
  });
  return response.data;
});

export const getCards = createAsyncThunk('quickScan/getCards', async (params) => {
  const response = await axiosInstance.get('/prediction/rapidInsightCards/', {
    params: params
  });
  return response.data;
});

export const rapidSlice = createSlice({
  name: 'quickScan',
  initialState,
  reducers: {
    set_heatmap_timeframe: (state, action) => {
      state.selected_heatmap_timeframe = action.payload;
    },
    set_cards_timeframe: (state, action) => {
      state.selected_cards_timeframe = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getCoins.pending, (state) => {
      state.get_coins_status = 'pending';
    })
    .addCase(getCoins.fulfilled, (state, action) => {
      const data = action.payload;
      const output = data.map((item) => {
        return { value: item.id, label: item.symbol, icon: item.icon };
      });
      const btc = output.find((obj) => obj.label === 'BTC-USDT');
      state.selected_coin = btc;
      state.get_coins_results = output;
      state.get_coins_status = 'fulfilled';
    })
    .addCase(getCoins.rejected, (state) => {
      state.get_coins_status = 'rejected';
    })
    .addCase(getHeatmap.pending, (state) => {
      state.get_heatmap_status = 'pending';
    })
    .addCase(getHeatmap.fulfilled, (state, action) => {
      const data = action.payload;
      // if (data?[0].)
      
      state.get_heatmap_results = data;
      state.get_heatmap_status = 'fulfilled';
    })
    .addCase(getHeatmap.rejected, (state) => {
      state.get_heatmap_status = 'rejected';
    })
    .addCase(getCards.pending, (state) => {
      state.get_cards_status = 'pending';
    })
    .addCase(getCards.fulfilled, (state, action) => {
      const data = action.payload;
      state.get_cards_results = data;
      state.get_cards_status = 'fulfilled';
    })
    .addCase(getCards.rejected, (state) => {
      state.get_cards_status = 'rejected';
    });
  },
});

export const {set_cards_timeframe, set_heatmap_timeframe} = rapidSlice.actions;;

export default rapidSlice.reducer;
