export const INTERVALS = [
  {
    value: 900,
    label: '15 Minutes',
  },
  {
    value: 3600,
    label: '1 Hour',
  },
  {
    value: 14400,
    label: '4 Hours',
  },
  {
    value: 86400,
    label: '1 Day',
  },
];

export const PRED_INTERVALS = [
  {
    value: 3600,
    label: '1 Hour',
  },
  {
    value: 14400,
    label: '4 Hours',
  }
];

export const LOOKBACKS = [
  {
    value: 30,
    label: '30 Days',
  },
  {
    value: 7,
    label: '1 Week',
  },
  {
    value: 1,
    label: '1 Day',
  },
];

export const POSITION_TYPES = [
  {
    value: 1,
    label: 'Long',
  },
  {
    value: -1,
    label: 'Short',
  },
];

export const MARGIN_METHODS = [
  {
    value: "fixed",
    label: 'Fixed',
  },
  {
    value: 'compound',
    label: 'Compound',
  },
];

export const SIGNAL_TYPES = [
  {
    value: 'Entry',
    label: 'Entry',
  },
  {
    value: 'Exit',
    label: 'Exit',
  },
];
