import React, { memo } from 'react';
import classNames from 'classnames';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import './style.scss';
import { useSelector } from 'react-redux';

import { useEffect, useState } from 'react';

const TradingView = ({ symbol, className, theme }) => {
  const [dark, setDark] = useState(theme ? 'dark' : 'light');

  useEffect(() => {
    setDark(theme ? 'dark' : 'light');
  }, [theme]);

  return (
    <div className={classNames('tradingview-widget-wrapper', className)}>
      <AdvancedRealTimeChart
        container_id="chart_tv_const"
        theme={dark}
        symbol={symbol}
        allow_symbol_change={false}
        interval="D"
        autosize
        disabled_features={["chart_zoom",  "context_menus"]}
        range="12M"
      ></AdvancedRealTimeChart>
    </div>
  );
};

export default TradingView;
