import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const useIsMobile = (minWidth = 768) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= minWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      setIsMobile(window.innerWidth <= minWidth);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return isMobile;
};

export default useIsMobile;
