import { configureStore } from '@reduxjs/toolkit';
import overallMarketReducer from '../pages/OverallMarket/slice';
import authReducer from '../pages/Login/slice';
import coinReducer from '../pages/Coin/slice';
import wizardReducer from '../pages/Wizard/slice';
import rapidReducer from '../pages/Rapid/slice';
import pulseReducer from '../pages/Pulse/slice';
import analyzerReducer from '../pages/PortfolioAnalyzer/slice';
import inDepthReducer from '../pages/InDepth/slice';

export const store = configureStore({
  reducer: {
    overall: overallMarketReducer,
    coin: coinReducer,
    auth: authReducer,
    wizard: wizardReducer,
    rapid: rapidReducer,
    pulse: pulseReducer,
    analyzer: analyzerReducer,
    inDepth: inDepthReducer
  },
  devTools: true,
});
