const marketcapOptions = (data, dark=true, pointWidth=6)=> {
  const values = data.map((x)=>{
    return x[1]
  })
  const min = Math.min(...values) * 0.98
  const max = Math.max(...values) * 1.02

  return {
    chart: {
      type: 'column',
      backgroundColor: dark ? '#202020' : "#E5E5E5",
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderRadius: 0.5,
        pointPadding: 0,
        pointWidth: pointWidth,
      },
      series: {
        color: '#4169e1',
        borderColor: '#4169e1',
        marker: {
          states: {
            hover: {
              fillColor: 'red',
            },
          },
        },
      },
    },
    xAxis: {
      lineColor: '#151515',
      visible: true,
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000,
      labels: {
        format: '{value:%Y-%m-%d}',
      },
    },
    yAxis: {
      min: min,
      gridLineWidth: 0,
      max: max,
      title: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        text: 'Market Cap',
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}Trillion)<br/>',
      xDateFormat: '%A, %b %e',
      shared: true,
    },
    series: [
      {
        name: 'Market Cap',
        data: data,
      },
    ],
  };
  
}
export default marketcapOptions;
