import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../api/config';

const getToDay = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  var lastYear = yyyy + '-' + mm + '-' + (dd - 1);
  return [today, lastYear];
};

const initialState = {
  baseInitialDataStatus: 'idle',
  comparedInitialDataStatus: 'idle',
  tickersByCandle_status: 'idle',

  baseCoinInfo: {},
  baseCoinInfo_status: 'idle',

  comparedCoinPriceInfo: {},
  comparedCoinPriceInfo_status: 'idle',

  comparedCoinVolumeInfo: {},
  comparedCoinVolumeInfo_status: 'idle',

  coinData: {},
  coinData_status: 'idle',

  baseCoinCandlesStatus: 'idle',
  baseCoinCandles: [],

  comparedCoinCandlesStatus: 'idle',
  comparedCoinCandles: [],

  baseCoinVolumesStatus: 'idle',
  baseCoinVolumes: [],

  comparedCoinVolumesStatus: 'idle',
  comparedCoinVolumes: [],

  comparedCoinPriceSearch: '',
  comparedCoinPriceSearchResults: [],
  comparedCoinPriceSearchStatus: 'idle',

  comparedCoinVolumeSearch: '',
  comparedCoinPriceSearchResults: [],
  comparedCoinVolumeSearchStatus: 'idle',

  overall_news_results: [],
  overall_news_status: 'idle',

  dailyInfo_status: 'idle',
  dailyInfo_results: [],

  correlated: [],
  unCorrelated: [],
  correlation_status: 'idle',

  cointegrated: [],
  unCointegrated: [],
  cointegration_status: 'idle',

  causes: [],
  causedby: [],
  causality_status: 'idle',

  binanceMetricsStatus: 'idle',
  openInterest: [],
  oneDayChange: null,
  oneWeekChange: null,
  open_interest_status: 'idle',

  buySellRatio: [],
  buyVol: [],
  sellVol: [],
  buySellRatio_status: [],

  fundingRate: [],
  funding_rate_status: 'idle',
  fundingRateLastValue: null,
  fundingRateOneDayChange: null,
  fundingRateOneWeekChange: null,

  top_trader_status: 'idle',
  topTraderShortAccounts: [],
  topTraderLongAccounts: [],
  lastTopTraderRatio: 0,
  topTradersDates: [],

  accounts_status: 'idle',
  shortAccounts: [],
  longAccounts: [],
  lastAccountsRatio: 0,
  accountsDates: [],

  technicalAnalysis_status: 'idle',
  technicalAnalysis_OSCILLATOR_Signals: [],
  technicalAnalysis_MA_signals: [],
  technicalAnalysis_ma_NumSignals: [],
  technicalAnalysis_sum_NumSignals: [],
  technicalAnalysis_OSCI_NumSignals: [],

  news_status: 'idle',
  news: [],

  getNewsStatus: 'idle',
  getNewsError: null,
  getDataStatus: 'idle',
  getDataError: null,
  status: 'not_started',
  volume: [],
  date: getToDay(),

  getSentimentLineChartStatus: 'idle',
  getSentimentLineChartData: [],

  sentimentValueTypes: [
    { label: 'Title', value: 'title' },
    { label: 'Content', value: 'content' },
  ],
  sentimentValueType: { label: 'Title', value: 'title' },

  coin_news_results: [],
  coin_news_status: 'idle'
};

export const getNews = createAsyncThunk('news/getNews', async (coinName) => {
  const response = await axios.get(
    'https://www.alphavantage.co/query?function=NEWS_SENTIMENT&apikey=6KG2XIDYHPTCO965&topics=blockchain&sort=LATEST',
    {
      params: {
        CRYPTO: coinName,
        function: 'NEWS_SENTIMENT',
        apikey: '6KG2XIDYHPTCO965',
      },
    }
  );
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const getOverallNews = createAsyncThunk('news/getOverallNews', async () => {
  const response = await axiosInstance.get(
    "/sentiment/getOverallNews/", {
      params: {
        page: 1
      }
    }
  );
  return response.data;
});

export const getInitialBaseData = createAsyncThunk(
  'market/getInitialBaseData',
  async (symbol) => {
    const response = await axiosInstance.get('/market/searchcoin/', {
      params: {
        search: symbol,
      },
    });
    return response?.data[0];
  }
);

export const getInitialComparedData = createAsyncThunk(
  'market/getInitialComparedData',
  async (symbol) => {
    const response = await axiosInstance.get('/market/searchcoin/', {
      params: {
        search: symbol,
      },
    });
    return response?.data[0];
  }
);

export const searchCoinPrice = createAsyncThunk(
  'market/searchcoinPrice/',
  async (symbol) => {
    const response = await axiosInstance.get('/market/searchcoin/', {
      params: {
        search: symbol,
      },
    });
    return response.data;
  }
);

export const searchCoinVolume = createAsyncThunk(
  'market/searchcoinVolume/',
  async (symbol) => {
    const response = await axiosInstance.get('/market/searchcoin/', {
      params: {
        search: symbol,
      },
    });
    return response.data;
  }
);

export const getCoinCandles = createAsyncThunk(
  'market/getBaseCoinCandles',
  async ({ symbolId, type }) => {
    const response = await axiosInstance.post('/market/closePrice/', {
      symbolId: symbolId,
      interval: 86400,
      n_candles: 95,
    });
    return { ...response.data, type };
  }
);

export const getCoinVolumes = createAsyncThunk(
  'market/getBaseCoinVolumes',
  async ({ symbolId, type }) => {
    const response = await axiosInstance.post('/market/coinVolume/', {
      symbolId: symbolId,
      interval: 86400,
      n_candles: 95,
    });
    return { ...response.data, type };
  }
);

// -----------------------------------------------------
export const getCointegrations = createAsyncThunk(
  'data/getCointegrations',
  async (props) => {
    const response = await axiosInstance.post('/market/cointegrations/', props);
    return response?.data;
  }
);
export const getCausalities = createAsyncThunk(
  'data/getCausalities',
  async (props) => {
    const response = await axiosInstance.post('/market/causations/', props);
    return response?.data;
  }
);
export const getCorrelations = createAsyncThunk(
  'data/getCorrelations',
  async (props) => {
    const response = await axiosInstance.post('/market/correlations/', props);
    return response?.data;
  }
);

export const getBinanceMetrics = createAsyncThunk(
  'data/getBinanceMetrics',
  async (props) => {
    const response = await axiosInstance.get('/market/getBinanceMetrics/', {
      params: {
        symbolId: props,
      },
    });
    return response.data;
  }
);

// -----------------------------------------------------
export const technicalAnalysis = createAsyncThunk(
  'data/technicalAnalysis',
  async (props) => {
    const response = await axiosInstance.post(
      '/market/technicalAnalysis/',
      props
    );
    return response.data;
  }
);
// -----------------------------------------------------
export const coinData = createAsyncThunk('data/coinData', async (props) => {
  const response = await axiosInstance.get(`/market/coinData/${props}/`);
  return response.data;
});

export const getSentimentLineChart = createAsyncThunk(
  'data/getSentimentLineChart',
  async (props) => {
    const response = await axiosInstance.get(
      '/sentiment/getSentimentLineChart/',
      {
        params: props,
      }
    );
    return response.data;
  }
);

export const getCoinNews = createAsyncThunk(
  'data/getCoinNews',
  async (props) => {
    const response = await axiosInstance.get(
      '/sentiment/getCoinNews/',
      {
        params: props,
      }
    );
    return response.data;
  }
);

export const coinSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    setSentimentValueType: (state, action) => {
      state.sentimentValueType = action.payload;
    },
    setComparedCoinPriceSearch: (state, action) => {
      state.comparedCoinPriceSearch = action.payload;
    },
    setComparedCoinPriceInfo: (state, action) => {
      state.comparedCoinPriceInfo = action.payload;
    },
    setComparedCoinVolumeSearch: (state, action) => {
      state.comparedCoinVolumeSearch = action.payload;
    },
    setComparedCoinVolumeInfo: (state, action) => {
      state.comparedCoinVolumeInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // --------news
      .addCase(getNews.fulfilled, (state, action) => {
        state.news_status = 'fulfilled';
        const news = action.payload?.feed?.sort(function (a, b) {
          var timeA = a.time_published;
          var timeB = b.time_published;
          if (timeA > timeB) {
            return -1;
          }
          if (timeA < timeB) {
            return 1;
          }
          return 0;
        });
        if (news) {
          const filteredNews = news.filter((item) => {
            const tickerSentiments = item.ticker_sentiment;
            // Use Array.every() to check if all ticker sentiments contains "CRYPTO"
            return tickerSentiments.every((sentiment) =>
              sentiment.ticker.includes('CRYPTO')
            );
          });
          filteredNews.map((item) => {
            if (item.ticker_sentiment) {
              item.ticker_sentiment = item.ticker_sentiment.map(
                (tickerItem) => {
                  if (tickerItem.ticker.startsWith('CRYPTO:')) {
                    tickerItem.ticker = tickerItem.ticker.replace(
                      'CRYPTO:',
                      ''
                    );
                  }
                  return tickerItem;
                }
              );
            }
            return item;
          });
          state.news_status = 'fulfilled';
          state.news = filteredNews;
        }
      })
      .addCase(getNews.pending, (state) => {
        state.news_status = 'pending';
      })
      .addCase(getOverallNews.pending, (state) => {
        state.overall_news_status = 'pending';
      })
      .addCase(getOverallNews.rejected, (state) => {
        state.overall_news_status = 'rejected';
      })
      .addCase(getOverallNews.fulfilled, (state, action) => {
        const news = action.payload.results;
        const filteredNews = news.filter((item) => {
          const tickerSentiments = item.tickers;
          // Use Array.every() to check if all ticker sentiments contains "CRYPTO"
          return tickerSentiments.every((sentiment) =>
            sentiment.ticker.includes('CRYPTO')
          );
        });
        filteredNews.map(item => {
          if (item.tickers) {
              item.tickers = item.tickers.map(tickerItem => {
                  if (tickerItem.ticker.startsWith('CRYPTO:')) {
                      tickerItem.ticker = tickerItem.ticker.replace('CRYPTO:', '');
                  }
                  return tickerItem;
              });
          }
          return item;
      });
        state.overall_news_results = filteredNews;
        state.overall_news_status = 'fulfilled';
      })
      //-------initial request
      .addCase(getInitialBaseData.pending, (state) => {
        state.baseInitialDataStatus = 'pending';
      })
      .addCase(getInitialBaseData.fulfilled, (state, action) => {
        state.baseCoinInfo = action.payload;
        state.baseInitialDataStatus = 'fulfilled';
      })
      .addCase(getInitialBaseData.rejected, (state) => {
        state.baseInitialDataStatus = 'rejected';
      })
      .addCase(getInitialComparedData.pending, (state) => {
        state.comparedInitialDataStatus = 'pending';
      })
      .addCase(getInitialComparedData.fulfilled, (state, action) => {
        state.comparedCoinPriceInfo = action.payload;
        state.comparedCoinVolumeInfo = action.payload;
        state.comparedInitialDataStatus = 'fulfilled';
      })
      .addCase(getInitialComparedData.rejected, (state) => {
        state.comparedInitialDataStatus = 'rejected';
      })
      .addCase(getCoinNews.pending, (state) => {
        state.get_coin_news_status = 'pending';
      })
      .addCase(getCoinNews.fulfilled, (state, action) => {
        state.coin_news_results = action.payload;
        state.coin_news_status = 'fulfilled';
      })
      .addCase(getCoinNews.rejected, (state) => {
        state.get_coin_news_status = 'rejected';
      })
      // -------------------------------------------------
      .addCase(getCorrelations.fulfilled, (state, action) => {
        state.correlated = action.payload['correlated to'];
        state.unCorrelated = action.payload['uncorrelated to'];
        state.correlation_status = 'fulfilled';
      })
      .addCase(getCorrelations.pending, (state) => {
        state.correlation_status = 'pending';
      })
      .addCase(getCorrelations.rejected, (state) => {
        state.correlation_status = 'rejected';
      })

      .addCase(getCointegrations.fulfilled, (state, action) => {
        state.cointegrated = action.payload['cointegrated to'];
        state.unCointegrated = action.payload['least cointegreted to'];
        state.cointegration_status = 'fulfilled';
      })
      .addCase(getCointegrations.pending, (state) => {
        state.cointegration_status = 'pending';
      })
      .addCase(getCointegrations.rejected, (state) => {
        state.cointegration_status = 'rejected';
      })

      .addCase(getCausalities.fulfilled, (state, action) => {
        state.causes = action.payload.causes;
        state.causedby = action.payload.caused_by;
        state.causality_status = 'fulfilled';
      })
      .addCase(getCausalities.pending, (state) => {
        state.causality_status = 'pending';
      })
      .addCase(getCausalities.rejected, (state) => {
        state.causality_status = 'rejected';
      })
      .addCase(technicalAnalysis.fulfilled, (state, action) => {
        state.technicalAnalysis_MA_signals = action.payload['MA_signals'];
        state.technicalAnalysis_OSCILLATOR_Signals =
          action.payload['oscillator_signals'];
        state.technicalAnalysis_OSCI_NumSignals =
          action.payload['oscillator_signal_counts'];
        state.technicalAnalysis_ma_NumSignals =
          action.payload['MA_signal_counts'];
        state.technicalAnalysis_sum_NumSignals =
          action.payload['summary_signal_counts'];

        state.technicalAnalysis_status = 'fulfilled';
      })
      .addCase(technicalAnalysis.pending, (state) => {
        state.technicalAnalysis_status = 'pending';
      })
      .addCase(technicalAnalysis.rejected, (state) => {
        state.technicalAnalysis_status = 'rejected';
      })
      // ------------------------------------------------------
      .addCase(coinData.fulfilled, (state, action) => {
        state.coinData = action.payload;
        state.coinData_status = 'fulfilled';
      })
      .addCase(coinData.pending, (state) => {
        state.coinData_status = 'pending';
      })
      .addCase(coinData.rejected, (state) => {
        state.coinData_status = 'rejected';
      })
      // ------------------------------------------------------
      .addCase(getBinanceMetrics.pending, (state) => {
        state.binanceMetricsStatus = 'pending';
      })
      // TODO: break this logic down to separate funcitons
      .addCase(getBinanceMetrics.fulfilled, (state, action) => {
        const open_interests = action.payload?.open_interest;
        const funding_rates = action.payload?.funding_rate;
        const accounts = action.payload.account;
        const top_traders = action.payload?.top_trader_accounts;

        if (open_interests) {
          var openInterest = [];
          var dataLength = open_interests.length;
          for (var i = 0; i < dataLength; i += 1) {
            openInterest.push([
              open_interests[i].timestamp,
              parseFloat(open_interests[i].sumOpenInterestValue) // the date
            ]
            );
          }
          state.openInterest = openInterest;
          state.oneDayChange = (
            ((openInterest.slice(-1)[0][1] - openInterest.slice(-2)[0][1]) /
              openInterest.slice(-2)[0][1]) *
            100
          ).toFixed(2);
          state.oneWeekChange = (
            ((openInterest.slice(-1)[0][1] - openInterest.slice(-7)[0][1]) /
              openInterest.slice(-7)[0][1]) *
            100
          ).toFixed(2);
          state.open_interest_status = 'fulfilled';
        }

        if (funding_rates) {
          var fundingRate = [];
          (dataLength = funding_rates.length), (i = 0);

          for (i; i < dataLength; i += 1) {
            fundingRate.push([
              funding_rates[i].fundingTime, // the date
              parseFloat(funding_rates[i].fundingRate), // fundingRate
            ]);
          }

          state.fundingRateOneDayChange = (
            ((funding_rates.slice(-1)[0].fundingRate -
              funding_rates.slice(-2)[0].fundingRate) /
              funding_rates.slice(-1)[0].fundingRate) *
            100
          ).toFixed(2);
          state.fundingRateOneWeekChange = (
            ((funding_rates.slice(-1)[0].fundingRate -
              funding_rates.slice(-7)[0].fundingRate) /
              funding_rates.slice(-1)[0].fundingRate) *
            100
          ).toFixed(2);
          state.fundingRateLastValue = funding_rates.slice(-1)[0].fundingRate;
          state.fundingRate = fundingRate;
          state.funding_rate_status = 'fulfilled';
        }

        if (top_traders) {
          state.topTraderShortAccounts = top_traders.map((x) => {
            return [x.timestamp, parseFloat(x.shortAccount)]
          }
          );
          state.topTraderLongAccounts = top_traders.map((x) => {
            return [x.timestamp, parseFloat(x.longAccount)]
          }
          );
          state.topTradersDates = accounts.map((x) => x.timestamp);
          state.lastTopTraderRatio = top_traders[top_traders.length - 1].longShortRatio
          state.top_trader_status = 'fulfilled';
        }

        if (accounts) {
          state.shortAccounts = accounts.map((x) => {
            return [x.timestamp, parseFloat(x.shortAccount)]
          }
          );
          state.longAccounts = accounts.map((x) => {
            return [x.timestamp, parseFloat(x.longAccount)]
          }
          );
          state.accountsDates = accounts.map((x) => x.timestamp);
          state.lastAccountsRatio = accounts[accounts.length - 1].longShortRatio
          state.accounts_status = 'fulfilled';
        }
        state.binanceMetricsStatus = 'fulfilled';
      })
      .addCase(getBinanceMetrics.rejected, (state) => {
        state.binanceMetricsStatus = 'rejected';
      })
      .addCase(getCoinCandles.pending, (state) => {
        state.baseCoinCandlesStatus = 'pending';
        state.comparedCoinCandlesStatus = 'pending';
      })
      .addCase(getCoinCandles.fulfilled, (state, action) => {
        const raw_candles = action.payload['close prices'];
        const candles = raw_candles.map((x) => {
          return [x.timestamp * 1000, x.close];
        });
        if (action.payload.type === 'base') {
          state.baseCoinCandles = candles;
        } else {
          state.comparedCoinCandles = candles;
          state.comparedCoinPriceSearch = '';
          state.comparedCoinPriceSearchResults = [];
        }
        state.baseCoinCandlesStatus = 'fulfilled';
        state.comparedCoinCandlesStatus = 'fulfilled';
      })
      .addCase(getCoinCandles.rejected, (state) => {
        state.baseCoinCandlesStatus = 'rejected';
        state.comparedCoinCandlesStatus = 'rejected';
      })
      .addCase(getCoinVolumes.pending, (state) => {
        state.baseCoinVolumesStatus = 'pending';
        state.comparedCoinVolumesStatus = 'pending';
      })
      .addCase(getCoinVolumes.fulfilled, (state, action) => {
        const raw_volumes = action.payload['volumes'];
        const volumes = raw_volumes.map((x) => {
          return [x.timestamp * 1000, x.volume];
        });
        if (action.payload.type === 'base') {
          state.baseCoinVolumes = volumes;
        } else {
          state.comparedCoinVolumes = volumes;
          state.comparedCoinVolumeSearch = '';
          state.comparedCoinVolumeSearchResults = [];
        }
        state.baseCoinVolumesStatus = 'fulfilled';
        state.comparedCoinVolumesStatus = 'fulfilled';
      })
      .addCase(getCoinVolumes.rejected, (state) => {
        state.baseCoinVolumesStatus = 'rejected';
        state.comparedCoinVolumesStatus = 'rejected';
      })
      .addCase(searchCoinPrice.pending, (state) => {
        state.comparedCoinPriceSearchStatus = 'pending';
      })
      .addCase(searchCoinPrice.fulfilled, (state, action) => {
        state.comparedCoinPriceSearchResults = action.payload.slice(0, 5);
        state.comparedCoinPriceSearchStatus = 'fulfilled';
      })
      .addCase(searchCoinPrice.rejected, (state) => {
        state.comparedCoinPriceSearchStatus = 'rejected';
      })
      .addCase(searchCoinVolume.pending, (state) => {
        state.comparedCoinVolumeSearchStatus = 'pending';
      })
      .addCase(searchCoinVolume.fulfilled, (state, action) => {
        state.comparedCoinVolumeSearchResults = action.payload.slice(0, 5);
        state.comparedCoinVolumeSearchStatus = 'fulfilled';
      })
      .addCase(searchCoinVolume.rejected, (state) => {
        state.comparedCoinVolumeSearchStatus = 'rejected';
      })
      .addCase(getSentimentLineChart.pending, (state) => {
        state.getSentimentLineChartStatus = 'pending';
      })
      .addCase(getSentimentLineChart.fulfilled, (state, action) => {
        state.getSentimentLineChartData = action.payload;
        state.getSentimentLineChartStatus = 'fulfilled';
      })
      .addCase(getSentimentLineChart.rejected, (state) => {
        state.getSentimentLineChartStatus = 'rejected';
      });
  },
});

export const {
  setComparedCoinPriceSearch,
  setComparedCoinVolumeSearch,
  setComparedCoinPriceInfo,
  setComparedCoinVolumeInfo,
  setSentimentValueType,
} = coinSlice.actions;

export default coinSlice.reducer;
