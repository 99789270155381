import React, { forwardRef } from 'react';
import classNames from 'classnames';
import './style.scss';

function Input(
  {
    label,
    error,
    type = 'text',
    className,
    containerClassName,
    onChange = () => {},
    placeholder,
    readOnly,
    disabled,
    value,
    rightIcon,
    ...props
  },
  ref
) {
  const isEditable = !readOnly && !disabled;
  const input = (
    <div className="relative">
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        {!!rightIcon && rightIcon}
      </div>
      <input
        ref={ref}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        value={value}
        onChange={onChange}
        className={classNames(
          'input2',
          { 'input--disabled2': !isEditable },
          { 'input--error2': error },
          className
        )}
        placeholder={placeholder}
        {...props}
      />
    </div>
    // <input
    //   ref={ref}
    //   readOnly={readOnly}
    //   disabled={disabled}
    //   type={type}
    //   value={value}
    //   onChange={onChange}
    //   className={classNames(
    //     "input",
    //     { "input--disabled": !isEditable },
    //     { "input--error": error },
    //     className
    //   )}
    //   placeholder={placeholder}
    //   {...props}
    // />
  );

  return (
    <div className={classNames('input__container2', containerClassName)}>
      {label && (
        <label>
          <span className="input__label2 dark:text-white">{label}</span>
          {input}
        </label>
      )}
      {!label && input}
      {error && <div className="input__text--error2">{error}</div>}
    </div>
  );
}

export default forwardRef(Input);
