import { React, useEffect, useState, useRef } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
// import PerformanceCards from '../../components/PerformanceCards';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic';
import CalendarInput from '../../components/CalendarInput';
import BottomInput from '../../components/BottomInput';
import './style.scss';
import moment from 'moment';
import PerformanceCards from '../../components/PerformanceSummary';
import { Table } from '../../components/Table';
import PerformanceChart from './subs/chart';
import { areAllVariablesPresent } from '../../utils/validators';
import {
  combination_for_timeframe_columns,
  combination_for_symbol_columns,
  ts_for_combination_columns,
} from '../../Constants/strategyTable';
import {
  select_coins,
  select_strategies,
  select_feature_sets,
  select_learning_models,
  select_labeling_methods,
  select_timeframes,
  select_margin_methods,
  set_start_time,
  set_end_time,
  get_coins,
  get_strategies,
  getFeatureTransforms,
  getLabelTransforms,
  getModels,
  set_leverage,
  get_measures,
  get_positions,
  get_orders,
  get_timeframe_combinations,
  get_symbol_combinations,
  get_combo_combinations,
} from './slice';

import { useSelector } from 'react-redux';
import { history } from '../../utils/history';
import Title from '../../components/Title';
import PositionSub from './subs/position';
import OrderSub from './subs/order';

function WizardPage() {
  const { dispatch } = history;
  const topRef = useRef(null);
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [timeframePage, setTimeframePage] = useState(1);
  const [symbolPage, setSymbolPage] = useState(1);
  const [comboPage, setComboPage] = useState(1);

  // slice states
  const theme = useSelector((state) => state.overall.theme);
  const state = useSelector((state) => state.wizard);
  const selected_coins = state.selected_coins;
  const available_coins = state.available_coins;
  const available_feature_sets = state.available_feature_sets;
  const selected_feature_sets = state.selected_feature_sets;
  const available_labeling_methods = state.available_labeling_methods;
  const selected_labeling_methods = state.selected_labeling_methods;
  const available_learning_models = state.available_learning_models;
  const selected_learning_models = state.selected_learning_models;
  const available_strategies = state.available_strategies;
  const selected_strategies = state.selected_strategies;
  const selected_timeframes = state.selected_timeframes;
  const available_timeframes = state.available_timeframes;
  const available_margins = state.available_margins;
  const selected_margins = state.selected_margins;
  const start_time = state.start_time;
  const end_time = state.end_time;
  const leverage = state.leverage;
  const all_measures = state.all_measures;
  const get_measures_status = state.get_measures_status;
  const positions = state.positions;
  const orders = state.orders;
  const timeframe_combinations = state.timeframe_combinations;
  const get_timeframe_combinations_status =
    state.get_timeframe_combinations_status;
  const symbol_combinations = state.symbol_combinations;
  const get_symbol_combinations_status = state.get_symbol_combinations_status;
  const combo_combinations = state.combo_combinations;
  const get_combo_combinations_status = state.get_combo_combinations_status;
  const summaries = state.summaries;

  // internal states
  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    dispatch(get_strategies());
    dispatch(get_coins());
    dispatch(getFeatureTransforms());
    dispatch(getLabelTransforms());
    dispatch(getModels());
  }, []);

  // measures api variables
  const measure_variables = [
    selected_coins,
    selected_timeframes,
    selected_feature_sets,
    selected_labeling_methods,
    selected_learning_models,
    selected_strategies,
    selected_margins,
    start_time,
    end_time,
    leverage,
  ];
  useEffect(() => {
    if (areAllVariablesPresent(measure_variables)) {
      const payload = {
        symbol_id: selected_coins.value,
        timeframe: selected_timeframes.value,
        feature_set_id: selected_feature_sets.value,
        labeling_method_id: selected_labeling_methods.value,
        learning_model_id: selected_learning_models.value,
        strategy_id: selected_strategies.value,
        margin_method: selected_margins.value,
        start_date: new Date(start_time * 1000).toISOString(),
        end_date: new Date(end_time * 1000).toISOString(),
        leverage,
        initial_capital: 1000,
        fee_percentage: 0.1,
      };
      dispatch(get_measures(payload));
      dispatch(get_positions(payload));
      dispatch(get_orders(payload));
    }
  }, measure_variables);

  useEffect(() => {
    dispatch(
      get_timeframe_combinations({
        timeframe: selected_timeframes.value,
        page: timeframePage,
      })
    );
  }, [selected_timeframes, timeframePage]);

  useEffect(() => {
    if (areAllVariablesPresent([selected_coins])) {
      dispatch(
        get_symbol_combinations({
          symbol_id: selected_coins.value,
          page: symbolPage,
        })
      );
    }
  }, [selected_coins, symbolPage]);

  const combo_variables = [
    selected_labeling_methods,
    selected_feature_sets,
    selected_learning_models,
    selected_strategies,
    comboPage,
  ];
  useEffect(() => {
    if (areAllVariablesPresent(combo_variables)) {
      dispatch(
        get_combo_combinations({
          labeling_method_id: selected_labeling_methods.value,
          feature_set_id: selected_feature_sets.value,
          learning_model_id: selected_learning_models.value,
          strategy_id: selected_strategies.value,
          page: comboPage,
        })
      );
    }
  }, combo_variables);

  const timeframe_recommended_clicked = (row) => {
    const symbol = available_coins.find((x)=> x.label == `${row[0]?.symbol}-USDT`);
    const lt = available_labeling_methods.find((x) => x.label == row[1]);
    const ft = available_feature_sets.find((x)=> x.label == row[2]);
    const dlm = available_learning_models.find((x)=> x.label == row[3]);
    const strategy = available_strategies.find((x)=> x.label == row[4]);
    const start_timestamp = row[8];
    const end_timestamp = row[9];
    if (areAllVariablesPresent([symbol, lt, ft, dlm, strategy])) {
      dispatch(select_coins(symbol));
      dispatch(select_labeling_methods(lt));
      dispatch(select_feature_sets(ft));
      dispatch(select_learning_models(dlm));
      dispatch(select_strategies(strategy));
      dispatch(set_start_time(start_timestamp));
      dispatch(set_end_time(end_timestamp));
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

    const symbol_recommended_clicked = (row) => {
    const timeframe = available_timeframes.find((x)=> x.label == row[0]);
    const lt = available_labeling_methods.find((x) => x.label == row[1]);
    const ft = available_feature_sets.find((x)=> x.label == row[2]);
    const dlm = available_learning_models.find((x)=> x.label == row[3]);
    const strategy = available_strategies.find((x)=> x.label == row[4]);
    const start_timestamp = row[8];
    const end_timestamp = row[9];
    if (areAllVariablesPresent([timeframe, lt, ft, dlm, strategy])) {
      dispatch(select_timeframes(timeframe));
      dispatch(select_labeling_methods(lt));
      dispatch(select_feature_sets(ft));
      dispatch(select_learning_models(dlm));
      dispatch(select_strategies(strategy));
      dispatch(set_start_time(start_timestamp));
      dispatch(set_end_time(end_timestamp));
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const combo_recommended_clicked = (row) => {
    const symbol = available_coins.find((x)=> x.label == `${row[0]?.symbol}-USDT`);
    const timeframe = available_timeframes.find((x)=> x.label == row[1]);
    const start_timestamp = row[5];
    const end_timestamp = row[6]
    if (areAllVariablesPresent([timeframe, symbol, start_timestamp, end_timestamp])) {
      dispatch(select_timeframes(timeframe));
      dispatch(select_coins(symbol));
      dispatch(set_start_time(start_timestamp))
      dispatch(set_end_time(end_timestamp))
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // TODO: add effect on timeframe change, and set the start time dynamically based on that

  return (
    <DashboardLayout>
      <div className="flex w-full" ref={topRef}>
        <Title
          title="Backtest Wizard"
          subtitle="Uncover Strategy Performance with Detailed Backtest Analysis"
          isPageTitle
        />
      </div>
      <div className="flex-col py-3 my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
        <Title
          title="Backtest Configuration Setup"
          subtitle="Customize Your Backtest Parameters to Suit Your Preferences"
        />
        <div className="flex justify-center gap-16 my-3">
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Symbol"
            selectedOptions={selected_coins}
            options={available_coins}
            className="select-analys-type"
            onChange={(items) => {
              dispatch(select_coins(items ? items : ''));
            }}
          />
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Time Frame"
            selectedOptions={selected_timeframes}
            options={available_timeframes}
            onChange={(items) =>
              dispatch(select_timeframes(items ? items : ''))
            }
          />
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Margin Method"
            selectedOptions={selected_margins}
            options={available_margins}
            onChange={(items) =>
              dispatch(
                select_margin_methods(items ? items : available_margins[0])
              )
            }
          />
          <CalendarInput
            label="Start Date"
            placeholder="Start Date"
            show={showStartDatePicker}
            setShow={(value) => {
              setShowStartDatePicker(value);
            }}
            value={new Date(start_time * 1000)}
            onFocus={(date) => {
              dispatch(
                set_start_time(Math.floor(new Date(date).getTime() / 1000))
              );
              setShowStartDatePicker(false);
            }}
            onChangeFunc={(dateTime) =>
              dispatch(set_start_time(Math.floor(dateTime / 1000)))
            }
            minDate={'2021/01/01'}
            maxDate={moment(new Date()).format('YYYY/MM/DD')}
            dark={theme}
          />
          <CalendarInput
            label="End Date"
            placeholder="End Date"
            show={showEndDatePicker}
            setShow={(value) => {
              setShowEndDatePicker(value);
            }}
            value={new Date(end_time * 1000)}
            onFocus={(date) => {
              dispatch(
                set_end_time(Math.floor(new Date(date).getTime() / 1000))
              );
              setShowEndDatePicker(false);
            }}
            onChangeFunc={(dateTime) =>
              dispatch(set_end_time(Math.floor(dateTime / 1000)))
            }
            minDate={'2021/01/01'}
            maxDate={moment(new Date()).format('YYYY/MM/DD')}
            dark={theme}
          />
        </div>
        <div className="flex justify-center gap-16">
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Labeling Method"
            selectedOptions={selected_labeling_methods}
            options={available_labeling_methods}
            onChange={(items) =>
              dispatch(select_labeling_methods(items ? items : ''))
            }
          />
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Feature Set"
            selectedOptions={selected_feature_sets}
            options={available_feature_sets}
            onChange={(items) =>
              dispatch(select_feature_sets(items ? items : ''))
            }
          />
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Learning Model"
            selectedOptions={selected_learning_models}
            options={available_learning_models}
            onChange={(items) =>
              dispatch(select_learning_models(items ? items : ''))
            }
          />
          <ButtonSelectDynamic
            dark={theme}
            isRequired
            label="Strategy"
            selectedOptions={selected_strategies}
            options={available_strategies}
            onChange={(items) =>
              dispatch(select_strategies(items ? items : ''))
            }
          />
          <BottomInput
            label={'Leverage'}
            type="number"
            value={leverage}
            onChange={(event) =>
              dispatch(set_leverage(parseInt(event.target.value)))
            }
          />
        </div>
      </div>
      <div className="flex-col py-3 p-5 mb-2 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
        <Title
          title="Performance Metrics Summary "
          subtitle="Analyzing Backtest Results: Comprehensive Performance Measures"
        />
        <PerformanceCards
          metrics={summaries}
          loading={get_measures_status !== 'fulfilled'}
        />
      </div>
      {
        <div className="flex flex-col justify-center">
          <div className="SatrtegyPage-Performance-Chart bg-light-back_secondary dark:bg-dark-back_secondary">
            <div className="p-4 flex justify-between items-center">
              <Title
                title="Performance Metrics Visualization"
                subtitle="Exploring Backtest Metrics: Charts and Tables for Detailed Analysis"
                className=""
              />
              <div className="grid grid-cols-3 divide-x">
                <button
                  style={{
                    borderBottom: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                  }}
                  className={`p-3 ${
                    selectedTab === 1 ? 'border-solid' : 'dark:bg-dark-back bg-light-back'
                  }`}
                  onClick={() => setSelectedTab(1)}
                >
                  Chart
                </button>
                <button
                  style={{
                    borderBottom: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                  }}
                  className={`p-3 ${
                    selectedTab === 2 ? 'border-solid' : 'dark:bg-dark-back bg-light-back'
                  }`}
                  onClick={() => setSelectedTab(2)}
                >
                  Positions
                </button>
                <button
                  style={{
                    borderBottom: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                  }}
                  className={`p-3 ${
                    selectedTab === 3 ? 'border-solid' : 'dark:bg-dark-back bg-light-back'
                  }`}
                  onClick={() => setSelectedTab(3)}
                >
                  Orders
                </button>
              </div>
            </div>
            {selectedTab === 1 ? (
              <PerformanceChart
                Equities={all_measures?.equity_series}
                DrawDowns={all_measures?.draw_down_series}
                Holds={all_measures?.buy_and_hold_series}
                Theme={theme}
                Measures={all_measures}
                loading={get_measures_status !== 'fulfilled'}
              />
            ) : selectedTab === 2 ? (
              <PositionSub Positions={positions} Loading={get_measures_status !== 'fulfilled'} />
            ) : (
              <OrderSub Orders={orders} Loading={get_measures_status !== 'fulfilled'} />
            )}
          </div>
          <div className="grid grid-cols-9 gap-5 my-4">
            <div className="col-span-5">
              <Table
                title={'Time Frame Recommendation Overview'}
                subtitle={`Strategic Recommendations: Ideal Signals for Enhanced Decision-Making in ${selected_timeframes.label} Time Frame`}
                loading={
                  get_timeframe_combinations_status === 'fulfilled'
                    ? false
                    : true
                }
                ActiveLookBack
                setActiveLookBack
                current_page={timeframePage}
                set_current_page={(page) => {
                  setTimeframePage(page);
                }}
                page_size={5}
                total_items={timeframe_combinations.total_items}
                columns={combination_for_timeframe_columns}
                rows={timeframe_combinations.results}
                onRowClick={(x) => timeframe_recommended_clicked(x)}
              />
            </div>
            <div className="col-span-4">
              <Table
                title={'Suggested Symbols and Time Frames'}
                subtitle={
                  'Best Symbols and Time Frames for Current Configuration'
                }
                loading={get_combo_combinations_status !== 'fulfilled'}
                ActiveLookBack
                setActiveLookBack
                current_page={comboPage}
                set_current_page={(page) => {
                  setComboPage(page);
                }}
                page_size={5}
                total_items={combo_combinations.total_items}
                columns={ts_for_combination_columns}
                rows={combo_combinations.results}
                onRowClick={(x) => combo_recommended_clicked(x)}
              />
            </div>
            <div className="col-span-full">
              <Table
                title={'Symbol Recommendations Overview'}
                subtitle={`Strategic Guidance: Top Signals for ${selected_coins.label} Decision-Making`}
                loading={get_symbol_combinations_status !== 'fulfilled'}
                ActiveLookBack
                setActiveLookBack
                current_page={symbolPage}
                set_current_page={(page) => {
                  setSymbolPage(page);
                }}
                page_size={5}
                total_items={symbol_combinations.total_items}
                columns={combination_for_symbol_columns}
                rows={symbol_combinations.results}
                onRowClick={(x) => symbol_recommended_clicked(x)}
              />
            </div>
          </div>
        </div>
      }
    </DashboardLayout>
  );
}
export default WizardPage;
