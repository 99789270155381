import { formatPrice } from '../utils/formatPrice';
import {} from '../utils/formatTime'
import { history } from '../utils/history';

export function getTreeMapOptions(data, dark, type = 'market') {
  const { navigate } = history;
  return {
    chart: {
      backgroundColor: dark ? '#202020' : '#E5E5E5',
    },

    title: {
      text: '',
    },

    tooltip: {
      formatter: function () {
        if (type == 'market') {
          const point = data.find((x)=>{
            return x.name == this.point.name;
          }).price
          var str =
              this.point.name +
              ': ' +
              this.point.close +
              '<br>Price Change: ' +
              this.point.percent_change +
              '%',
            node = this.point.node;

          if (node) {
            node.children.forEach(function (child) {
              str += '<br />' + child.name + ' ' + child.val;
            });
          }

          return str;
        } else if (type == 'sentiment') {
          const tooltip = `Positive Average Sentiment: ${this.point.avg_positive}<br />Negatieve Average Sentiment: ${this.point.avg_negative}
                          <br />Neutral Average Sentiment: ${this.point.avg_neutral}`
          return tooltip
        }
      },
    },

    series: [
      {
        name: 'Market Cap',
        type: 'treemap',
        allowDrillToNode: true,
        layoutAlgorithm: 'squarified',
        animationLimit: 1000,
        borderColor: '#151515',
        levels: [
          {
            level: 1,
            dataLabels: {
              formatter: function () {
                var values = data.map((x) => x.value);
                // Define the minimum and maximum font sizes and the corresponding value range
                var minFontSize = 8;
                var maxFontSize = 25;
                var minValue = Math.min(...values); // Minimum value in your data
                var maxValue = Math.max(...values); // Maximum value in your data (adjust as needed)
                // Calculate the scaled font size based on the value
                var fontSize =
                  minFontSize +
                  ((this.point.value - minValue) / (maxValue - minValue)) *
                    (maxFontSize - minFontSize);

                // Ensure the font size is within the desired range
                var name = `<span style="font-size: ${fontSize}px">${this.point.name}</span>`;
                if (type == 'market') {
                  var percentChange = `<span style="font-size: ${
                    fontSize - 3
                  }px">${`${this.point.percent_change}%`}</span>`;
  
                  return `${name}<br>${percentChange}`;
                } else {
                  var percentChange = `<span style="font-size: ${
                    fontSize - 3
                  }px">${this.point.percent_change}</span>`;
  
                  return `${name}<br>${percentChange}`;
                }
              },
            },
          },
        ],
        data,
        point: {
          events: {
            click: function (e) {
              navigate(`/coin/${e?.point?.options?.name}`);
            },
          },
        },
      },
    ],
    breadcrumbs: {
      useHTML: true,
    },
  };
}

export const BubbleChartOptions = (data, dark, type="market") => {
  const { navigate } = history;
  return {
    chart: {
      type: 'packedbubble',
      backgroundColor: dark ? '#202020' : '#E5E5E5',
    },
    title: {
      text: '',
    },
    plotOptions: {
      packedbubble: {
        minSize: '1%',
        maxSize: '600%',
        dataLabels: {
          enabled: true,
          formatter: function () {
            var values = data.map((x) => x.value);
            // Define the minimum and maximum font sizes and the corresponding value range
            var minFontSize = 8;
            var maxFontSize = 25;
            var minValue = Math.min(...values); // Minimum value in your data
            var maxValue = Math.max(...values); // Maximum value in your data (adjust as needed)
            // Calculate the scaled font size based on the value
            var fontSize =
              minFontSize +
              ((this.point.value - minValue) / (maxValue - minValue)) *
                (maxFontSize - minFontSize);
            var name = `<span style="font-size: ${fontSize}px">${this.point.name}</span>`;
            var percentChange = `<span style="font-size: ${fontSize - 3}px">${
              this.point.percent_change
            }%</span>`;

            return `${name}<br>${percentChange}`;
          },
          style: {
            // Set the default font size for data labels
            fontSize: '10px',
            fillOpacity: 1, // Set the fill opacity to 1 for solid colors
          },
        },
        layoutAlgorithm: {
          splitSeries: false,
          gravitationalConstant: 0.005,
        },
      },
    },
    tooltip: {
      formatter: function () {
        if (type == 'market') {
          tooltip = `<b>${this.point.name.replace('USDT', '')}:</b> ${formatPrice(
            this.point.last_price
          )} USDT<br>Price Change: ${this.point.percent_change}%`
          return tooltip;
        }
        else if (type == 'sentiment') {
          const tooltip = `Positive Average Sentiment: ${this.point.avg_positive}%<br />Negatieve Average Sentiment: ${this.point.avg_negative}%
                          <br />Neutral Average Sentiment: ${this.point.avg_neutral}%`
          return tooltip
        }
      },
    },
    series: [
      {
        data: data,
        showInLegend: false,
        point: {
          events: {
            click: function (e) {
              navigate(`/coin/${e?.point?.options?.name}`);
            },
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };
};
