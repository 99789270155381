import React from 'react';
import './style.scss';

const ProgressBar = ({ percentage }) => {
  const fillStyles = {
    width: `${percentage}%`,
  };

  return (
    <div className="progress-bar bg-white dark:bg-dark-back">
      <div className="progress-bar__label">{percentage}%</div>
      <div
        className={
          percentage === 100
            ? 'progress-bar__fill complete'
            : 'progress-bar__fill'
        }
        style={fillStyles}
      />
    </div>
  );
};

export default ProgressBar;
