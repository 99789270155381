import { generateAreaChart } from "../utils/d3_maker";

export const RecentSignalsHeaders = [
  { name: 'Time', isSortable: true },
  { name: 'Symbol', isSortable: true },
  { name: 'Time Frame', isSortable: true },
  { name: 'Strategy', isSortable: true },
  { name: 'Type', isSortable: true },
  { name: 'Feature Set', isSortable: true },
  { name: 'Labeling Method', isSortable: true },
  { name: 'Learning Model', isSortable: true },
  { name: 'Entry Price', isSortable: true },
  { name: 'net_profit', isSortable: true },
  // { name: 'profit_factor', isSortable: true },
  // { name: 'drawdown', isSortable: true },
  { name: 'price chart', isSortable: true },
];

export const sample_data = [
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
  [
    '10:36:48',
    {symbol: 'BTC', icon: 'https://api.finbright.org/static/icon/btc.svg'},
    14400,
    'Buy',
    1,
    'FS1',
    'LM1',
    'LM1',
    50000,
    generateAreaChart([
      48000, 49000, 49500, 48500, 50000, 50500, 51500, 52000, 51000, 52500,
      49500, 48500, 50000, 49500, 48500, 50000, 49500, 48500, 50000,
    ]),
  ],
];
