/** @type {import('tailwindcss').Config} */

export default {
  darkMode: 'class',
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    screens: {
      sm: '480px',
      md: '768px',
      lg: '976px',
      xl: '1440px',
    },
    // plugins: [require('flowbite/plugin')],
    fontFamily: {
      sans: ['Graphik', 'sans-serif'],
      serif: ['Merriweather', 'serif'],
    },
    extend: {
      // tailwind default colors https://tailwindcss.com/docs/customizing-colors
      colors: {
        dark: '#040B10',
        secondary: '#F9F9F1',
        dark_secondary: '#202020',
        dark_gray: '#151515',
      },
      spacing: {
        128: '32rem',
        144: '36rem',
        170: '50rem',
        200: '70rem',
      },
      borderRadius: {
        '4xl': '2rem',
      },
    },
  },
  ...(process.env.NODE_ENV === 'production' ? { cssnano: {} } : {}),
};
