import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { history } from '../../utils/history';
import ReactLoading from 'react-loading';
import axiosInstance from '../../api/config';
import LoginInput from '../../components/LoginInput';
import FinbrightIcon from '../../components/SideBar/components/FinBrightIcon';
import LocalStorageService from '../../api/LocalStoragrService';
import './style.scss';
import { Helmet } from 'react-helmet';

const localStorageService = LocalStorageService.getService();

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const token = localStorageService.getAccessToken();
    const refresh = localStorageService.getRefreshToken();
    if (token && refresh) {
      history.navigate('/market-overview');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.warn('Fill the  empty fields first please.');
      return;
    }
    if (hasError) {
      toast.error(hasError);
    }
    setIsSubmitting(true);
    axiosInstance
      .post('/auth/jwt/create', {
        email,
        password,
      })
      .then((res) => res.data)
      .then((data) => {
        toast.success('Logged in successfully.');
        localStorageService.setToken({
          access: data.access,
          refresh: data.refresh,
        });
        history.navigate('/market-overview');
      })
      .catch((err) => {
        toast.error('Something went wrong.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="login">
      <>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div className='flex justify-center align-center items-center'>
        <FinbrightIcon width={60} height={75}/>
        <h1 className="login__h1">FinBright</h1>
        </div>
        <form className="login__form" onSubmit={handleSubmit}>
          <div className="login__form-title">Login 👋</div>
          <LoginInput
            autoFocus
            label="Email:"
            placeholder="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setHasError(
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
              );
            }}
            error={
              email.length > 0 &&
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
                ? 'Email format is not valid'
                : ''
            }
          />
          <LoginInput
            label="Password:"
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <div className="login__signup">
              New to Finbright?&nbsp;
              <Link to={"/signup"} className="login__signup-link">
                Create an account
              </Link>
            </div> */}
          <button
            disabled={isSubmitting}
            className="login__submit"
            type="submit"
          >
            {isSubmitting ? (
              <ReactLoading
                className="react-loading"
                height={24}
                width={'100%'}
              />
            ) : (
              'Login'
            )}
          </button>
          {/* <div className="login__message">
            This is a nonprofit research project conducted in the school of
            computer engineering at Iran University of Science and Technology.
            The account is created only for our lab members to use existing
            features and develop new functionalities.
          </div> */}
        </form>
      </>
    </div>
  );
}

export default Login;
