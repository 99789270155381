import React from 'react';

import './style.scss';

function Title({
  title,
  subtitle,
  isPageTitle,
  icon,
  end_icon,
  className,
}) {
  const BLOCK_NAME = isPageTitle ? 'fin-page-title' : 'fin-title';
  return (
    <div className={`${className}`}>
      <div className="fin-title__icon-wrapper">
        {icon && <>{icon}</>}
        <div className={`${BLOCK_NAME}__title text-black dark:text-white mr-2`}>{title}</div>
        {end_icon && <>{end_icon}</>}
      </div>
      {subtitle && <div className={`${BLOCK_NAME}__subtitle text-neutral-500 dark:text-neutral-400`}>{subtitle}</div>}
    </div>
  );
}

export default Title;
