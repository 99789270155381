import React from 'react';
import { history } from './utils/history';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import OverallMarket from './pages/OverallMarket';
import Login from './pages/Login';
// import Signup from "./pages/Signup";
import Coin from './pages/Coin';
import DashboardLayout from './components/DashboardLayout';
import RequireAuth from './components/RequireAuth';
import { RapidPage } from './pages/Rapid/index';
import PortfolioAnalyzer from './pages/PortfolioAnalyzer';
import WizardPage from './pages/Wizard';
import NotFoundPage from './pages/NotFoundPage';
import PulsePage from './pages/Pulse';
import InDepthPage from './pages/InDepth';
import { useDispatch } from 'react-redux';
import { Logout } from './components/Logout';

function AppRoutes() {
  history.navigate = useNavigate();
  history.location = useLocation();
  history.dispatch = useDispatch();

  return (
    <Routes>
      {/* public routes */}
      {/* <Route path="/signup" element={<Signup />}></Route> */}
      <Route path="/login" element={<Login />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="*" element={<NotFoundPage />} />
      {/* private routed */}
      <Route
        index
        path="/"
        element={
          <RequireAuth>
            {' '}
            <OverallMarket />{' '}
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/market-overview"
        element={
          <RequireAuth>
            <OverallMarket />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/coin/:coin_name"
        element={
          <RequireAuth>
            {' '}
            <Coin />{' '}
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/profile"
        element={<RequireAuth Component={<DashboardLayout />} />}
      ></Route>
      <Route
        path="/rapid-insight"
        element={
          <RequireAuth>
            <RapidPage />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/portfolio-analyzer"
        element={
          <RequireAuth>
            <PortfolioAnalyzer />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/in-depth-insight"
        element={
          <RequireAuth>
            {' '}
            <InDepthPage />{' '}
            {/* <PulsePage />{' '} */}
          </RequireAuth>
        }
      ></Route>
       <Route
        path="/pulse"
        element={
          <RequireAuth>
            <PulsePage />{' '}
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/wizard"
        element={
          <RequireAuth>
            <WizardPage />{' '}
          </RequireAuth>
        }
      ></Route>
    </Routes>
  );
}

export default AppRoutes;
