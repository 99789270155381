import cx from 'classnames';
import { useEffect, useState } from 'react';
import { history } from '../../utils/history';
import { HiTrendingDown, HiTrendingUp } from 'react-icons/hi';
import { MdOutlineStackedLineChart } from 'react-icons/md';
import { TIME_FRAMES } from '../../pages/Coin';
import Pagination from '../Pagination';
import ReactLoading from 'react-loading';
import Title from '../Title';
import './style.scss';

const LoadingRow = () => (
  <div className="flex w-full px-4 justify-between items-center h-[2.6rem]">
    <div className="flex gap-2 items-center">
      {/* <div className="rounded-full bg-gray-200 animate-pulse" style={{ width: 25, height: 25 }} /> */}
      <div className="text-black dark:text-white font-normal bg-gray-200 animate-pulse rounded w-20 h-4" />
    </div>

    <div className="flex gap-2 items-center">
      {/* <div className="rounded-full bg-gray-200 animate-pulse" style={{ width: 25, height: 25 }} /> */}
      <div className="text-black dark:text-white font-normal bg-gray-200 animate-pulse rounded w-20 h-4" />
    </div>
  </div>
);

const PAGE_SIZE = 4;
export const CorrelationTable = ({
  firstList,
  secondList,
  firstTitle,
  secondTitle,
  headingText,
  desc,
  headingIcon,
  iconByAmount,
  firstListHeader,
  activeTimeFrame,
  setActiveTimeFrame,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [firstFormattedData, setFirstFormattedData] = useState(firstList);
  const [secFormattedData, setSecFormattedData] = useState(secondList);
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    const currentTableData = firstList.slice(firstPageIndex, lastPageIndex);
    const currentTableSecData = secondList.slice(firstPageIndex, lastPageIndex);
    setFirstFormattedData(currentTableData);
    setSecFormattedData(currentTableSecData);
  }, [currentPage, firstList, secondList]);
  return (
    <div className="flex flex-col max-md:h-full bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
      <div className="py-4 px-3 pb-0">
        <Title
          title={headingText}
          subtitle={desc}
          // icon={headingIcon}
          className="py-0"
        />
        <div className="gauge-charts__time-frames">
          {TIME_FRAMES.map((timeFrame, i) => (
            <div
              key={i}
              // className={cx('gauge-charts__time-frame', {
              //   'gauge-charts__time-frame--active':
              //     timeFrame === activeTimeFrame,
              // })}
              className={`gauge-charts__time-frame ${
                timeFrame === activeTimeFrame
                  ? 'bg-dark-primary text-white'
                  : 'bg-white text-black dark:bg-black dark:text-white'
              }`}
              onClick={() => setActiveTimeFrame(timeFrame)}
            >
              {timeFrame}
            </div>
          ))}
        </div>
      </div>
      {isLoading ? (
        <>
          <div className="flex justify-between max-md:flex-col max-md:gap-14">
            <div className="mt-4 w-full flex flex-col">
              <p className="text-black dark:text-white font-semibold flex justify-center">
                {firstTitle}
              </p>
              <div className="flex p-3 w-full border-b border-spacing-1 border-neutral-800 justify-between text-left  items-center">
                {firstListHeader?.map((item, index) => (
                  <p
                    key={index}
                    className={cx('text-gray-500', {
                      'mr-4': index === firstListHeader.length - 1,
                    })}
                  >
                    {item}
                  </p>
                ))}
              </div>
              {[...Array(PAGE_SIZE).keys()].map((_, index) => (
                <LoadingRow key={index} />
              ))}
            </div>
            <div className="mt-4 w-full flex flex-col">
              <p className="text-black dark:text-white font-semibold flex justify-center">
                {secondTitle}
              </p>
              <div className="flex p-3 w-full border-b border-spacing-1 border-neutral-800 justify-between text-left  items-center">
                {firstListHeader?.map((item, index) => (
                  <p
                    key={index}
                    className={cx('text-gray-500', {
                      'mr-4': index === firstListHeader.length - 1,
                    })}
                  >
                    {item}
                  </p>
                ))}
              </div>
              {[...Array(PAGE_SIZE).keys()].map((_, index) => (
                <LoadingRow key={index} />
              ))}
            </div>
          </div>
        </>
      ) : (
        // <>
        //   <div className="flex justify-between max-md:flex-col max-md:gap-14">
        //     <div className="mt-4 w-full flex flex-col">
        //       <p className="text-black dark:text-white font-semibold flex justify-center">
        //         {firstTitle}
        //       </p>
        //       <div className="flex p-1 w-full border-b border-spacing-1 border-neutral-800 justify-between text-left  items-center">
        //         {firstListHeader?.map((item, index) => (
        //           <p
        //             key={index}
        //             className={cx('text-gray-500', {
        //               'mr-4': index === firstListHeader.length - 1,
        //             })}
        //           >
        //             {item}
        //           </p>
        //         ))}
        //       </div>
        //     </div>
        //     <div className="mt-4 w-full flex flex-col">
        //       <p className="text-black dark:text-white font-semibold flex justify-center">
        //         {secondTitle}
        //       </p>
        //       <div className="flex p-3 w-full border-b border-spacing-1 border-neutral-800 justify-between text-left  items-center">
        //         {firstListHeader?.map((item, index) => (
        //           <p
        //             key={index}
        //             className={cx('text-gray-500', {
        //               'mr-4': index === firstListHeader.length - 1,
        //             })}
        //           >
        //             {item}
        //           </p>
        //         ))}
        //       </div>
        //     </div>
        //   </div>
        // </>
        <>
          <div className="correlation-table max-md:flex-col max-md:gap-0 flex justify-between p-1 relative pt-0 overflow-x-auto w-full gap-7">
            <div className="mt-4 w-full flex flex-col">
              <p className="text-black dark:text-white font-semibold flex justify-center">
                {firstTitle}
              </p>
              <div className="flex p-3 w-full border-b border-spacing-1 border-neutral-800 justify-between text-left  items-center">
                {firstListHeader?.map((item, index) => (
                  <p
                    key={index}
                    className={cx('text-gray-500', {
                      'mr-4': index === firstListHeader.length - 1,
                    })}
                  >
                    {item}
                  </p>
                ))}
              </div>
              {[...Array(PAGE_SIZE).keys()].map((_, index) => (
                <div
                  key={index}
                  className="flex w-full px-4 justify-between items-center h-[2.6rem]"
                >
                  <a
                    className="flex justify-between w-full"
                    href={
                      firstFormattedData[index]
                        ? '/coin/' +
                          firstFormattedData[index].stock_name +
                          '-USDT/'
                        : ''
                    }
                  >
                    <div className="flex gap-2 items-center cursor-pointer">
                      {firstFormattedData[index] ? (
                        <img
                          className="rounded-full"
                          width={25}
                          height={25}
                          alt=""
                          src={firstFormattedData[index].coin_icon}
                        />
                      ) : null}
                      <p className="text-black dark:text-white font-normal">
                        {firstFormattedData[index]
                          ? firstFormattedData[index].stock_name
                          : ' '}
                      </p>
                    </div>

                    <div className="flex gap-2 items-center">
                      <p
                        className={cx(
                          'p-0.5 rounded-lg text-black dark:text-white font-normal',
                          {
                            'text-rose-600': firstFormattedData[index]
                              ? firstFormattedData[index].score
                              : 0 < 0 && !!iconByAmount,
                            'text-emerald-500': firstFormattedData[index]
                              ? firstFormattedData[index].score
                              : 0 > 0 && !!iconByAmount,
                            'text-blue-600': !iconByAmount,
                          }
                        )}
                      >
                        {firstFormattedData[index]
                          ? firstFormattedData[index].score + '%'
                          : ' '}
                      </p>
                      {firstFormattedData[index] ? (
                        iconByAmount ? (
                          <HiTrendingUp size={20} color="#b6b6b6" />
                        ) : (
                          <MdOutlineStackedLineChart
                            size={20}
                            color="#b6b6b6"
                          />
                        )
                      ) : null}
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <div className="mt-4 mb-2 w-full flex flex-col">
              <p className="text-black dark:text-white font-semibold flex justify-center">
                {secondTitle}
              </p>
              <div className="flex p-3 w-full border-b border-spacing-1 border-neutral-800 justify-between text-left  items-center">
                {firstListHeader?.map((item, index) => (
                  <p
                    key={index}
                    className={cx('text-gray-500', {
                      'mr-4': index === firstListHeader.length - 1,
                    })}
                  >
                    {item}
                  </p>
                ))}
              </div>
              {[...Array(PAGE_SIZE).keys()].map((_, index) => (
                <div
                  key={index}
                  className="flex w-full px-4 justify-between items-center h-[2.6rem]"
                >
                  <a
                    className="flex justify-between w-full"
                    href={
                      firstFormattedData[index]
                        ? '/coin/' +
                          firstFormattedData[index].stock_name +
                          '-USDT/'
                        : ''
                    }
                  >
                    <div className="flex gap-2 items-center">
                      {secFormattedData[index] ? (
                        <img
                          className="rounded-full"
                          width={25}
                          height={25}
                          alt=""
                          src={secFormattedData[index].coin_icon}
                        />
                      ) : null}
                      <p className="text-black dark:text-white font-normal">
                        {secFormattedData[index]
                          ? secFormattedData[index].stock_name
                          : ' '}
                      </p>
                    </div>

                    <div className="flex gap-2 items-center">
                      <p
                        className={cx(
                          'p-0.5 rounded-lg text-black dark:text-white font-normal',
                          {
                            'text-rose-600': secFormattedData[index]
                              ? secFormattedData[index].score
                              : 0 < 0 && !!iconByAmount,
                            'text-emerald-500': secFormattedData[index]
                              ? secFormattedData[index].score
                              : 0 > 0 && !!iconByAmount,
                            'text-blue-600': !iconByAmount,
                          }
                        )}
                      >
                        {secFormattedData[index]
                          ? secFormattedData[index].score + '%'
                          : ' '}
                      </p>
                      {secFormattedData[index] ? (
                        iconByAmount ? (
                          <HiTrendingDown size={20} color="#b6b6b6" />
                        ) : (
                          <MdOutlineStackedLineChart
                            size={20}
                            color="#b6b6b6"
                          />
                        )
                      ) : null}
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className="flex w-full justify-center items-center pb-2">
        <Pagination
          className="correlation-table__pagination"
          currentPage={currentPage}
          totalCount={firstList.length}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};
