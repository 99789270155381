import React, { useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import BottomInput from '../../../../components/BottomInput';
import ButtonSelectDynamic from '../../../../components/ButtonSelectDynamic';

import './style.scss';

function StrategyRow({
  dark=true,
  allocationMode,
  removeRow,
  updateRow,
  positions,
  asset,
  coins,
  index,
  removable,
}) {
  const isManual = allocationMode === 'manual';
  return (
    <div className="smart-portfolio__row bg-light-back dark:bg-dark-back w-5/6 rounded-md">
      <div className="smart-portfolio__row-left bg-light-back dark:bg-dark-back">
        <div className="smart-portfolio__row-col">Asset #{index + 1}</div>
        <div className="smart-portfolio__row-col">
          <ButtonSelectDynamic
            dark={dark}
            isRequired
            isSearchable
            placeholder={'Select Coin'}
            options={coins}
            onChange={(e) =>
              updateRow({ ...asset, symbol_id: e?.value ? e.value : '' })
            }
            selectedOptions={coins.find((x) => x.value === asset.symbol_id)}
          />
        </div>
        <div className="smart-portfolio__row-col">
          <ButtonSelectDynamic
            dark={dark}
            isRequired
            placeholder={'Select Position Type'}
            options={positions}
            onChange={(e) =>
              updateRow({ ...asset, position_type: e?.value ? e.value : '' })
            }
            selectedOptions={positions.find((x) => x.value === asset.position_type)}
          />
        </div>
        <div className="smart-portfolio__row-col">
          <BottomInput
            className={`bg-light-back_secondary dark:bg-dark-back_secondary`}
            endIcon={isManual ? '%' : ''}
            value={asset.asset_allocation === 0 ? '' : asset.asset_allocation}
            onChange={(e) =>
              updateRow({
                ...asset,
                asset_allocation: e.target.value
                  ? parseFloat(e.target.value)
                  : 0,
              })
            }
            type={isManual ? 'number' : 'text'}
            disabled={!isManual}
          />
        </div>
        <div className={removable ? '' : 'mr-6'}>
        <RiDeleteBinLine
          onClick={() => removeRow(index)}
          className={removable ? 'smart-portfolio__row-delete-btn' : 'hidden'}
        />
      </div>
      </div>
    </div>
  );
}

export default StrategyRow;
