import classNames from 'classnames';
import React from 'react';
import './style.scss';
import { formatPrice } from '../../utils/formatPrice';

function OverallMarketHeaderCard({
  full_name,
  base_asset,
  close_price,
  percent_change,
  precision = 2,
  icon,
}) {
  return (
    <div className="overal-market-header-card bg-light-back_secondary text-black dark:bg-dark-back_secondary dark:text-neutral-400 mx-1" key={base_asset}>
      <img
        src={icon}
        alt={full_name}
        className="overal-market-header-card__logo"
      />
      <div className="overal-market-header-card__content">
        <div className="overal-market-header-card__name">{full_name}</div>
        <div className="overal-market-header-card__symbol">{base_asset}</div>
      </div>
      <div className="overal-market-header-card__footer">
        <div className="overal-market-header-card__price flex items-baseline">{formatPrice(close_price, precision)}<p className='pl-1 text-xs'>USDT</p></div>
        <div
          className={classNames(
            'overal-market-header-card__change',
            percent_change > 0 ? 'green' : 'red'
          )}
        >
          {percent_change}%
        </div>
      </div>
    </div>
  );
}

export default OverallMarketHeaderCard;
