import { React, useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Input from '../../components/Input';
import axiosInstance from '../../api/config';
import Chart from '../../components/Chart/chart';
import DashboardLayout from '../../components/DashboardLayout';
import BiggestTable from '../../components/BiggestTable';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic';
import { TbChartLine } from 'react-icons/tb';
import './style.scss';
import Title from '../../components/Title';
import OverallMarketHeader from '../../components/OverallMarketHeader';
import TreeMap from '../../components/Treemap';
import { MarketNews } from '../../components/MarketNews';
import marketcapOptions from '../../Constants/marketcapOptions';
import { useSelector } from 'react-redux';
import { history } from '../../utils/history';
import candleLoading from '../../assets/images/candleLoading.GIF';
import { getTreeMapOptions, BubbleChartOptions } from '../../Constants/treemap';
import {
  set_market_treemap_method,
  set_gainers_sort,
  set_losers_sort,
  getLosers,
  getGainers,
  percentchangecards,
  annualMarketCapCoingecko,
  getMarketTreemapData,
  set_market_chart_type,
  set_sentiment_chart_type,
  set_sentiment_treemap_method,
  getSentimentTreemapData,
  set_sentiment_source,
  getOverallNews,
} from './slice';
import Loading from 'react-loading';

const SAMPLE_BIGGEST_TABLE_COLUMNS = [
  { label: 'symbol', accessor: 'symbol' },
  { label: 'price', accessor: 'price' },
  { label: 'volume', accessor: 'volume', isSortable: true },
  { label: 'change', accessor: 'percent_change', isSortable: true },
];

export const TIME_FRAMES = ['15 Min', '1 Hour', '4 Hour', '1 Day'];

function OverallMarket() {
  const { dispatch, navigate } = history;
  const [losersActiveTimeFrame, setLosersActiveTimeFrame] = useState(
    TIME_FRAMES[3]
  );
  const [gainersActiveTimeFrame, setGainersActiveTimeFrame] = useState(
    TIME_FRAMES[3]
  );
  const selector = useSelector((state) => state.overall);
  const cardsData = selector.percentchangecardsResult;
  const percent_change_cards_status = selector.percentchangecards_status;
  const Gainers = selector.biggestGainers;
  const Losers = selector.biggestLosers;
  const gainers_status = selector.biggestGainers_status;
  const losers_status = selector.biggestLosers_status;
  const annualMarketCap_results = selector.annualMarketCapCoingeckoResult;
  const annualMarketCap_status = selector.annualMarketCapCoingecko_status;
  const market_treemap_method = selector.marketTreemapMethod;
  const market_treemap_methods = selector.marketTreemapMethods;
  const sentiment_treemap_method = selector.sentimentTreemapMethod;
  const sentiment_treemap_methods = selector.sentimentTreemapMethods;
  const market_treemap_data = selector.MarketTreemapData;
  const market_treemap_status = selector.MarketTreemapStatus;
  const sentiment_treemap_data = selector.sentimentTreemapData;
  const sentiment_treemap_status = selector.sentimentTreemapStatus;
  const market_chart_type = selector.marketSelectedChart;
  const market_chart_types = selector.marketChartOptions;
  const sentiment_chart_type = selector.sentimentSelectedChart;
  const sentiment_chart_types = selector.sentimentChartOptions;
  const sentiment_source = selector.sentimentSelectedSource;
  const sentiment_sources = selector.sentimentSources;
  const sentiment_value_type = selector.sentimentSelectedValueType;
  const theme = selector.theme;
  const gainers_sort_field = selector.gainersSortField;
  const gainers_order = selector.gainersOrder;
  const losers_sort_field = selector.losersSortField;
  const losers_order = selector.losersOrder;
  const source_weight = selector.source_weight;
  const tag_weight = selector.tag_weight;
  const overall_news_results = selector.overall_news_results;
  const overall_news_status = selector.overall_news_status;

  //variables:
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const getIntervalValue = (name) => {
    switch (name) {
      case TIME_FRAMES[0]:
        return 900;
      case TIME_FRAMES[1]:
        return 3600;
      case TIME_FRAMES[2]:
        return 14400;
      default:
        return 86400;
    }
  };

  useEffect(() => {
    dispatch(percentchangecards());
    dispatch(getGainers());
    dispatch(getLosers());
    dispatch(annualMarketCapCoingecko());
    dispatch(getOverallNews());
  }, []);

  useEffect(() => {
    dispatch(getMarketTreemapData({ method: market_treemap_method.value }));
  }, [market_treemap_method]);

  useEffect(() => {
    if (
      (sentiment_treemap_method.value,
      sentiment_source.value,
      sentiment_value_type.value)
    ) {
      const content = {
        method: sentiment_treemap_method.value,
        source: sentiment_source.value,
        value_type: sentiment_value_type.value,
        source_weight: 0.9,
        tag_weight: 0.99,
      };
      dispatch(getSentimentTreemapData(content));
    }
  }, [
    sentiment_treemap_method,
    sentiment_source,
    sentiment_value_type,
    source_weight,
    tag_weight,
  ]);

  useEffect(() => {
    dispatch(
      getGainers({
        interval: getIntervalValue(gainersActiveTimeFrame),
        order: gainers_order,
        sort_field: gainers_sort_field,
      })
    );
  }, [gainersActiveTimeFrame, gainers_order, gainers_sort_field]);

  useEffect(() => {
    dispatch(
      getLosers({
        interval: getIntervalValue(losersActiveTimeFrame),
        order: losers_order,
        sort_field: losers_sort_field,
      })
    );
  }, [losersActiveTimeFrame, losers_order, losers_sort_field]);

  const options = useMemo(() => {
    return marketcapOptions(annualMarketCap_results, theme);
  }, [annualMarketCap_results, theme]);

  const searchAction = (q) => {
    setIsSearchLoading(true);
    axiosInstance
      .get('/market/searchcoin/', {
        params: { search: !!q ? q : searchInputValue },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setSearchResult(res.data.slice(0, 3));
          if (!q) {
            navigate(`/coin/${res.data[0].symbol}`);
            navigate(0);
          }
        } else {
          setSearchResult([]);
        }
      })
      .catch((error) => alert(error.message))
      .finally(() => setIsSearchLoading(false));
  };

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      searchAction();
    }
  }

  function handleChange(event) {
    setSearchInputValue(event.target.value);
    if (!!event.target.value) {
      searchAction(event.target.value);
    } else {
      setSearchResult([]);
    }
  }
  // Data retrieved from: https://www.uefa.com/uefachampionsleague/history/

  return (
    <DashboardLayout>
      {percent_change_cards_status == 'fulfilled' ? (
        <div className="market-overview">
          <Helmet>
            <title>Market Overview</title>
          </Helmet>
          <div className="market-overview__header text-black">
            <Title
              className={'text-black'}
              title="Market Overview"
              subtitle="All-in-One Insights into Cryptocurrency Market Trends"
              isPageTitle
            />
            <div className="market-overview__searchbar">
              <Input
                placeholder="search ..."
                onKeyDown={handleKeyDown}
                value={searchInputValue}
                onChange={handleChange}
                search={true}
                className="text-black dark:text-white bg-light-back_secondary dark:bg-dark-back_secondary"
              />
              <div className="absolute top-10 z-50 w-56">
                {isSearchLoading ? (
                  <div className="flex bg-light-back_secondary dark:bg-dark-back_secondary rounded-xl justify-center h-8 ">
                    <Loading width={20} height={10} className="mt-1" />
                  </div>
                ) : (
                  searchResult.map((item, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        navigate(`/coin/${item.symbol}`);
                        navigate(0);
                      }}
                      className="flex p-2 mt-1 bg-light-back_secondary text-black dark:bg-dark-side dark:text-white border-b dark:border-neutral-800 rounded-xl  gap-2 items-center cursor-pointer"
                    >
                      <img
                        src={item.icon}
                        alt="coin-icon"
                        className="h-7 w-7 rounded-full"
                      />
                      <p className="">{item.symbol}</p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          {
            <OverallMarketHeader
              items={cardsData}
              loading={
                percent_change_cards_status !== 'fulfilled'
              }
            />
          }
          <div className="market-overview__main">
            <div className="market-overview__topSection">
              <div className="market-overview__topSection-SubSection bg-light-back_secondary dark:bg-dark-back_secondary p-4 rounded-md">
                <Title
                  title="Market Capitalization"
                  subtitle="Explore the Total Value of the Cryptocurrency Market"
                />
                {annualMarketCap_status == 'fulfilled' ? (
                  <div className="mr-2">
                    <Chart Options={options} />
                  </div>
                ) : (
                  <div className="animate-pulse w-full flex h-[410px]">
                    <TbChartLine
                      style={{
                        color: 'gray',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </div>
                )}
              </div>
              <MarketNews
                rows={overall_news_results}
                loading={overall_news_status !== 'fulfilled'}
              />
            </div>
            <div className="mt-5 rounded-md">
              <div className="treemap bg-light-back_secondary dark:bg-dark-back_secondary grid grid-cols-2 p-5 gap-x-4 gap-y-0">
                <div className="col-span-1">
                  <Title
                    title={'Daily Price Dynamics'}
                    subtitle={
                      'Track Real-time Price Movements with our Day-Long Heatmap'
                    }
                  />
                </div>
                <div className="col-span-1 justify-end items-center flex my-1">
                  <div className="text-base mx-1">
                    <ButtonSelectDynamic
                      dark={theme}
                      onChange={(item) => {
                        dispatch(item ? set_market_treemap_method(item) : null);
                      }}
                      options={market_treemap_methods}
                      value={market_treemap_method}
                      defaultValue={market_treemap_method}
                      label="Box Size"
                      isRequired
                    />
                  </div>
                  <div className="text-base mx-1">
                    <ButtonSelectDynamic
                      dark={theme}
                      options={market_chart_types}
                      value={market_chart_type}
                      defaultValue={market_chart_type}
                      onChange={(item) => {
                        item ? dispatch(set_market_chart_type(item)) : null;
                      }}
                      label="Chart Type"
                      isRequired
                    />
                  </div>
                </div>
                <TreeMap
                  Options={
                    market_chart_type?.value === 'treemap'
                      ? getTreeMapOptions(market_treemap_data, theme)
                      : BubbleChartOptions(market_treemap_data, theme)
                  }
                  Status={market_treemap_status}
                />
              </div>
            </div>
            <div className="mt-5 rounded-md">
              <div className="treemap bg-light-back_secondary dark:bg-dark-back_secondary grid grid-cols-2 p-5 gap-x-4 gap-y-0">
                <div className="col-span-1">
                  <Title
                    title={'Crypto Sentiment Heatmap'}
                    subtitle={
                      'Explore Daily Sentiment Trends Across Cryptocurrency News and Analysis'
                    }
                  />
                </div>
                <div className="col-span-1 justify-end items-center flex my-1">
                  <div className="text-base mx-1">
                    <ButtonSelectDynamic
                      dark={theme}
                      onChange={(item) => {
                        dispatch(
                          item ? set_sentiment_treemap_method(item) : null
                        );
                      }}
                      options={sentiment_treemap_methods}
                      value={sentiment_treemap_method}
                      defaultValue={sentiment_treemap_method}
                      label="Box Size"
                      isRequired
                    />
                  </div>
                  <div className="text-base mx-1">
                    <ButtonSelectDynamic
                      dark={theme}
                      options={sentiment_chart_types}
                      value={sentiment_chart_type}
                      defaultValue={sentiment_chart_type}
                      onChange={(item) => {
                        item ? dispatch(set_sentiment_chart_type(item)) : null;
                      }}
                      label="Chart Type"
                      isRequired
                    />
                  </div>
                  <div className="text-base mx-1">
                    <ButtonSelectDynamic
                      dark={theme}
                      options={sentiment_sources}
                      value={sentiment_source}
                      defaultValue={sentiment_source}
                      onChange={(item) => {
                        item ? dispatch(set_sentiment_source(item)) : null;
                      }}
                      label="Source"
                      isRequired
                    />
                  </div>
                  {/* <div className="text-base mx-1">
                      <ButtonSelectDynamic
                        dark={theme}
                        options={sentiment_value_types}
                        value={sentiment_value_type}
                        defaultValue={sentiment_value_type}
                        onChange={(item) => {
                          item ? dispatch(set_sentiment_value_type(item)) : null;
                        }}
                        label="Value Type"
                        isRequired
                      />
                    </div> */}
                </div>
                <TreeMap
                  Options={
                    sentiment_chart_type?.value === 'treemap'
                      ? getTreeMapOptions(
                          sentiment_treemap_data,
                          theme,
                          'sentiment'
                        )
                      : BubbleChartOptions(sentiment_treemap_data, theme)
                  }
                  Status={sentiment_treemap_status}
                  ChartType={sentiment_chart_type}
                />
              </div>
            </div>
            <div className="market-overview__biggest-tables">
              {gainers_status === 'fulfilled' ? (
                <BiggestTable
                  isFilled={true}
                  rows={Gainers}
                  columns={SAMPLE_BIGGEST_TABLE_COLUMNS}
                  title="Biggest Gainers"
                  subtitle="Discover Cryptocurrencies Experiencing Significant Increase Over Different Time Horizons"
                  isTitlePositive
                  ActiveTimeFrame={gainersActiveTimeFrame}
                  setActiveTimeFrame={setGainersActiveTimeFrame}
                  DefaultOrder="desc"
                  setSort={set_gainers_sort}
                  sortField={gainers_sort_field}
                  order={gainers_order}
                ></BiggestTable>
              ) : (
                <BiggestTable
                  isFilled={false}
                  columns={SAMPLE_BIGGEST_TABLE_COLUMNS}
                  title="Biggest Gainers"
                  subtitle="Discover Cryptocurrencies Experiencing Significant Increase Over Different Time Horizons"
                  isTitlePositive
                  ActiveTimeFrame={gainersActiveTimeFrame}
                  setActiveTimeFrame={setGainersActiveTimeFrame}
                ></BiggestTable>
              )}
              {losers_status === 'fulfilled' ? (
                <BiggestTable
                  isFilled={true}
                  rows={Losers}
                  columns={SAMPLE_BIGGEST_TABLE_COLUMNS}
                  title="Biggest Losers"
                  subtitle="Discover Cryptocurrencies Experiencing Significant Decreases Over Different Time Horizons"
                  isTitlePositive={false}
                  ActiveTimeFrame={losersActiveTimeFrame}
                  setActiveTimeFrame={setLosersActiveTimeFrame}
                  DefaultOrder="asc"
                  setSort={set_losers_sort}
                  sortField={losers_sort_field}
                  order={losers_order}
                ></BiggestTable>
              ) : (
                <BiggestTable
                  isFilled={false}
                  columns={SAMPLE_BIGGEST_TABLE_COLUMNS}
                  title="Biggest Losers"
                  subtitle="Discover Cryptocurrencies Experiencing Significant Decreases Over Different Time Horizons"
                  isTitlePositive={false}
                  ActiveTimeFrame={losersActiveTimeFrame}
                  setActiveTimeFrame={setLosersActiveTimeFrame}
                ></BiggestTable>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="market-overview__page-loading">
          <img src={candleLoading} style={{ width: 300, height: 300 }}></img>
        </div>
      )}
    </DashboardLayout>
  );
}

export default OverallMarket;
