import moment from "moment"
import { toast } from 'react-toastify';

export const validate_time = (start_date, holding_period, forward = true) => {
  const endDate = new Date(
    start_date.getTime() + holding_period * 24 * 60 * 60 * 1000
  );
  var today = moment(); // get current date and time
  var startOfYesterday = moment().subtract(1, 'day').startOf('day');

  const result = {
    start_date: start_date,
    holding_period: holding_period,
    end_date: endDate,
    is_valid: false,
  }

  if (forward) {
    if (endDate >= today) {
      return {...result, is_valid: true}
    }
  } else {
    if (endDate <= startOfYesterday) {
      return {...result, is_valid: true};
    }
  }

  return result;
};

export const arraysAreEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
}

export const validateDates = (start_date, end_date) => {
  if (start_date >= end_date) {
    toast.warn("Start date must come be smaller than end date.");
    return false;
  }
  return true;
}

export const areAllVariablesPresent = (variables) => {
  return variables.every(variable => {
    if (variable === null || variable === undefined) {
      return false;
    }
    if (typeof variable === 'string' || Array.isArray(variable)) {
      return variable.length !== 0;
    }
    if (typeof variable === 'object') {
      return Object.keys(variable).length !== 0;
    }
    if (typeof variable === 'number') {
      return !isNaN(variable);
    }
    return true;
  });
};
