import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/config';
import { PRED_INTERVALS, MARGIN_METHODS } from '../../Constants/consts';
import { validateDates } from '../../utils/validators';
import {
  positions_to_list,
  orders_to_list,
  timeframe_combs_to_list,
  symbol_combs_to_list,
  summary_metrics_map,
  summary_metrics,
  combo_combs_to_list,
} from '../../Constants/strategyTable';

const now = new Date(new Date());
const yearAgo = new Date(new Date().setUTCHours(0, 0, 0));
yearAgo.setDate(now.getDate() - 360);

const initialState = {
  start_time: Math.floor(yearAgo.getTime() / 1000),
  end_time: Math.floor(now.getTime() / 1000),

  available_coins: [],
  selected_coins: [],
  get_coins_status: 'idle',

  available_feature_sets: [],
  selected_feature_sets: [],
  get_feature_sets_status: 'idle',

  available_labeling_methods: [],
  selected_labeling_methods: [],
  get_labeling_methods_status: 'idle',

  available_learning_models: [],
  selected_learning_models: [],
  get_learning_models_status: 'idle',

  available_strategies: [],
  selected_strategies: [],
  get_strategies_status: 'idle',

  available_margins: MARGIN_METHODS,
  selected_margins: MARGIN_METHODS[0],

  available_timeframes: PRED_INTERVALS,
  selected_timeframes: PRED_INTERVALS[1],

  all_measures: [],
  short_measures: [],
  long_measures: [],
  summaries: [],
  get_measures_status: 'idle',

  positions: [],
  get_positions_status: 'idle',

  orders: [],
  get_orders_status: 'idle',

  timeframe_combinations: {},
  get_timeframe_combinations_status: 'idle',

  symbol_combinations: {},
  get_symbol_combinations_status: 'idle',

  combo_combinations: {},
  get_combo_combinations_status: 'idle',

  leverage: 1,
};

export const getFeatureTransforms = createAsyncThunk(
  'strategy/getfeaturetransforms',
  async () => {
    const response = await axiosInstance.get(
      '/prediction/featureTransforms/',
      {}
    );
    return response.data;
  }
);

export const getLabelTransforms = createAsyncThunk(
  'strategy/getlabeltransforms',
  async () => {
    const response = await axiosInstance.get(
      '/prediction/labelTransforms/',
      {}
    );
    return response.data;
  }
);

export const getModels = createAsyncThunk('strategy/getmodels', async () => {
  const response = await axiosInstance.get('/prediction/models/', {});
  return response.data;
});

export const get_strategies = createAsyncThunk(
  'strategy/get_strategies',
  async () => {
    const results = await axiosInstance.get('/strategy/strategies/');
    return results.data;
  }
);

export const get_coins = createAsyncThunk('strategy/get_coins', async () => {
  const results = await axiosInstance.get('/core/stocks/?has_predictions=true');
  return results.data;
});

export const get_measures = createAsyncThunk(
  'strategy/get_measures',
  async (data) => {
    const results = await axiosInstance.get('/strategy/measures/', {
      params: data,
    });
    return results.data;
  }
);

export const get_positions = createAsyncThunk(
  'strategy/get_positions',
  async (data) => {
    const results = await axiosInstance.get('/strategy/positions/', {
      params: data,
    });
    return results.data;
  }
);

export const get_orders = createAsyncThunk(
  'strategy/get_orders',
  async (data) => {
    const results = await axiosInstance.get('/strategy/orders/', {
      params: data,
    });
    return results.data;
  }
);

export const get_timeframe_combinations = createAsyncThunk(
  'strategy/get_timeframe_combinations',
  async (data) => {
    const results = await axiosInstance.get(
      '/strategy/recommendedCombinationForTimeframe/',
      {
        params: data,
      }
    );
    return results.data;
  }
);

export const get_symbol_combinations = createAsyncThunk(
  'strategy/get_symbol_combinations',
  async (data) => {
    const results = await axiosInstance.get(
      '/strategy/recommendedCombinationForSymbol/',
      {
        params: data,
      }
    );
    return results.data;
  }
);

export const get_combo_combinations = createAsyncThunk(
  'strategy/get_combo_combinations',
  async (data) => {
    const results = await axiosInstance.get(
      '/strategy/recommendedSymbolTimeframeForCombination/',
      {
        params: data,
      }
    );
    return results.data;
  }
);

export const wizardSlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    select_coins: (state, action) => {
      state.selected_coins = action.payload;
    },
    select_feature_sets: (state, action) => {
      state.selected_feature_sets = action.payload;
    },
    select_labeling_methods: (state, action) => {
      state.selected_labeling_methods = action.payload;
    },
    select_learning_models: (state, action) => {
      state.selected_learning_models = action.payload;
    },
    select_strategies: (state, action) => {
      state.selected_strategies = action.payload;
    },
    select_signal_types: (state, action) => {
      state.selected_signal_types = action.payload;
    },
    select_margin_methods: (state, action) => {
      state.selected_margins = action.payload;
    },
    select_timeframes: (state, action) => {
      state.selected_timeframes = action.payload;
    },
    set_start_time: (state, action) => {
      if (validateDates(action.payload, state.end_time)) {
        state.start_time = action.payload;
      }
    },
    set_end_time: (state, action) => {
      if (validateDates(state.start_time, action.payload)) {
        state.end_time = action.payload;
      }
    },
    set_leverage: (state, action) => {
      state.leverage = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(get_strategies.pending, (state) => {
        state.get_strategies_status = 'pending';
      })
      .addCase(get_strategies.fulfilled, (state, action) => {
        const payload = action.payload?.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_strategies = payload[0];
        state.available_strategies = payload;
        state.get_strategies_status = 'fulfilled';
      })
      .addCase(get_strategies.rejected, (state) => {
        state.get_strategies_status = 'rejected';
      })
      .addCase(getFeatureTransforms.pending, (state) => {
        state.get_feature_sets_status = 'pending';
      })
      .addCase(getFeatureTransforms.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_feature_sets = output[0];
        state.available_feature_sets = output;
        state.get_feature_sets_status = 'fulfilled';
      })
      .addCase(getFeatureTransforms.rejected, (state) => {
        state.get_feature_sets_status = 'rejected';
      })
      .addCase(getLabelTransforms.pending, (state) => {
        state.get_labeling_methods_status = 'pending';
      })
      .addCase(getLabelTransforms.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_labeling_methods = output[0];
        state.available_labeling_methods = output;
        state.get_labeling_methods_status = 'fulfilled';
      })
      .addCase(getLabelTransforms.rejected, (state) => {
        state.get_labeling_methods_status = 'rejected';
      })
      .addCase(getModels.pending, (state) => {
        state.get_models_status = 'pending';
      })
      .addCase(getModels.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_learning_models = output[0];
        state.available_learning_models = output;
        state.get_models_status = 'fulfilled';
      })
      .addCase(getModels.rejected, (state) => {
        state.get_models_status = 'rejected';
      })
      .addCase(get_coins.pending, (state) => {
        state.get_coins_status = 'pending';
      })
      .addCase(get_coins.fulfilled, (state, action) => {
        state.available_coins = [];
        state.available_coins = action.payload?.map((item) => {
          return {
            value: item.id,
            label: item.symbol,
          };
        });
        const bitcoin = state.available_coins.filter(
          (x) => x.label == 'BTC-USDT'
        )[0];
        state.selected_coins = bitcoin;
        state.get_coins_status = 'fulfilled';
      })
      .addCase(get_coins.rejected, (state) => {
        state.get_coins_status = 'rejected';
      })
      .addCase(get_measures.pending, (state) => {
        state.get_measures_status = 'pending';
      })
      .addCase(get_measures.fulfilled, (state, action) => {
        const payload = action.payload;
        payload.map((list) => {
          list.side === 0
            ? (state.all_measures = list)
            : list.side === 1
            ? (state.long_measures = list)
            : list.side === -1
            ? (state.short_measures = list)
            : '';
        });
        state.summaries = summary_metrics_map(
          summary_metrics,
          state.all_measures,
          state.long_measures,
          state.short_measures
        );
        state.get_measures_status = 'fulfilled';
      })
      .addCase(get_measures.rejected, (state) => {
        state.get_measures_status = 'rejected';
      })
      .addCase(get_positions.pending, (state) => {
        state.get_positions_status = 'pending';
      })
      .addCase(get_positions.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.length > 1) {
          state.positions = positions_to_list(payload);
          state.get_positions_status = 'fulfilled';
        }
      })
      .addCase(get_positions.rejected, (state) => {
        state.get_positions_status = 'rejected';
      })
      .addCase(get_orders.pending, (state) => {
        state.get_orders_status = 'pending';
      })
      .addCase(get_orders.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload.length > 1) {
          const orders = orders_to_list(payload);
          state.orders = orders;
          state.get_orders_status = 'fulfilled';
        }
      })
      .addCase(get_orders.rejected, (state) => {
        state.get_orders_status = 'rejected';
      })
      .addCase(get_timeframe_combinations.pending, (state) => {
        state.get_timeframe_combinations_status = 'pending';
      })
      .addCase(get_timeframe_combinations.fulfilled, (state, action) => {
        const payload = action.payload;
        state.timeframe_combinations = payload;
        state.timeframe_combinations.results = timeframe_combs_to_list(
          payload.results
        );
        state.get_timeframe_combinations_status = 'fulfilled';
      })
      .addCase(get_timeframe_combinations.rejected, (state) => {
        state.get_timeframe_combinations_status = 'rejected';
      })
      .addCase(get_symbol_combinations.pending, (state) => {
        state.get_symbol_combinations_status = 'pending';
      })
      .addCase(get_symbol_combinations.fulfilled, (state, action) => {
        const payload = action.payload;
        state.symbol_combinations = payload;
        state.symbol_combinations.results = symbol_combs_to_list(
          payload.results
        );
        state.get_symbol_combinations_status = 'fulfilled';
      })
      .addCase(get_symbol_combinations.rejected, (state) => {
        state.get_symbol_combinations_status = 'rejected';
      })
      .addCase(get_combo_combinations.pending, (state) => {
        state.get_combo_combinations_status = 'pending';
      })
      .addCase(get_combo_combinations.fulfilled, (state, action) => {
        const payload = action.payload;
        state.combo_combinations = payload;
        state.combo_combinations.results = combo_combs_to_list(payload.results);
        state.get_combo_combinations_status = 'fulfilled';
      })
      .addCase(get_combo_combinations.rejected, (state) => {
        state.get_combo_combinations_status = 'rejected';
      });
  },
});

export const {
  select_coins,
  select_strategies,
  select_signal_types,
  select_margin_methods,
  select_timeframes,
  select_lookback,
  select_feature_sets,
  select_labeling_methods,
  select_learning_models,
  set_start_time,
  set_end_time,
  set_leverage,
} = wizardSlice.actions;

export default wizardSlice.reducer;
