import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  HiChevronDoubleUp,
  HiChevronDoubleDown,
  HiOutlineSwitchVertical,
  HiOutlineArrowSmUp,
  HiOutlineArrowSmDown,
} from 'react-icons/hi';
import { formatVolume } from '../../utils/formatPrice';
import Pagination from '../Pagination';
import './style.scss';
import Title from '../Title';
import { history } from '../../utils/history';
import useIsMobile from '../../hooks/useIsMobile';
import ButtonSelectDynamic from '../ButtonSelectDynamic';

const PAGE_SIZE = 5;
const TIME_FRAMES = ['15 Min', '1 Hour', '4 Hour', '1 Day'];

function BiggestTable({
  isFilled,
  title,
  isTitlePositive,
  subtitle,
  columns,
  rows,
  ActiveTimeFrame,
  setActiveTimeFrame,
  sortField="percent_change",
  order="asc",
  setSort,
}) {
  const isMobile = useIsMobile();
  const { navigate, dispatch } = history;
  const [currentPage, setCurrentPage] = useState(1);
  const [Data, setData] = useState([]);
  const loadingData = Array.from({ length: PAGE_SIZE }, (_, index) => index);

  useEffect(() => {
    setCurrentPage(1);
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    const currentTableData = rows ? rows.slice(firstPageIndex, lastPageIndex) : []
    setData(currentTableData);
  }, [rows])

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    const currentTableData = rows ? rows.slice(firstPageIndex, lastPageIndex) : []
    setData(currentTableData);
  }, [currentPage]);

  const handleSortByColumn = (accessor) => {
    const sortOrder =
      accessor === sortField ? (order === 'asc' ? 'desc' : 'asc') : 'desc';
      dispatch(setSort({sortField: accessor, order: sortOrder}))
  };

  return (
    <div
      className={`biggest-table bg-light-back_secondary dark:bg-dark-back_secondary`}
    >
      <Title
        title={title}
        icon={
          isTitlePositive ? (
            <HiChevronDoubleUp size={20} color="#21cc6d" />
          ) : (
            <HiChevronDoubleDown size={20} color="#ee4657" />
          )
        }
      />
      <div className="biggest-table__subtitle">{subtitle}</div>
      <div className="biggest-table__time-frames">
        {isMobile ? (
          <div className="biggest-table__time-frames-select">
            <ButtonSelectDynamic
              options={TIME_FRAMES.map((timeFrame) => ({
                value: timeFrame,
                label: timeFrame,
              }))}
              value={ActiveTimeFrame}
              defaultValue={ActiveTimeFrame}
              onChange={(newValue) => setActiveTimeFrame(newValue?.value)}
              label="TimeFrame:"
            />
          </div>
        ) : (
          <>
            {TIME_FRAMES.map((timeFrame, index) => (
              <div
                key={index}
                className={`biggest-table__time-frame ${timeFrame === ActiveTimeFrame ? 'bg-dark-primary text-white' : 'bg-white dark:bg-black'}`}
                onClick={() => setActiveTimeFrame(timeFrame)}
              >
                {timeFrame}
              </div>
            ))}
          </>
        )}
      </div>
      <table className="biggest-table__table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                onClick={
                  col.isSortable ? () => handleSortByColumn(col.accessor) : null
                }
                style={{
                  cursor: col.isSortable ? 'pointer' : 'default',
                }}
              >
                <div className="biggest-table__th">
                  {col.label}
                  {col.isSortable && (
                    <div className="biggest-table__sort-icon">
                      {sortField === col.accessor ? (
                        order === 'desc' ? (
                          <HiOutlineArrowSmUp fontSize={20} />
                        ) : (
                          <HiOutlineArrowSmDown fontSize={20} />
                        )
                      ) : (
                        <HiOutlineSwitchVertical />
                      )}
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isFilled
            ? Data.map((row, index) => (
                <tr
                  className="cursor-pointer"
                  key={index}
                  onClick={() => navigate(`/coin/${row.symbol}`)}
                >
                  <td className='flex justify-center'>
                    <div className='flex justify-start items-center w-3/5 '>
                    <img
                      className='rounded-full mr-2'
                      height={25}
                      width={25}
                      src={
                        row.icon ||
                        'https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg'
                      }
                      alt="logo"
                    />
                    <p>{row.symbol}</p>
                    </div>
                    </td>
                  <td>{row.price}</td>
                  {/* <td>{row.previous_price}</td> */}
                  <td>{formatVolume(row.volume)}</td>
                  <td className={row.change_value < 0 ? 'red' : 'green'}>
                    {row.change_value}%
                  </td>
                </tr>
              )) : loadingData.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div
                      style={{
                        backgroundColor: 'gray',
                        width: '60px',
                        height: '20px',
                        borderRadius: '7px',
                        margin: '0 auto',
                      }}
                    ></div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: 'gray',
                        width: '30px',
                        height: '30px',
                        borderRadius: '100%',
                        margin: '0 auto',
                      }}
                    ></div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: 'gray',
                        width: '60px',
                        height: '20px',
                        borderRadius: '7px',
                        margin: '0 auto',
                      }}
                    ></div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: 'gray',
                        width: '60px',
                        height: '20px',
                        borderRadius: '7px',
                        margin: '0 auto',
                      }}
                    ></div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: 'gray',
                        width: '60px',
                        height: '20px',
                        borderRadius: '7px',
                        margin: '0 auto',
                      }}
                    ></div>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      {isFilled && (
        <div className="biggest-table__pagination-wrapper">
          <div className="biggest-table__pagination-text">
            {`showing ${(currentPage - 1) * PAGE_SIZE + 1} to ${Math.min(
              currentPage * PAGE_SIZE,
              isFilled ? rows.length : loadingData.length
            )} of ${isFilled ? rows.length : loadingData.length} items`}
          </div>
          <Pagination
            className="biggest-table__pagination"
            currentPage={currentPage}
            totalCount={isFilled ? rows.length : loadingData.length}
            pageSize={PAGE_SIZE}
            siblingCount={0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
}

export default BiggestTable;
