export const options = {
  chart: {
    type: 'bar',
    backgroundColor: null,
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['95%', '90%', '75%', '50%', '25%', '10%', '5%'],
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Goals',
    },
    gridLineWidth: 0,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0,
      pointPadding: 0,
      pointWidth: 20,
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      threshold: -0.001,
      dataLabels: {
        enabled: true,
        inside: true,
        align: 'right',
        x: +30,
      },
      name: 'Cristiano Ronaldo',
      data: [
        { y: 1021.04, color: 'red' },
        1010.83,
        1000.01,
        950.99,
        930.1,
        910.44,
        880.27,
      ],
    },
  ],
};

export const BarChartOptions = (
  chartTitle,
  xAxisTitle,
  yAxisTitle,
  categories,
  seriesData,
  dark=true
) => {
  const precise_series_data = seriesData.map((x) => {return parseFloat(x.toFixed(2))})
  const zipped_series = precise_series_data.map((data, index)=> {
    return {
      value: data,
      category: categories[index]
    }
  })
  const minValue = Math.min(...precise_series_data); // Find the minimum value in the series
  const chartOptions = {
    chart: {
      type: 'column', // Change the chart type to 'bar'
      backgroundColor: 'transparent',
      width: 600, // Set the desired width in pixels
      height: 400, // Set the desired height in pixels
    },
    title: {
      text: "",
      style: {
        color: dark ? '#ffffff' : '#111',
      },
    },
    colors: [
      '#B80000',
      '#FF9800',
      '#5F8670'
    ],
    xAxis: {
      categories: [""],
      title: {
        text: xAxisTitle,
        style: {
          color: dark ? '#ffffff': '#111',
        },
      },
      gridLineWidth: 0, // Remove the grid lines on the x-axis
      labels: {
        style: {
          color: dark ? '#ffffff': '#111', // Set the color of the x-axis labels to white
        },
      },
    },
    tooltip: {
      formatter: function() {
        return this.series.name + '<br/>' +
          'Porfolio Equity: ' + this.y;
      }
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: {
          color: dark ? '#ffffff': '#111',
        },
      },
      gridLineWidth: 0,
      labels: {
        style: {
          color: dark ? '#ffffff': '#111',
        },
      },
      min: minValue - (minValue * 0.01),
    },
    plotOptions: {
      series: {
        color: '#4169e1',
        borderColor: 'transparent',
        marker: {
          states: {
            hover: {
              fillColor: 'red',
            },
          },
        },
        pointPadding: 0.1, // Remove padding between the bars
        groupPadding: 0.04, // Remove padding between the groups of bars
        pointWidth: 80, // Set the width of the bars
        dataLabels: {
          style: {
            color: dark ? '#ffffff': '#111', // Set the color of the data point labels to white
          },
        },
      },
    },
    legend: {
      itemStyle: {
        color: dark ? 'white' : 'black' // Set the legend item color to white
      }
    },
    series: zipped_series.map((zipped) => {
      return {
        name: zipped.category.replace("_", " "),
        data: [zipped.value],
        color: zipped.category == "Initial_Weights" ? '#B80000' : zipped.category == "Adjusted_Weights" ? "#FF9800" : '#5F8670'
      }
    }),
  };

  return chartOptions;
};

export function getStackedColumnOptions(greenSeries, redSeries) {
  return {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: 150,
      width: 250,
    },
    title: {
      enabled: false,
      text: '',
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
    visible: false,
      title: '',
      // gridLineWidth: 0.5,
      // gridLineColor: '#ccc',
      // plotLines: [{
      //   value: 0.5,
      //   color: '#ccc',
      //   width: 1,
      //   zIndex: 5
      // }],
      max: 1,
      min: 0
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          'Date: ' +
          new Date(this.point.x).toISOString().split('T')[0] +
          '</b> <br>' +
          '<b> ' +
          'Value: ' +
          this.point.y +
          '<b/>'
        );
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        },
        marker: {
          enabled: true,
        },
        borderWidth: 0
      }
    },
    series: [{
      name: 'Green',
      data: greenSeries,
      color: "green"
    }, {
      name: 'Red',
      data: redSeries,
      color: "red"
    }]
  };
}

export const PercentileChartPlot = (percentiles, values, dark=true) => {
  const min = Math.min(...values)
  const max = Math.max(...values)
  const series = values.map((value, index)=> {
    return {
      value: parseFloat(value.toFixed(2)),
      percentile: percentiles[index],
    }
  })
  return {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
      events: {
        load: function() {
          var chart = this;
          chart.series[0].data.forEach(function(point) {
            point.graphic.attr({
              r: 2 // radius for rounded corners
            });
          });
        }
      }
    },
    title: {
      text: '',
      style: {
        color: dark ? '#ffffff' : '#111',
        fontWeight: 'bold',
        fontSize: '16px',
      },
    },
    colors: [
      "#186F65",
      "#187F75",
      "#B5CB99",
      "#B5DBA9",
      "#FCE0AB",
      "#FCE09B",
      "#B2533E"
    ],
    xAxis: {
      categories: ["Percentiles"],
      gridLineWidth: 0,
      title: {
        text: null
      },
      labels: {
        style: {
          color: dark ? '#ffffff' : '#111',
        },
      },
    },
    tooltip: {
      formatter: function() {
        return this.series.name + '<br/>' +
          'Porfolio Equity: ' + this.y;
      }
    },
    yAxis: {
      gridLineWidth: 0,
      tickInterval: 30,
      min: min - (min * 0.05),
      max: max + (max * 0.05),
      title: {
        text: 'Portfolio Equity',
        align: 'middle',
        style: {
          color: dark ? '#ffffff' : '#111',
          fontWeight: 'bold',
        },
      },
      labels: {
        overflow: 'justify',
        style: {
          color: dark ? '#ffffff' : '#111',
        },
      },
    },
    plotOptions: {
      bar: {
        
        dataLabels: {
          enabled: true,
          color: dark ? '#ffffff' : '#111',
          formatter: function() {
            return this.y.toFixed(2); // format the data labels to show only two decimal places
          }
        },
        borderWidth: 0, // remove borders
        pointPadding: 0,
        pointWidth: 20,
        groupPadding: 0
      },
    },
    legend: {
      itemStyle: {
        color: dark ? 'white' : 'black'
      }
    },
    series: series.map((x)=> {
      return {
        name: `${x.percentile}th Percentile`,
        data: [x.value],
      }
    })
  };
};