import React from 'react';
import './style.scss';
import {
  formatPrice,
  formatVolume,
  formatMarketCap,
} from '../../utils/formatPrice';

const CoinSummaryList = [
  { name: 'Open', isPercentage: false, amount: -20100 },
  { name: 'Low', isPercentage: false, amount: 192500 },
  { name: 'High', isPercentage: false, amount: -505000 },
  { name: 'Volume', isPercentage: false, amount: 25222 },
  { name: 'Market Cap', isPercentage: false, amount: 505000 },
  { name: 'Circulating Supply', isPercentage: false, amount: 505000 },
];

export const CoinSummary = ({ coinData, coinInfo }) => {
  return (
    <>
      {/* Version for larger screens */}
      <div className="hidden sm:flex w-full mt-5 justify-between">
        <div className="flex items-center gap-4">
          <img
            src={coinInfo.icon}
            alt="coin-icon"
            className="h-16 w-16 rounded-full"
          />
          <div>
            <h2 className="uppercase">{coinInfo.fullName}/TETHERUS</h2>
            <p className="flex items-center gap-2 text-gray-500 text-xs">
              <span className="uppercase">{coinInfo.symbol}</span>
            </p>
            <div className="ml-auto flex items-center gap-2">
              <h1 className="font-bold text-xl">
                {formatPrice(coinData.last_price, coinData.precision)}
              </h1>
              <p
                className={
                  coinData.price_change < 0
                    ? 'text-red-500 font-bold'
                    : 'text-green-500 font-bold '
                }
              >
                {`${coinData.price_change}%`}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-3 ml-5">
          {coinData?.cards?.map((item, index) => (
            <div
              key={index}
              className="card rounded-md px-2 flex flex-col p-2 h-20 justify-center uppercase text-center text-black dark:text-white bg-light-back_secondary dark:bg-dark-back_secondary"
            >
              <div className="coin-header-card__name text-sm  font-semibold text-black dark:text-white">
                {item.name}
              </div>
              <div
                className={`coin-header-card__value ${
                  item.name.includes('Daily')
                    ? coinData.price_change < 0
                      ? 'text-red-500 font-bold'
                      : 'text-green-500 font-bold'
                    : null
                }`}
              >
                {item.name === 'Volume'
                  ? formatVolume(item.value)
                  : item.name === 'Market Cap'
                  ? formatMarketCap(item.value)
                  : item.name === 'Circulating Supply'
                  ? formatMarketCap(item.value)
                  : item.value}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Version for smaller screens */}
      <div className="flex sm:hidden flex-wrap w-full m-5">
        <div className="flex items-center gap-4 w-full sm:w-auto">
          <img src={coinInfo.icon} alt="coin-icon" className="h-16 w-16" />
          <div>
            <h2 className="uppercase">{coinInfo.fullName}/TETHERUS</h2>
            <p className="flex items-center gap-2 text-gray-500 text-xs">
              <span className="uppercase">{coinInfo.symbol}</span>
            </p>
            <div className="ml-auto flex items-center gap-2">
              <h1 className="font-bold text-xl">
                {formatPrice(coinData.last_price, coinData.precision)}
              </h1>
              <span className="text-gray-500 text-sm">USDT</span>
              <p
                className={
                  coinData.price_change < 0
                    ? 'text-red-500 font-bold'
                    : 'text-green-500 font-bold'
                }
              >
                {`${coinData.price_change}%`}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-wrap mt-5 gap-2">
          {coinData?.cards?.map((item) => (
            <div
              key={item.name}
              className="card rounded-md px-4 flex flex-col p-2 w-full sm:w-auto h-20 justify-center uppercase text-center text-black dark:text-white bg-light-back_secondary dark:bg-dark-back_secondary"
            >
              <div className="coin-header-card__name text-sm font-semibold text-black dark:text-white">
                {item.name}
              </div>
              <div
                className={`coin-header-card__value ${
                  item.name.includes('Daily')
                    ? coinData.price_change < 0
                      ? 'text-red-500 font-bold'
                      : 'text-green-500 font-bold'
                    : null
                }`}
              >
                {item.name === 'Market Cap' ? item.value + ' T' : item.value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
