import React from 'react';
import { history } from '../../utils/history';

const ToggleSwitch = ({ value, onChange, className }) => {
  const { dispatch } = history;
  const toggleClasses = `w-10 h-5 rounded-full duration-300 ease-in-out relative ${value ? 'bg-dark-primary' : 'bg-gray-300'} ${className}`;

  const handleToggle = () => {
    dispatch(onChange(!value));
  };

  return (
    <label className={`inline-flex items-center rounded-full cursor-pointer ${value ? 'bg-dark-primary' : 'bg-gray-300'}`}>
      <div className={toggleClasses}>
        <div className={`w-5 h-5 bg-white rounded-full shadow-md transform duration-300 ease-in-out ${value ? 'translate-x-5' : 'translate-x-0'}`} />
      </div>
      <input
        type="checkbox"
        className="hidden"
        checked={value}
        onChange={handleToggle}
      />
    </label>
  );
};

export default ToggleSwitch;
