import { formatPythonTimestamp } from '../utils/formatTime';
import { formatPrice } from '../utils/formatPrice';


const price_class_transform = (x) => {
  return `${x[0].includes('Short') ? 'text-[#ef4444]' : 'text-[#21cc6d]'} justify-center items-center`;
};

const profit_class_transform = (x) => {
  return `${x < 0 ? 'text-[#ef4444]' : 'text-[#21cc6d]'} justify-center items-center`;
};

const profit_array_class_transform = (x) => {
  return `${x[0] < 0 ? 'text-[#ef4444]' : 'text-[#21cc6d]'} justify-center items-center`;
};

const sharpe_class_transform = (x) => {
  return 'font-extrabold justify-center items-center';
};

const double_row_transform = (x) => (
  <div className="flex-row p-1">
    <div>{x[0]}</div> <div>{x[1]}</div>
  </div>
);
const double_row_percent_transform = (x) => (
  <div className="flex-row p-1">
    <div className='font-bold'>{x[0]}</div> <div className='text-sm'>{x[1]}</div>
  </div>
);

const symbol_transform = (x) => {
  return (
    <div className="flex gap-2 items-end">
      <img
        className="rounded-full"
        width={25}
        height={25}
        alt={x.symbol}
        src={x.icon}
      />
      <p className="text-black dark:text-white font-normal">{x.symbol}</p>
    </div>
  );
};

export const positions_to_list = (positions) => {
    return positions.map((position, i) => {
        let position_type = position.side === 1 ? 'Long' : 'Short';
        return [
            i + 1,
            [`Exit ${position_type}`, `Entry ${position_type}`],
            [`${position?.exit_timestamp ? formatPythonTimestamp(position.exit_timestamp) : '-'}`, `${formatPythonTimestamp(position.entry_timestamp)}`],
            [`${position?.exit_price ? formatPrice(position?.exit_price): '-'}`, `${formatPrice(position?.entry_price)}`],
            position.margin,
            position.quantity,
            [position.profit, `${position.profit_percentage}%`],
            [position.run_up, `${position.run_up_percentage}%`],
            [position.draw_down, `${position.draw_down_percentage}%`]
        ]
    })
}

export const orders_to_list = (orders) => {
  return orders.map((order, i) => {
      return [
          i + 1,
          order.side,
          order.timestamp,
          order.type,
          order.status,
          order.comment,
          order.price,
          order.stop_price,
          order.filled_price
      ]
  })
}

export const timeframe_combs_to_list = (timeframe_combs) => {
  return timeframe_combs.map((timeframe_comb) => {
      return [
        timeframe_comb.symbol_with_icon,
        timeframe_comb.lt_readable,
        timeframe_comb.ft_readable,
        timeframe_comb.dlm_readable,
        timeframe_comb.strategy_readable,
        timeframe_comb.net_profit,
        timeframe_comb.sharpe_ratio,
        timeframe_comb.buy_and_hold,
        timeframe_comb.start_timestamp,
        timeframe_comb.end_timestamp
      ]
  })
}

export const symbol_combs_to_list = (symbol_combs) => {
  return symbol_combs.map((symbol_comb) => {
      return [
        symbol_comb.timeframe_readable,
        symbol_comb.lt_readable,
        symbol_comb.ft_readable,
        symbol_comb.dlm_readable,
        symbol_comb.strategy_readable,
        symbol_comb.net_profit,
        symbol_comb.sharpe_ratio,
        symbol_comb.buy_and_hold,
        symbol_comb.start_timestamp,
        symbol_comb.end_timestamp
      ]
  })
}

export const combo_combs_to_list = (combs) => {
  return combs.map((comb) => {
      return [
        comb.symbol_with_icon,
        comb.timeframe_readable,
        comb.net_profit,
        comb.sharpe_ratio,
        comb.buy_and_hold,
        comb.start_timestamp,
        comb.end_timestamp,
      ]
  })
}

export const summary_metrics_map = (metrics, all, long, short) => {
  return metrics.map((metric) => {
    const id = metric.id;
    return {
      all: all[id],
      long: long[id],
      short: short[id],
      ...metric
    }
  })
}

export const position_columns = [
  { name: 'Trade #', width: 1 },
  {
    name: 'Type',
    isBold: false,
    transformFn: double_row_transform,
    classNameFn: price_class_transform,
    width: 2,
  },
  { name: 'Date/Time', transformFn: double_row_transform, width: 3 },
  { name: 'Price', transformFn: double_row_transform, width: 2 },
  { name: 'Margin', width: 2 },
  { name: 'Quantity', width: 2 },
  { name: 'Profit', width: 2, transformFn: double_row_percent_transform, classNameFn: profit_array_class_transform},
  { name: 'Runup', width: 2, transformFn: double_row_percent_transform, classNameFn: profit_array_class_transform },
  { name: 'Drawdown', width: 2, transformFn: double_row_percent_transform, classNameFn: profit_array_class_transform },
];

export const order_columns = [
  { name: 'Trade #', width: 1 },
  { name: 'Side', transformFn: (x) => x === 1 ? 'Long' : 'Short', classNameFn: (x)=> `${x === 1 ? 'text-[#21cc6d]' : 'text-[#ef4444]'} justify-center items-center`, width: 1},
  { name: 'Date/Time', transformFn: (x) => formatPythonTimestamp(x), width: 3},
  { name: 'Type', width: 1 , transformFn: (x) => x === 0 ? 'Market' : x === 1 ? 'Limit' : x === 2 ? 'Stop Market' : x === 3 ? 'Stop Limit' : x},
  { name: 'Status', width: 1, transformFn: (x) => x === 1 ? 'Open' : x === 2 ? 'Partially Filled' : x === 4 ? 'Filled' : x === 8 ? 'Cancled' : x},
  { name: 'Comment', width: 1 },
  { name: 'Price', width: 1, transformFn: (x) => formatPrice(x) },
  { name: 'Stop Price', width: 1, transformFn: (x) => formatPrice(x) },
  { name: 'Filled Price', width: 1, transformFn: (x) => formatPrice(x) },
]

export const combination_for_timeframe_columns = [
  { name: 'Symbol', transformFn: symbol_transform},
  { name: 'Label', width: 1 },
  { name: 'Feature' },
  { name: 'Model', width: 1 },
  { name: 'Strategy', width: 1 },
  { name: 'Profit', classNameFn: profit_class_transform },
  { name: 'Sharpe', classNameFn: sharpe_class_transform },
  { name: 'B&H', classNameFn: profit_class_transform },
]

export const combination_for_symbol_columns = [
  { name: 'Time Frame' },
  { name: 'Label' },
  { name: 'Feature' },
  { name: 'Model' },
  { name: 'Strategy' },
  { name: 'Net Profit', classNameFn: profit_class_transform, width: 2},
  { name: 'Sharpe Ratio', classNameFn: sharpe_class_transform, width: 2 },
  { name: 'Buy & Hold', classNameFn: profit_class_transform, width: 2 },
]

export const ts_for_combination_columns =  [
  { name: 'Symbol', transformFn: symbol_transform },
  { name: 'Time Frame' },
  { name: 'Profit', classNameFn: profit_class_transform },
  { name: 'Sharpe', classNameFn: sharpe_class_transform },
  { name: 'B&H', classNameFn: profit_class_transform },
]

export const summary_metrics = [
  {
    name: 'Net Profit',
    id: 'net_profit'
  },
  {
    name: 'Gross Profit',
    id: 'gross_profit'
  },
  {
    name: 'Gross Loss',
    id: 'gross_loss'
  },
  {
    name: 'Max Run-up',
    id: 'maximum_run_up'
  },
  {
    name: 'Max Drawdown',
    id: 'maximum_draw_down'
  },
  {
    name: 'Buy & Hold Return',
    id: 'buy_and_hold'
  },
  {
    name: 'Sharpe Ratio',
    id: 'sharpe_ratio'
  },
  {
    name: 'Sortino Ratio',
    id: 'sortino_ratio'
  },
  {
    name: 'Profit Factor',
    id: 'profit_factor'
  },
  {
    name: 'Commission Fee',
    id: 'commission_paid'
  },
  {
    name: 'Total Trades',
    id: 'number_of_total_trades'
  },
  // {
  //   name: 'Total Open Trades',
  //   id: ''
  // },
  {
    name: 'No. of Winning Trades',
    id: 'number_of_winning_trades'
  },
  {
    name: 'No. of Losing Trades',
    id: 'number_of_losing_trades'
  },
  // {
  //   name: 'Percent Profitable',
  //   id: ''
  // },
  {
    name: 'Avg. Trade',
    id: 'average_trades'
  },
  {
    name: 'Avg. Winning Trade',
    id: 'average_winning_trades'
  },
  {
    name: 'Avg. Losing Trade',
    id: 'average_losing_trades'
  },
  {
    name: 'Avg. Win per Avg. Loss',
    id: 'ratio_average_win_to_average_loss'
  },
  {
    name: 'Largest Winning Trade',
    id: 'largest_winning_trade'
  },
  {
    name: 'Largest Losing Trade',
    id: 'largest_losing_trade'
  },
  {
    name: 'Avg. No. of Bars in Trades',
    id: 'average_bars_in_trade'
  },
  {
    name: 'Avg. No. of Bars in Winning ',
    id: 'average_bars_in_winning_trade'
  },
  {
    name: 'Avg. No. of Bars in Losing Trades',
    id: 'average_bars_in_losing_trade'
  },
];

