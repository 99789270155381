import { React } from 'react';
import SideBar from '../SideBar';
import './style.scss';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer'

function DashboardLayout({ children }) {
  const theme = useSelector((state) => state.overall.theme)
  return (
    <div className={`dashboard-layout ${theme ? 'dark': ''}`}>
      <SideBar />
      <main className="dashboard-layout__main bg-light-back dark:bg-black text-black dark:text-white">
        <div className="dashboard-layout__main-wrapper">{children}</div>
      <Footer />
      </main>
    </div>
  );
}

export default DashboardLayout;
