import { INTERVALS } from './consts';
import { generateAreaChart } from '../utils/d3_maker';
import { formatPrice } from '../utils/formatPrice';


export const signalsToList = (signals) => {
  return signals.map((signal) => {
      return [
        signal.human_readable_time,
        signal.symbol_with_icon,
        signal.timeframe,
        signal.side,
        signal.lt,
        signal.ft,
        signal.dlm,
        signal.strategy,
        signal.entry_price,
        signal.price_chart
      ]
  })
}

// UI logic functions
const symbol_transform = (x) => {
  return (
    <div className="flex gap-2 items-end">
      <img
        className="rounded-full"
        width={25}
        height={25}
        alt={x.symbol}
        src={x.icon}
      />
      <p className="text-black dark:text-white font-normal">{x.symbol}</p>
    </div>
  );
};

const type_transform = (x) => {
  return x === 1 ? 'Long' : x === -1 ? 'Short' : '-';
};

const type_class_generator = (x) => {
  return x == -1 ? 'text-[#ef4444] justify-center items-center' :'text-[#21cc6d] justify-center items-center';
};

const price_chart_transform = (x) => {
  let diff = x[0] - x[x.length -1];
  let color = diff < 0 ? 'green' : 'red';
  let chart = generateAreaChart(x, color, Math.random().toString(36).substring(2))
  return chart;
};

const timeframe_transform = (x) => {
  const interval = INTERVALS.find((interval) => interval.value === x);
  return interval ? interval.label : x;
};

const price_transform = (x) => {
  return formatPrice(x);
}

export const columns = [
  { name: 'Date/Time', width: 2 },
  { name: 'Symbol', transformFn: symbol_transform },
  { name: 'Time Frame', transformFn: timeframe_transform },
  {
    name: 'Position',
    transformFn: type_transform,
    classNameFn: type_class_generator,
  },
  { name: 'Label'},
  { name: 'Feature' },
  { name: 'Model' },
  { name: 'Strategy' },
  { name: 'Entry Price' , transformFn: price_transform},
  { name: 'Price', width: 3, transformFn: price_chart_transform },
];
