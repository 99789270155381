export function formatPrice(num, fixed = 2) {
  const number = num  > 1 ? num.toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num.toFixed(fixed);
  return number
}

export function formatVolume(num, fixed = 2) {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let i = 0;
  while (num >= 1000 && i < suffixes.length - 1) {
    num /= 1000;
    i++;
  }
  const formattedVolume = num.toFixed(fixed) + suffixes[i];
  return formattedVolume;
}

export const formatMarketCap = (number_in_trillion) => {
  const trillion = 1000000000000;
  const billion = 1000000000;
  const million = 1000000;

  const number = number_in_trillion * trillion;

  if (number >= trillion) {
    return (number / trillion).toFixed(2) + 'T';
  } else if (number >= billion) {
    return (number / billion).toFixed(2) + 'B';
  } else if (number >= million) {
    return (number / million).toFixed(2) + 'M';
  }

  return number.toString();
};


export function humanReadableNumber(number) {
  if (number >= 1) {
    // Handle large numbers
    if (number < 1000) {
      return number.toFixed(1);
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
  } else {
    // Handle small numbers
    if (number >= 0.001) {
      return (number * 1000).toFixed(1) + "m";
    } else if (number >= 0.000001) {
      return (number * 1_000_000).toFixed(1) + "μ";
    } else if (number >= 0.000000001) {
      return (number * 1_000_000_000).toFixed(1) + "n";
    } else if (number >= 0.000000000001) {
      return (number * 1_000_000_000_000).toFixed(1) + "p";
    }
  }
}


export function formatFundingRate(value) {
  // Convert the value to a number
  const rate = Number(value);

  // Check if the value is negative
  const isNegative = rate < 0;

  // Get the absolute value of the rate
  const absoluteRate = Math.abs(rate);

  // Format the rate with variable decimal places
  const formattedRate = absoluteRate.toFixed(8).replace(/\.?0+$/, '');

  // Add a '+' sign for positive rates (optional)
  const sign = isNegative ? '-' : '+';

  // Return the formatted rate with the sign
  return sign + formattedRate;
}
