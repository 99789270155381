import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import { heatmap_options } from '../../Constants/heatmap';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic';
import './style.scss';
import { QuickScanOption } from '../../Constants/quickScanOptions';
import cx from 'classnames';
import Chart from '../../components/Chart/chart';
import { formatPrice, formatVolume } from '../../utils/formatPrice';
import Title from '../../components/Title';

import {
  getHeatmap,
  getCards,
  set_cards_timeframe,
  set_heatmap_timeframe
} from './slice';
import { useSelector } from 'react-redux';
import { history } from '../../utils/history';
import candleLoading from '../../assets/images/candleLoading.GIF';
import { TbChartLine } from 'react-icons/tb';

export const CoinItem = ({ coin, activeCoin, setActiveCoin }) => {
  return (
    <div
      onClick={() => setActiveCoin(coin.coinName)}
      className={cx('flex items-center gap-1 cursor-pointer  rounded-3xl p-1', {
        'bg-indigo-600': coin.coinName === activeCoin,
        'bg-slate-700': coin.coinName !== activeCoin,
      })}
    >
      <img width={22} height={22} alt="" src={coin.coinImg} />
      <p>{coin.coinName}</p>
    </div>
  );
};

export const RapidPage = () => {
  const { dispatch } = history;
  const theme = useSelector((state) => state.overall.theme);
  const selector = useSelector((state) => state.rapid);
  const timeframes = selector.timeframes;
  const selected_heatmap_timeframe = selector.selected_heatmap_timeframe;
  const selected_cards_timeframe = selector.selected_cards_timeframe;
  const get_feature_transforms_status = selector.get_feature_transforms_status;
  const get_label_transforms_status = selector.get_label_transforms_status;
  const get_models_status = selector.get_models_status;
  const get_heatmap_status = selector.get_heatmap_status;
  const get_heatmap_results = selector.get_heatmap_results;
  const get_cards_status = selector.get_cards_status;
  const get_cards_results = selector.get_cards_results;

  useEffect(() => {
    const params = {
      timeframe: selected_heatmap_timeframe.value
    };
    dispatch(getHeatmap(params));
    dispatch(getCards(params));
  }, [
    get_feature_transforms_status,
    get_label_transforms_status,
    get_models_status,
  ])

  useEffect(() => {
    const params = {
      timeframe: selected_heatmap_timeframe.value
    };
    dispatch(getHeatmap(params));
  }, [
    selected_heatmap_timeframe
  ])

  useEffect(() => {
    const params = {
      timeframe: selected_cards_timeframe.value
    };
    dispatch(getCards(params));
  }, [
    selected_cards_timeframe
  ])

  return (
    <DashboardLayout>
      <div className="flex flex-col justify-center  m-5">
        <div className="flex w-full ">
          <Title
            title="Rapid Market Scan"
            subtitle="Unlock Trading Potential using Real-Time Predictive Heatmaps and Progress Bars for Informed Decision-Making"
            isPageTitle
          />
        </div>
        <div className='flex flex-col justify-between bg-light-back_secondary dark:bg-dark-back_secondary p-5 rounded-md mt-5'>
          <div className='flex justify-between'>
            <Title
              title={`Heatmap Visualization of Probability Sums`}
              subtitle={`Summerized Probabilities Across Different Labelling Methods and Feature Sets`}
            />
            <ButtonSelectDynamic
              dark={theme}
              isRequired
              label="Time Frame"
              selectedOptions={selected_heatmap_timeframe}
              options={timeframes}
              className="select-analys-type"
              onChange={(item) => dispatch(set_heatmap_timeframe(item))}
            />
          </div>
          {get_heatmap_status === 'fulfilled' ? (
            <div>
              {
                [...Object.keys(get_heatmap_results)].map((item, i) => (
                  <div key={i} className="setting bg-light-back dark:bg-dark-back p-5 rounded-md mt-5">
                    <div className='flex w-full justify-between mt-2'>
                      <Title
                        title={`${get_heatmap_results[item]?.labeling} Prediction Heatmap`}
                        subtitle={get_heatmap_results[item]?.description}
                      />
                    </div>
                    <div className='mt-2'>
                    <Chart Options={heatmap_options(get_heatmap_results[item]?.x, get_heatmap_results[item]?.y, get_heatmap_results[item]?.ratio?.slice(0, 60), theme)} />
                    </div>
                  </div>
                ))
              }
            </div>
          ) : (
            <div
              className="flex justify-center items-center bg-light-back_secondary dark:bg-dark-back_secondary p-5 rounded-md mt-5 animate-pulse"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <TbChartLine
                style={{ color: 'gray', height: '40%', width: '30%' }}
              />
            </div>
          )}
        </div>
        {/* </div> */}
        <div className="flex flex-col justify-center w-full text-black dark:text-white">
          <div className='flex flex-col justify-between bg-light-back_secondary dark:bg-dark-back_secondary p-5 rounded-md mt-5'>
            <div className='flex justify-between'>
              <Title
                title={`Trading Insights by Coin Cards`}
                subtitle={`Number of Buy and Sell Recommendations from Different Prediction Model Combinations`}
              />
              <ButtonSelectDynamic
                dark={theme}
                isRequired
                label="Time Frame"
                selectedOptions={selected_cards_timeframe}
                options={timeframes}
                className="select-analys-type"
                onChange={(item) => dispatch(set_cards_timeframe(item))}
              />
            </div>  
            <div className="grid grid-cols-1 text-white sm:grid-cols-3 gap-9 mt-4">
              {get_cards_status === 'fulfilled' ? (
                // <div className='flex flex-wrap'>
                get_cards_results.map((item, i) => (
                  <div key={i} className="card rounded-md bg-light-back dark:bg-dark-back text-black dark:text-white p-4">
                    <div className="flex justify-around my-2">
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">SYMBOL</p>
                        <div className="flex gap-2 items-center justify-center text-center">
                          <img className='rounded-full' width={30} height={30} alt="" src={item.icon} />
                          <span className='flex flex-wrap items-center justify-center text-center'>{item.symbol}</span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">PRICE</p>
                        <p className='flex items-center justify-center text-center'>{formatPrice(item.last_price)}</p>
                      </div>
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">DAILY VOLUME</p>
                        <p className='flex items-center justify-center text-center'>{formatVolume(item.daily_volume)}</p>
                      </div>
                    </div>
                    <Chart Options={QuickScanOption(item.signal_chart.labelings, item.signal_chart.longs, item.signal_chart.shorts, theme)} />
                    <div className="flex justify-around my-2">
                      {/* <div className="flex flex-col gap-2">
                      <p className="text-xs text-gray-500">MARKET CAP</p>
                      <p>{formatMarketCap(item.market_cap)}</p>
                    </div> */}
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">15Min%</p>
                        <p
                          className={cx({
                            'text-[#ef4444]': item.quarterly_change < 0,
                            'text-[#22c55e]': item.quarterly_change > 0,
                          })}
                        >
                          {item.quarterly_change.toFixed(2)}%
                        </p>
                      </div>
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">1H%</p>
                        <p
                          className={cx({
                            'text-[#ef4444]': item.hourly_change < 0,
                            'text-[#22c55e]': item.hourly_change > 0,
                          })}
                        >
                          {item.hourly_change.toFixed(2)}%
                        </p>
                      </div>
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">1D%</p>
                        <p
                          className={cx({
                            'text-[#ef4444]': item.daily_change < 0,
                            'text-[#22c55e]': item.daily_change > 0,
                          })}
                        >
                          {item.daily_change.toFixed(2)}%
                        </p>
                      </div>
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <p className="text-xs text-gray-500">1W%</p>
                        <p
                          className={cx({
                            'text-[#ef4444]': item.weekly_change < 0,
                            'text-[#22c55e]': item.weekly_change > 0,
                          })}
                        >
                          {item.weekly_change.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                ))
                // </div>
              ) : (
                <>
                  {Array(8)
                    .fill(0)
                    .map((item, i) => (
                      <div key={i} className="card p-3 rounded-md bg-light-back_secondary dark:bg-dark-back_secondary">
                        <div className="flex justify-around my-2">
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                        </div>
                        <div
                          className="animate-pulse"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <TbChartLine
                            style={{
                              color: 'gray',
                              height: '30%',
                              width: '70%',
                            }}
                          />
                        </div>
                        <div className="flex justify-around my-2">
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                          <div className="flex flex-col gap-2 animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-9 mb-4"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
