import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../components/DashboardLayout';
import Title from '../../components/Title';
import ButtonSelectDynamic from '../../components/ButtonSelectDynamic';
import BottomInput from '../../components/BottomInput';
import { backtest_main_chart } from '../../Constants/backTest';
import CalendarInput from '../../components/CalendarInput';
import {
  HiChevronDoubleUp,
  HiChevronDoubleDown,
} from 'react-icons/hi';
import PriceChart from '../../components/PriceChart';
import { Table } from '../../components/Table';
import { history } from '../../utils/history';
import { formatPythonTimestamp } from '../../utils/formatTime';
import {
  recent_predictions_columns,
  recommended_symbols_timeframes_columns,
  timeframe_recommendation_columns,
  symbol_recommendation_columns
} from '../../Constants/inDepth';
import { TbChartLine } from 'react-icons/tb';
import moment from 'moment';

// Import actions and thunks
import {
  getCoins,
  getFeatureTransforms,
  getLabelTransforms,
  getModels,
  getOverallMetrics,
  getPredictionChart,
  getRecentPredictions,
  getRecommendedSymbolTimeframe,
  getRecommendedPredictorsForSymbol,
  getRecommendedPredictorsForTimeframe,
  set_coin,
  set_timeframe,
  set_start_date,
  set_end_date,
  set_labeling_method,
  set_learning_model,
  set_feature_set,
  set_recent_predictions_current_page,
  set_recommender_symbol_timeframe_page,
  set_recommender_symbol_page,
  set_recommender_timeframe_page,
} from './slice';
import { INTERVALS } from '../../Constants/consts';
import { areAllVariablesPresent } from '../../utils/validators';

export const InDepthPage = () => {
  const { dispatch } = history;
  const topRef = useRef(null);
  // Retrieve States
  const theme = useSelector((state) => state.overall.theme);
  const selector = useSelector((state) => state.inDepth);
  const get_coins_status = selector.get_coins_status;
  const get_coins_results = selector.get_coins_results;
  const selected_coin = selector.selected_coin;
  const timeframes = selector.timeframes;
  const selected_timeframe = selector.selected_timeframe;
  const start_date = selector.start_date;
  const end_date = selector.end_date;
  const selected_labeling_method = selector.selected_labeling_method;
  const selected_feature_set = selector.selected_feature_set;
  const selected_learning_model = selector.selected_learning_model;
  const labeling_method_options = selector.labeling_method_options;
  const feature_set_options = selector.feature_set_options;
  const learning_model_options = selector.learning_model_options;
  const get_prediction_chart_status = selector.get_prediction_chart_status;
  const get_prediction_chart_results = selector.get_prediction_chart_results;
  const get_feature_transforms_status = selector.get_feature_transforms_status;
  const get_label_transforms_status = selector.get_label_transforms_status;
  const get_models_status = selector.get_models_status;
  const get_overall_metrics_results = selector.get_overall_metrics_results;
  const get_overall_metrics_status = selector.get_overall_metrics_status;
  const get_recent_predictions_results =
    selector.get_recent_predictions_results;
  const get_recent_predictions_page = selector.get_recent_predictions_page;
  const get_recent_predictions_status = selector.get_recent_predictions_status;
  const get_recommender_symbol_timeframe_status =
    selector.get_recommender_symbol_timeframe_status;
  const get_recommender_symbol_timeframe_page =
    selector.get_recommender_symbol_timeframe_page;
  const get_recommender_symbol_timeframe_results =
    selector.get_recommender_symbol_timeframe_results;
  const get_recommender_symbol_results =
    selector.get_recommender_symbol_results;
  const get_recommender_symbol_page = selector.get_recommender_symbol_page;
  const get_recommender_symbol_status = selector.get_recommender_symbol_status;
  const get_recommender_timeframe_results =
    selector.get_recommender_timeframe_results;
  const get_recommender_timeframe_page =
    selector.get_recommender_timeframe_page;
  const get_recommender_timeframe_status =
    selector.get_recommender_timeframe_status;

  // Effects
  useEffect(() => {
    dispatch(getCoins());
    dispatch(getFeatureTransforms());
    dispatch(getLabelTransforms());
    dispatch(getModels());
  }, []);

  useEffect(() => {
    if (
      (get_coins_status == 'fulfilled',
      get_feature_transforms_status == 'fulfilled',
      get_label_transforms_status == 'fulfilled',
      get_models_status == 'fulfilled')
    ) {
      const params = {
        symbol_id: selected_coin.value,
        timeframe: selected_timeframe.value,
        labeling_method_id: selected_labeling_method.value,
        feature_set_id: selected_feature_set.value,
        learning_model_id: selected_learning_model.value,
        start_date: new Date(start_date * 1000).toISOString(),
        end_date: new Date(end_date * 1000).toISOString(),
      };
      dispatch(getOverallMetrics(params));
      dispatch(getPredictionChart(params));
      dispatch(getRecentPredictions(params));
      dispatch(getRecommendedSymbolTimeframe(params));
      dispatch(getRecommendedPredictorsForSymbol(params));
      dispatch(getRecommendedPredictorsForTimeframe(params));

      // Reseting pages
      dispatch(set_recent_predictions_current_page(1));
      dispatch(set_recommender_symbol_page(1));
      dispatch(set_recommender_symbol_timeframe_page(1));
      dispatch(set_recommender_timeframe_page(1));
    }
  }, [
    selected_coin,
    selected_timeframe,
    selected_labeling_method,
    selected_feature_set,
    selected_learning_model,
    start_date,
    end_date,
    get_coins_status,
    get_feature_transforms_status,
    get_label_transforms_status,
    get_models_status,
  ]);

  useEffect(() => {
    const params = {
      symbol_id: selected_coin.value,
      timeframe: selected_timeframe.value,
      labeling_method_id: selected_labeling_method.value,
      feature_set_id: selected_feature_set.value,
      learning_model_id: selected_learning_model.value,
      start_date: new Date(start_date * 1000).toISOString(),
      end_date: new Date(end_date * 1000).toISOString(),
      page: get_recent_predictions_page,
    };
    if (params) {
      dispatch(getRecentPredictions(params));
    }
  }, [get_recent_predictions_page]);

  useEffect(() => {
    const params = {
      symbol_id: selected_coin.value,
      timeframe: selected_timeframe.value,
      labeling_method_id: selected_labeling_method.value,
      feature_set_id: selected_feature_set.value,
      learning_model_id: selected_learning_model.value,
      start_date: new Date(start_date * 1000).toISOString(),
      end_date: new Date(end_date * 1000).toISOString(),
      page: get_recommender_symbol_timeframe_page,
    };
    if (params) {
      dispatch(getRecommendedSymbolTimeframe(params));
    }
  }, [get_recommender_symbol_timeframe_page]);

  useEffect(() => {
    const params = {
      symbol_id: selected_coin.value,
      timeframe: selected_timeframe.value,
      labeling_method_id: selected_labeling_method.value,
      feature_set_id: selected_feature_set.value,
      learning_model_id: selected_learning_model.value,
      start_date: new Date(start_date * 1000).toISOString(),
      end_date: new Date(end_date * 1000).toISOString(),
      page: get_recommender_symbol_page,
    };
    if (params) {
      dispatch(getRecommendedPredictorsForSymbol(params));
    }
  }, [get_recommender_symbol_page]);

  useEffect(() => {
    const params = {
      symbol_id: selected_coin.value,
      timeframe: selected_timeframe.value,
      labeling_method_id: selected_labeling_method.value,
      feature_set_id: selected_feature_set.value,
      learning_model_id: selected_learning_model.value,
      start_date: new Date(start_date * 1000).toISOString(),
      end_date: new Date(end_date * 1000).toISOString(),
      page: get_recommender_timeframe_page,
    };
    if (params) {
      dispatch(getRecommendedPredictorsForTimeframe(params));
    }
  }, [get_recommender_timeframe_page]);

  // local states
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const combo_recommendation_clicked = (row) => {
    const symbol = get_coins_results?.find((x)=> x.value == row[0].value);
    const timeframe = INTERVALS.find((x)=> x.value == row[1]);
    const start_timestamp = row[3];
    const end_timestamp = row[4];
    if (areAllVariablesPresent([timeframe, symbol, end_timestamp, start_timestamp])) {
      dispatch(set_coin(symbol));
      dispatch(set_timeframe(timeframe));
      dispatch(set_start_date(parseInt(start_timestamp)));
      dispatch(set_end_date(parseInt(end_timestamp)));
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const timeframe_recommendation_clicked = (row) => {
      const symbol = get_coins_results?.find((x)=> x.value == row[0].value);
      const lt = labeling_method_options?.find((x) => x.label == row[1]);
      const ft = feature_set_options?.find((x)=> x.label == row[2]);
      const dlm = learning_model_options.find((x)=> x.label == row[3]);
      const start_timestamp = row[7];
      const end_timestamp = row[8];
      if (areAllVariablesPresent([symbol, lt, ft, dlm, start_timestamp, end_timestamp])) {
        dispatch(set_coin(symbol));
        dispatch(set_labeling_method(lt));
        dispatch(set_feature_set(ft));
        dispatch(set_learning_model(dlm));
        dispatch(set_start_date(start_timestamp));
        dispatch(set_end_date(end_timestamp));
        topRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  }

  const symbol_recommended_clicked = (row) => {
    const timeframe = INTERVALS.find((x)=> x.value == row[0]);
    const lt = labeling_method_options?.find((x) => x.label == row[1]);
    const ft = feature_set_options?.find((x)=> x.label == row[2]);
    const dlm = learning_model_options.find((x)=> x.label == row[3]);
    const start_timestamp = row[7];
    const end_timestamp = row[8];
    if (areAllVariablesPresent([timeframe, lt, ft, dlm])) {
      dispatch(set_timeframe(timeframe));
      dispatch(set_labeling_method(lt));
      dispatch(set_feature_set(ft));
      dispatch(set_learning_model(dlm));
      dispatch(set_start_date(start_timestamp));
      dispatch(set_end_date(end_timestamp));
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col justify-center m-5" ref={topRef}>
        <div className="flex w-full ">
          <Title
            title="In-Depth Insight"
            subtitle="Comprehensive Analysis with Advanced Prediction Methods Represented in the Form of Plots and Tables for Informed Decision-Making"
            isPageTitle
          />
        </div>
        <div className="first-div text-black dark:text-white w-full my-4 p-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md">
          <Title
            title="In-Depth Insight Configuration"
            subtitle="Explore Finbrgiths Prediction Models and Their Results"
          />
          <div className="first-row-select flex-col model-config">
            <div className="flex justify-center gap-[6rem]">
              {get_coins_status == 'fulfilled' ? (
                <ButtonSelectDynamic
                  dark={theme}
                  isRequired
                  label="Symbol"
                  selectedOptions={selected_coin}
                  options={get_coins_results}
                  className="select-analys-type"
                  onChange={(item) => dispatch(set_coin(item))}
                />
              ) : null}
              <ButtonSelectDynamic
                dark={theme}
                isRequired
                label="Time Frame"
                selectedOptions={selected_timeframe}
                options={timeframes}
                className="select-analys-type"
                onChange={(item) => dispatch(set_timeframe(item))}
              />
              <CalendarInput
                label="Start Date"
                placeholder="Start Date"
                show={showStartDatePicker}
                setShow={(value) => {
                  setShowStartDatePicker(value);
                }}
                value={new Date(start_date * 1000)}
                onFocus={(date) => {
                  dispatch(
                    set_start_date(Math.floor(new Date(date).getTime() / 1000))
                  );
                  setShowStartDatePicker(false);
                }}
                onChangeFunc={(dateTime) =>
                  dispatch(set_start_date(Math.floor(dateTime / 1000)))
                }
                minDate={'2021/01/01'}
                maxDate={moment(new Date()).format('YYYY/MM/DD')}
                dark={theme}
              />
              <CalendarInput
                label="End Date"
                placeholder="End Date"
                show={showEndDatePicker}
                setShow={(value) => {
                  setShowEndDatePicker(value);
                }}
                value={new Date(end_date * 1000)}
                onFocus={(date) => {
                  dispatch(
                    set_end_date(Math.floor(new Date(date).getTime() / 1000))
                  );
                  setShowEndDatePicker(false);
                }}
                onChangeFunc={(dateTime) =>
                  dispatch(set_start_date(Math.floor(dateTime / 1000)))
                }
                minDate={'2021/01/01'}
                maxDate={moment(new Date()).format('YYYY/MM/DD')}
                dark={theme}
              />
            </div>
            <div className="flex justify-center gap-[6rem]">
              <ButtonSelectDynamic
                dark={theme}
                isRequired
                label="Labeling Method"
                selectedOptions={selected_labeling_method}
                options={labeling_method_options}
                className="select-analys-type"
                onChange={(item) => dispatch(set_labeling_method(item))}
              />
              <ButtonSelectDynamic
                dark={theme}
                isRequired
                label="Feature Set"
                selectedOptions={selected_feature_set}
                options={feature_set_options}
                className="select-analys-type"
                onChange={(item) => dispatch(set_feature_set(item))}
              />
              <ButtonSelectDynamic
                dark={theme}
                isRequired
                label="Learning Model"
                selectedOptions={selected_learning_model}
                options={learning_model_options}
                className="select-analys-type"
                onChange={(item) => dispatch(set_learning_model(item))}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-between bg-light-back_secondary dark:bg-dark-back_secondary p-5 rounded-md mb-5">
            <Title
              title="Performance Measures Overview"
              subtitle="Assessing Accuracy and Efficiency: Comprehensive Performance Metrics for the Specified Configuration"
              className={'mb-2'}
            />

            {get_feature_transforms_status == 'fulfilled' &&
            get_label_transforms_status == 'fulfilled' &&
            get_models_status == 'fulfilled' &&
            get_overall_metrics_status == 'fulfilled' ? (
              <div className="grid grid-cols-4 gap-8 w-full">
                <div className="flex flex-col justify-between bg-light-back dark:bg-dark-back p-5 rounded-md col-span-1">
                  <div className="flex w-full justify-center items-center mb-2">
                    <p className="font-bold">Overall</p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <p>Accuracy</p>
                    <p>
                      {/* {get_overall_metrics_results.accuracy}% -{' '} */}
                      {get_overall_metrics_results.probability_accuracy}%
                    </p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <p>Number of Predictions</p>
                    <p>{get_overall_metrics_results.total_predictions}</p>
                  </div>
                </div>
                <div className="flex flex-col justify-between bg-light-back dark:bg-dark-back p-5 rounded-md col-span-1">
                  <div className="flex w-full justify-center items-center mb-2">
                    <p className="font-bold">Precision</p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <div>
                        <HiChevronDoubleUp color="#21cc6d" />
                      </div>
                      <p className="pl-2">Up</p>
                    </div>
                    <p>
                      {/* {get_overall_metrics_results.precision_up}% -{' '} */}
                      {get_overall_metrics_results.probability_precision_up}%
                    </p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <div>
                        <HiChevronDoubleDown color="#ee4657" />{' '}
                      </div>
                      <p className="pl-2">Down</p>
                    </div>
                    <p>
                      {/* {get_overall_metrics_results.precision_down}% -{' '} */}
                      {get_overall_metrics_results.probability_precision_down}%
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-between bg-light-back dark:bg-dark-back p-5 rounded-md col-span-1">
                  <div className="flex w-full justify-center items-center mb-2">
                    <p className="font-bold">Recall</p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <div>
                        <HiChevronDoubleUp color="#21cc6d" />
                      </div>
                      <p className="pl-2">Up</p>
                    </div>
                    <p>
                      {/* {get_overall_metrics_results.recall_up}% -{' '} */}
                      {get_overall_metrics_results.probability_recall_up}%
                    </p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <div>
                        <HiChevronDoubleDown color="#ee4657" />{' '}
                      </div>
                      <p className="pl-2">Down</p>
                    </div>
                    <p>
                      {/* {get_overall_metrics_results.recall_down}% -{' '} */}
                      {get_overall_metrics_results.probability_recall_down}%
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-between bg-light-back dark:bg-dark-back p-5 rounded-md col-span-1">
                  <div className="flex w-full justify-center items-center mb-2">
                    <p className="font-bold">F1 Measure</p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <div>
                        <HiChevronDoubleUp color="#21cc6d" />
                      </div>
                      <p className="pl-2">Up</p>
                    </div>
                    <p>
                      {/* {get_overall_metrics_results.f1_up}% -{' '} */}
                      {get_overall_metrics_results.probability_f1_up}%
                    </p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <div>
                        <HiChevronDoubleDown color="#ee4657" />{' '}
                      </div>
                      <p className="pl-2">Down</p>
                    </div>
                    <p>
                      {/* {get_overall_metrics_results.f1_down}% -{' '} */}
                      {get_overall_metrics_results.probability_f1_down}%
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-4 gap-8 w-full">
                <div className="flex flex-col justify-between bg-transparent p-5 rounded-md col-span-1 animate-pulse">
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                </div>
                <div className="flex flex-col justify-between bg-transparent p-5 rounded-md col-span-1 animate-pulse">
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                </div>
                <div className="flex flex-col justify-between bg-transparent p-5 rounded-md col-span-1 animate-pulse">
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                </div>
                <div className="flex flex-col justify-between bg-transparent p-5 rounded-md col-span-1 animate-pulse">
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                  <div className="flex w-full justify-center items-center font-bold rounded-md bg-gray-200 h-3 my-2"></div>
                </div>
              </div>
            )}
          </div>
        </div>
        {get_feature_transforms_status == 'fulfilled' &&
        get_label_transforms_status == 'fulfilled' &&
        get_models_status == 'fulfilled' &&
        get_prediction_chart_status == 'fulfilled' ? (
          <div className="p-5 first-div text-black dark:text-white w-full mb-5 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md flex flex-col">
            <Title
              title="Actual vs. Predicted Candlestick Plot"
              subtitle="Visualizing Trends: Background Colors for Actual Values and Dots for Predicted Values"
            />
            <div className="w-full">
              <PriceChart
                options={backtest_main_chart(
                  get_prediction_chart_results.candles,
                  selected_coin.label,
                  get_prediction_chart_results.color_coded_labels,
                  get_prediction_chart_results.color_coded_predictions
                )}
              />
            </div>
          </div>
        ) : (
          <div className="p-5 animate-pulse first-div text-black dark:text-white w-full my-5 p-2 bg-light-back_secondary dark:bg-dark-back_secondary rounded-md flex flex-col">
            <Title
              title="Actual vs. Predicted Candlestick Plot"
              subtitle="Visualizing Trends: Background Colors for Actual Values and Dots for Predicted Values"
            />
            <div className="w-full h-[500px]">
              <div
                className="animate-pulse"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <TbChartLine
                  style={{
                    color: 'gray',
                    height: '45%',
                    width: '45%',
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 gap-5">
          {
            <div className="col-span-1">
              <Table
                title={'Recent Predictions Overview'}
                subtitle={
                  'Timely Insights: Displaying Recent Predictions and Probabilities for Specified Symbol, Time Frame, and Predictor'
                }
                loading={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recent_predictions_status == 'fulfilled'
                    ? false
                    : true
                }
                ActiveLookBack
                setActiveLookBack
                current_page={get_recent_predictions_page}
                set_current_page={(page) => {
                  dispatch(set_recent_predictions_current_page(page));
                }}
                page_size={5}
                total_items={get_recent_predictions_results.total_items}
                columns={recent_predictions_columns}
                rows={get_recent_predictions_results?.results?.map((x) => {
                  const item = [
                    x.timestamp || null,
                    x.prediction === 0 ? -1: x.prediction === 1 ? 1 : 'N/A',
                    x.probability || null,
                    x.actual  === 0 ? -1: x.actual === 1 ? 1 : 'N/A',
                    x.price_change || null,
                  ];
                  return item;
                })}
              />
            </div>
          }
          {
            <div className="col-span-1">
              <Table
                title={'Recommended Symbols and Time Frames'}
                subtitle={
                  'Accuracy Recommendations: Identifying the Best Symbol and Time Frame for Selected Prediction Parameters'
                }
                loading={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recommender_symbol_timeframe_status == 'fulfilled'
                    ? false
                    : true
                }
                ActiveLookBack
                setActiveLookBack
                page_size={5}
                current_page={get_recommender_symbol_timeframe_page}
                set_current_page={(page) => {
                  dispatch(set_recommender_symbol_timeframe_page(page));
                }}
                total_items={
                  get_recommender_symbol_timeframe_results.total_items
                }
                columns={recommended_symbols_timeframes_columns}
                onRowClick={(x) => combo_recommendation_clicked(x)}
                rows={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recommender_symbol_timeframe_status == 'fulfilled' &&
                  get_recommender_symbol_timeframe_results?.results?.map(
                    (x) => {
                      const symbol = x.symbol
                        ? get_coins_results?.find((y) => {
                            return y.label == x.symbol;
                          })
                        : null;
                      return [symbol, x.timeframe, x.probability_accuracy, x.start_timestamp, x.end_timestamp];
                    }
                  )
                }
              />
            </div>
          }
          {
            <div className="col-span-full">
              <Table
                title={`Time Frame Recommendation Insights`}
                subtitle={`Strategic Predictions: Top Recommendations for Enhanced Decision-Making in ${selected_timeframe.label}`}
                loading={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recommender_timeframe_status == 'fulfilled'
                    ? false
                    : true
                }
                ActiveLookBack
                setActiveLookBack
                current_page={get_recommender_timeframe_page}
                set_current_page={(page) => {
                  dispatch(set_recommender_timeframe_page(page));
                }}
                total_items={get_recommender_timeframe_results.total_items}
                columns={timeframe_recommendation_columns}
                onRowClick={(x) => timeframe_recommendation_clicked(x)}
                rows={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recommender_timeframe_status == 'fulfilled' &&
                  get_recommender_timeframe_results.results.map((x) => {
                    const symbol = x.symbol
                      ? get_coins_results.find((y) => {
                          return y.label == x.symbol;
                        })
                      : null;
                    const labeling_method = x.lt
                      ? labeling_method_options.find((y) => {
                          return y.value == x.lt;
                        }).label
                      : 'N/A';
                    const feature_set = x.ft
                      ? feature_set_options.find((y) => {
                          return y.value == x.ft;
                        }).label
                      : 'N/A';
                    const learning_model = x.dlm
                      ? learning_model_options.find((y) => {
                          return y.value == x.dlm;
                        }).label
                      : 'N/A';
                    return [
                      symbol,
                      labeling_method,
                      feature_set,
                      learning_model,
                      x.probability_accuracy,
                      x.probability_precision_up,
                      x.probability_precision_down,
                      x.start_timestamp,
                      x.end_timestamp
                    ];
                  })
                }
              />
            </div>
          }
          {
            <div className="col-span-full">
              <Table
                title={'Symbol Recommendation Insights'}
                subtitle={`Guided Predictions: Top Recommendations for Enhanced Decision-Making on ${selected_coin.label}`}
                loading={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recommender_symbol_status == 'fulfilled'
                    ? false
                    : true
                }
                ActiveLookBack
                setActiveLookBack
                current_page={get_recommender_symbol_page}
                set_current_page={(page) => {
                  dispatch(set_recommender_symbol_page(page));
                }}
                total_items={get_recommender_symbol_results.total_items}
                columns={symbol_recommendation_columns}
                onRowClick={(x)=> symbol_recommended_clicked(x)}
                rows={
                  get_feature_transforms_status == 'fulfilled' &&
                  get_label_transforms_status == 'fulfilled' &&
                  get_models_status == 'fulfilled' &&
                  get_recommender_symbol_status == 'fulfilled' &&
                  get_recommender_symbol_results?.results?.map((x) => {
                    const timeframe = x.timeframe;
                    const labeling_method = x.lt
                      ? labeling_method_options.find((y) => {
                          return y.value == x.lt;
                        }).label
                      : 'N/A';
                    const feature_set = x.ft
                      ? feature_set_options.find((y) => {
                          return y.value == x.ft;
                        }).label
                      : 'N/A';
                    const learning_model = x.dlm
                      ? learning_model_options.find((y) => {
                          return y.value == x.dlm;
                        }).label
                      : 'N/A';
                    return [
                      timeframe,
                      labeling_method,
                      feature_set,
                      learning_model,
                      x.probability_accuracy,
                      x.probability_precision_up,
                      x.probability_precision_down,
                      x.start_timestamp,
                      x.end_timestamp
                    ];
                  })
                }
              />
            </div>
          }
        </div>
      </div>
    </DashboardLayout>
  );
};

export default InDepthPage;

