import React from 'react';
import AppRoutes from './AppRoutes';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './styles/global.scss';

function App() {
  return (
    <>
      <Helmet>
        <title>FinBright</title>
      </Helmet>
      <ToastContainer position="bottom-center" theme="dark" />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<AppRoutes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
