import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/config';
import { PRED_INTERVALS, POSITION_TYPES } from '../../Constants/consts';
import { signalsToList, } from '../../Constants/screenerTable';

const initialState = {
  available_coins: [],
  selected_coins: [],
  get_coins_status: 'idle',

  available_feature_sets: [],
  selected_feature_sets: [],
  get_feature_sets_status: 'idle',

  available_labeling_methods: [],
  selected_labeling_methods: [],
  get_labeling_methods_status: 'idle',

  available_learning_models: [],
  selected_learning_models: [],
  get_learning_models_status: 'idle',

  available_strategies: [],
  selected_strategies: [],
  get_strategies_status: 'idle',

  available_position_types: [{label: "All", value: 'all'}, ...POSITION_TYPES],
  selected_position_types: [{label: "All", value: 'all'}, ...POSITION_TYPES],

  available_timeframes: [{label: "All", value: 'all'}, ...PRED_INTERVALS],
  selected_timeframes: [{label: "All", value: 'all'}, ...PRED_INTERVALS],

  signals: [],
  get_signals_status: 'idle',

  page: 1,
};

export const getFeatureTransforms = createAsyncThunk(
  'scanner/getfeaturetransforms',
  async () => {
    const response = await axiosInstance.get(
      '/prediction/featureTransforms/',
      {}
    );
    return response.data;
  }
);

export const getLabelTransforms = createAsyncThunk(
  'scanner/getlabeltransforms',
  async () => {
    const response = await axiosInstance.get(
      '/prediction/labelTransforms/',
      {}
    );
    return response.data;
  }
);

export const getModels = createAsyncThunk('scanner/getmodels', async () => {
  const response = await axiosInstance.get('/prediction/models/', {});
  return response.data;
});

export const get_strategies = createAsyncThunk(
  'scanner/get_strategies',
  async () => {
    const results = await axiosInstance.get('/strategy/strategies/');
    return results.data;
  }
);

export const get_coins = createAsyncThunk('scanner/get_coins', async () => {
  const results = await axiosInstance.get('/core/stocks/?has_predictions=true');
  return results.data;
});

export const get_signals = createAsyncThunk(
  'scanner/get_signals',
  async (data) => {
    const results = await axiosInstance.post('/strategy/recentSignals/', data);
    return results.data;
  }
);

export const pulseSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    select_coins: (state, action) => {
      state.selected_coins = action.payload;
    },
    select_feature_sets: (state, action) => {
      state.selected_feature_sets = action.payload;
    },
    select_labeling_methods: (state, action) => {
      state.selected_labeling_methods = action.payload;
    },
    select_learning_models: (state, action) => {
      state.selected_learning_models = action.payload;
    },
    select_strategies: (state, action) => {
      state.selected_strategies = action.payload;
    },
    select_signal_types: (state, action) => {
      state.selected_signal_types = action.payload;
    },
    select_position_types: (state, action) => {
      state.selected_position_types = action.payload;
    },
    select_timeframes: (state, action) => {
      state.selected_timeframes = action.payload;
    },
    select_page: (state, action) => {
      state.page = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      .addCase(get_strategies.pending, (state) => {
        state.get_strategies_status = 'pending';
      })
      .addCase(get_strategies.fulfilled, (state, action) => {
        const payload = action.payload?.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_strategies = [{value: 'all', label: 'All'}, ...payload]
        state.available_strategies = [{value: 'all', label: 'All'}, ...payload];
        state.get_strategies_status = 'fulfilled';
      })
      .addCase(get_strategies.rejected, (state) => {
        state.get_strategies_status = 'rejected';
      })
      .addCase(getFeatureTransforms.pending, (state) => {
        state.get_feature_sets_status = 'pending';
      })
      .addCase(getFeatureTransforms.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_feature_sets = [{value: 'all', label: 'All'}, ...output];
        state.available_feature_sets = [{value: 'all', label: 'All'}, ...output];
        state.get_feature_sets_status = 'fulfilled';
      })
      .addCase(getFeatureTransforms.rejected, (state) => {
        state.get_feature_sets_status = 'rejected';
      })
      .addCase(getLabelTransforms.pending, (state) => {
        state.get_labeling_methods_status = 'pending';
      })
      .addCase(getLabelTransforms.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_labeling_methods = [{value: 'all', label: 'All'}, ...output];
        state.available_labeling_methods = [{value: 'all', label: 'All'}, ...output];
        state.get_labeling_methods_status = 'fulfilled';
      })
      .addCase(getLabelTransforms.rejected, (state) => {
        state.get_labeling_methods_status = 'rejected';
      })
      .addCase(getModels.pending, (state) => {
        state.get_models_status = 'pending';
      })
      .addCase(getModels.fulfilled, (state, action) => {
        const data = action.payload;
        const output = data.map((item) => {
          return { value: item.id, label: item.short_name };
        });
        state.selected_learning_models = [{value: 'all', label: 'All'}, ...output];
        state.available_learning_models = [{value: 'all', label: 'All'}, ...output];
        state.get_models_status = 'fulfilled';
      })
      .addCase(getModels.rejected, (state) => {
        state.get_models_status = 'rejected';
      })
      .addCase(get_coins.pending, (state) => {
        state.get_coins_status = 'pending';
      })
      .addCase(get_coins.fulfilled, (state, action) => {
        state.available_coins = []
        if (action.payload.length > 0){
          state.available_coins.push({value: 'all', label: 'All'})
        }
        state.available_coins.push(...action.payload?.map((item) => {
          return {
            value: item.id,
            label: item.symbol,
          };
        }));
        state.selected_coins = state.available_coins
        state.get_coins_status = 'fulfilled';
      })
      .addCase(get_coins.rejected, (state) => {
        state.get_coins_status = 'rejected';
      })

      .addCase(get_signals.pending, (state) => {
        state.get_signals_status = 'pending';
      })
      .addCase(get_signals.fulfilled, (state, action) => {
        const payload = action.payload;
        payload.results = signalsToList(payload.results);
        state.signals = payload;
        state.get_signals_status = 'fulfilled';
      })
      .addCase(get_signals.rejected, (state) => {
        state.get_signals_status = 'rejected';
      });
  },
});

export const {
  select_coins,
  select_strategies,
  select_signal_types,
  select_position_types,
  select_timeframes,
  select_lookback,
  select_page,
  select_feature_sets,
  select_labeling_methods,
  select_learning_models,
} = pulseSlice.actions;

export default pulseSlice.reducer;
