import Highcharts from 'highcharts';

export const options = (equity, drawdown, buy_and_hold, dark=true) => {
  const series = [
    {
      name: 'Strategy Drawdown',
      // drawdown
      lineColor: false,
      fillColor: ' #342d7a ',
      color: 'purple',
      border: false,
      tooltip: false,
      yAxis: 0,
      type: 'column',
      marker: false,
      data: drawdown?.map((x) => -x),
    },
    {
      name: 'Strategy Equity',
      // strategy equity
      type: 'spline',
      color: 'blue',
      data: equity?.map((x) => Math.round(x)),
      yAxis: 1,
      marker: false,
      tooltip: false,
    },
    {
      name: 'Buy and Hold Equity',
      // buy and hold equity
      type: 'spline',
      data: buy_and_hold?.map((x) => Math.round(x)),
      color: 'red',
      yAxis: 1,
      marker: false,
      tooltip: false,
    },
  ];

  return {
    chart: {
      type: 'areaspline',
      backgroundColor: null,
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: true,
      crosshair: true,
      labels: {
        style: {
          color: dark ? '#b6b6b6' : '#666666',
        },
      },
    },
    yAxis: [
      {
        // Primary yAxis
        gridLineWidth: 0,
        labels: {
          style: {
            color: dark ? '#b6b6b6' : '#666666',
          },
        },
        title: {
          text: 'Drawdown',
          style: {
            color: dark ? 'white' : 'black',
          },
        },
        opposite: true,
        reversed: true,
        crosshair: true,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Equity',
          style: {
            color: dark ? 'white' : 'black',
          },
        },
        labels: {
          style: {
            color: dark ? '#b6b6b6' : '#666666',
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: dark ? 'white' : 'black', // Set the legend item color to white
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let s = '<b>Position ID: ' + this.x + '</b>';

        this.points.forEach(function (point) {
          s += '<br/>' + point.series.name + ': ' + point.y + ' USDT';
        });

        return s;
      },
    },
    plotOptions: {
      series: {
        setOpacity: 0,
      },
      column: {
        pointPadding: 0.1, // Adjust this value to change the column width. Smaller values result in thinner columns.
        groupPadding: 0,
        borderWidth: 0, // Set this to 0 to remove the border.
        borderRadius: 2,
        borderColor: 'transparent', // Set this to 'transparent' to make the border invisible.
      },
      area: {
        borderWidth: 0, // Set this to 0 to remove the border.
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0.75,
          },
          stops: [
            [0, '#7D7AFF'],
            [1, Highcharts.color('#1C1A23').setOpacity(0).get('rgba')],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 0,
        states: {
          hover: {
            lineWidth: 0.5,
          },
        },
        threshold: null,
      },
    },
    series: series,
  };
};
