import React, { useState } from 'react';
import Select, { components } from 'react-select';
import './style.scss';
const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </div>
      {children}
    </components.Option>
  );
};

const ButtonSelectDynamic = ({
  isSearchable = false,
  options,
  className,
  defaultValue,
  label,
  disabled,
  isRequired,
  onChange,
  placeholder = 'Not Selected',
  selectedOptions,
  isFilled,
  isMulti,
  dark = true,
  ...props
}) => {

  const handleOptionSelect = (selectedOption) => {
    if (isMulti) {
      let newSelectedOptions = [...selectedOptions];
      if (selectedOption?.value) {
        const optionIndex = newSelectedOptions.findIndex(
          (option) => option.value === selectedOption.value
        );
        if (optionIndex === -1) {
          newSelectedOptions.push(selectedOption);
          // If "all" is selected, select all options
          if (selectedOption.value === "all") {
            newSelectedOptions = options;
          }
        } else {
          newSelectedOptions.splice(optionIndex, 1);
          // If "all" is deselected, deselect all options
          if (selectedOption.value === "all") {
            newSelectedOptions = [];
          }
          // If any other option is deselected, check if all options are selected
          else {
            const allOptionIndex = newSelectedOptions.findIndex(
              (option) => option.value === "all"
            );
            if (allOptionIndex !== -1) {
              newSelectedOptions.splice(allOptionIndex, 1);
            }
          }
        }
        onChange(newSelectedOptions);
      }
    } else {
      onChange(selectedOption);
    }
  };

  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      color: dark ? '#ffffff' : '#111111'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: dark ? '#ffffff' : '#111111'
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      backgroundColor: state.isFocused ? '#4269e0' : 'transparent',
      color: dark ? '#ffffff' : '#111111'
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      backgroundColor: dark ? '#151515' : 'white', // Adjust the background color of the main frame
      // color: '#111111'

    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4269e0', // Set the background color for selected values
      display: 'flex',
      alignItems: 'center',
      padding: '2px 6px',
      borderRadius: '10px',
      margin: '2px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      paddingLeft: '4px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      paddingLeft: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#0355a0', // Set the background color when hovering over the remove button
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: 'transparent',
      boxShadow: state.isFocused ? '0 0 0 3px rgb(66, 105, 224)' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#4269e0' : '#161724',
      },
      backgroundColor: dark ? '#151515' : 'white', // Adjust the background color of the main frame
      borderRadius: '10px',
    }),
  };

  return (
    <div className={`bottom-select__container ${dark ? 'text-white' : 'text-black'}`}>
      {label && <div 
      className="bottom-select__label">{label}</div>}
      <Select
        styles={customStyles}
        components={{ Option: isMulti ? Option : components.Option }}
        isSearchable={isSearchable}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={selectedOptions}
        onChange={handleOptionSelect}
        isClearable={!isRequired}
        className={`${dark ? 'text-white' : 'text-black'}`}
      />
    </div>
  );
};

export default ButtonSelectDynamic;
