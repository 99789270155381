import { formatPythonTimestamp } from '../utils/formatTime';

const oh = [
  {
    open: 26508.8,
    close: 26329.7,
    low: 26230.9,
    high: 26709.4,
    volume: 241977,
    timestamp: 1687046400,
  },
  {
    open: 26329.6,
    close: 26828.5,
    low: 26241.2,
    high: 27077,
    volume: 400534,
    timestamp: 1687132800,
  },
  {
    open: 26828.6,
    close: 28290.6,
    low: 26633.9,
    high: 28397.8,
    volume: 758176,
    timestamp: 1687219200,
  },
  {
    open: 28290.7,
    close: 29988.5,
    low: 28236.4,
    high: 30822,
    volume: 1123474,
    timestamp: 1687305600,
  },
  {
    open: 29988.5,
    close: 29875,
    low: 29500.1,
    high: 30488,
    volume: 561052,
    timestamp: 1687392000,
  },
  {
    open: 29875,
    close: 30673.4,
    low: 29792,
    high: 31500,
    volume: 699073,
    timestamp: 1687478400,
  },
  {
    open: 30673.5,
    close: 30524.2,
    low: 30222,
    high: 30796.5,
    volume: 279539,
    timestamp: 1687564800,
  },
  {
    open: 30524.2,
    close: 30458,
    low: 30252.6,
    high: 31053.2,
    volume: 301533,
    timestamp: 1687651200,
  },
  {
    open: 30458,
    close: 30260.1,
    low: 29890.1,
    high: 30667.3,
    volume: 415099,
    timestamp: 1687737600,
  },
  {
    open: 30260.1,
    close: 30683.2,
    low: 30213,
    high: 30998.5,
    volume: 406073,
    timestamp: 1687824000,
  },
  {
    open: 30683.2,
    close: 30066.3,
    low: 29804.6,
    high: 30701.8,
    volume: 416656,
    timestamp: 1687910400,
  },
  {
    open: 30066.3,
    close: 30439.9,
    low: 30036.1,
    high: 30837,
    volume: 382073,
    timestamp: 1687996800,
  },
  {
    open: 30439.9,
    close: 30460.2,
    low: 29500,
    high: 31300,
    volume: 809331,
    timestamp: 1688083200,
  },
  {
    open: 30460.2,
    close: 30573.6,
    low: 30311.3,
    high: 30668.2,
    volume: 135520,
    timestamp: 1688169600,
  },
  {
    open: 30573.6,
    close: 30612.7,
    low: 30149.9,
    high: 30800,
    volume: 231866,
    timestamp: 1688256000,
  },
  {
    open: 30612.7,
    close: 31149,
    low: 30559.4,
    high: 31395.2,
    volume: 370293,
    timestamp: 1688342400,
  },
  {
    open: 31149,
    close: 30756.1,
    low: 30600,
    high: 31319.4,
    volume: 300832,
    timestamp: 1688428800,
  },
  {
    open: 30756.1,
    close: 30488.4,
    low: 30175.8,
    high: 30875.2,
    volume: 294897,
    timestamp: 1688515200,
  },
  {
    open: 30488.4,
    close: 29874.4,
    low: 29818,
    high: 31568,
    volume: 721267,
    timestamp: 1688601600,
  },
  {
    open: 29874.3,
    close: 30327.9,
    low: 29680,
    high: 30443.6,
    volume: 337802,
    timestamp: 1688688000,
  },
  {
    open: 30328,
    close: 30269.3,
    low: 30026.8,
    high: 30380,
    volume: 138734,
    timestamp: 1688774400,
  },
  {
    open: 30269.2,
    close: 30147.8,
    low: 30042,
    high: 30443.4,
    volume: 162296,
    timestamp: 1688860800,
  },
  {
    open: 30147.8,
    close: 30396.9,
    low: 29928.8,
    high: 31040,
    volume: 429116,
    timestamp: 1688947200,
  },
  {
    open: 30396.9,
    close: 30608.4,
    low: 30261.4,
    high: 30804.9,
    volume: 298905,
    timestamp: 1689033600,
  },
  {
    open: 30608.3,
    close: 30368.9,
    low: 30186,
    high: 30980.5,
    volume: 425058,
    timestamp: 1689120000,
  },
  {
    open: 30368.9,
    close: 31441.7,
    low: 30233,
    high: 31850,
    volume: 696024,
    timestamp: 1689206400,
  },
  {
    open: 31441.6,
    close: 30293.3,
    low: 29876.6,
    high: 31640,
    volume: 538692,
    timestamp: 1689292800,
  },
  {
    open: 30293.3,
    close: 30276.4,
    low: 30220.2,
    high: 30380,
    volume: 111622,
    timestamp: 1689379200,
  },
  {
    open: 30276.5,
    close: 30216.8,
    low: 30050,
    high: 30441.6,
    volume: 173806,
    timestamp: 1689465600,
  },
];

const trade_list = [
  {
    id: 'aed7d6b0-d2b0-4416-98a8-52ce908bfba8',
    trade_number: 1,
    type: 'long',
    entry_timestamp: 1698826997000,
    exit_timestamp: 1699044652000,
    entry_price: 54718.0,
    exit_price: 40802.0,
    pnl: 94.0,
  },
  {
    id: '2bfb4f85-d35d-412e-a278-64750f9d8789',
    trade_number: 2,
    type: 'short',
    entry_timestamp: 1698997311000,
    exit_timestamp: 1700033448000,
    entry_price: 57021.0,
    exit_price: 57794.0,
    pnl: 4.0,
  },
  {
    id: '9c0f97d8-ac88-4f3d-81a8-11b74f1b0a2a',
    trade_number: 3,
    type: 'long',
    entry_timestamp: 1699039371000,
    exit_timestamp: 1699814656000,
    entry_price: 41670.0,
    exit_price: 31005.0,
    pnl: 67.0,
  },
  {
    id: '05061fb6-aa3c-4f7c-8a84-faf5c648c301',
    trade_number: 4,
    type: 'long',
    entry_timestamp: 1699320776000,
    exit_timestamp: 1699241893000,
    entry_price: 76009.0,
    exit_price: 10100.0,
    pnl: 7.0,
  },
  {
    id: 'b7637b4a-340b-4a96-b578-a5bf024251ab',
    trade_number: 5,
    type: 'long',
    entry_timestamp: 1699394878000,
    exit_timestamp: 1699990957000,
    entry_price: 85216.0,
    exit_price: 17910.0,
    pnl: 75.0,
  },
  {
    id: 'b41e5653-2509-4f48-a2dd-9365a9e72027',
    trade_number: 6,
    type: 'short',
    entry_timestamp: 1699398720000,
    exit_timestamp: 1700170115000,
    entry_price: 90533.0,
    exit_price: 38272.0,
    pnl: 92.0,
  },
  {
    id: '7de40f44-4ca1-44ca-91da-3427039a4f6c',
    trade_number: 7,
    type: 'short',
    entry_timestamp: 1699525842000,
    exit_timestamp: 1700035528000,
    entry_price: 82990.0,
    exit_price: 68143.0,
    pnl: 13.0,
  },
  {
    id: '37aecce7-76df-4d66-b530-d74c0797897c',
    trade_number: 8,
    type: 'long',
    entry_timestamp: 1699534057000,
    exit_timestamp: 1700236609000,
    entry_price: 9231.0,
    exit_price: 57447.0,
    pnl: 54.0,
  },
  {
    id: 'ae37e0dc-7609-4352-89b0-d2367089969f',
    trade_number: 9,
    type: 'long',
    entry_timestamp: 1699650828000,
    exit_timestamp: 1699486078000,
    entry_price: 80444.0,
    exit_price: 71234.0,
    pnl: 43.0,
  },
  {
    id: 'a006a354-6414-4583-9f82-bd254e32128f',
    trade_number: 10,
    type: 'short',
    entry_timestamp: 1700121695000,
    exit_timestamp: 1699951354000,
    entry_price: 13302.0,
    exit_price: 33680.0,
    pnl: 30.0,
  },
  {
    id: '1da1acba-ffff-4b59-ac92-0080e9b79172',
    trade_number: 11,
    type: 'long',
    entry_timestamp: 1700266782000,
    exit_timestamp: 1699452019000,
    entry_price: 51705.0,
    exit_price: 82440.0,
    pnl: 47.0,
  },
];

export const backtest_main_chart = (
  ohlc = oh,
  symbol = "",
  trades_list = trade_list,
  dots = [],
  theme = 'dark'
) => {

  const series = ohlc.map((x) => {
    return [x.timestamp * 1000, parseFloat(x.open), parseFloat(x.high), parseFloat(x.low), parseFloat(x.close)];
  })
  return {
    chart: {
      backgroundColor: 'transparent',
      height: 500,
      zooming: true,
    },
    tooltip: {
      split: true,
      xDateFormat: '%Y-%m-%d %H:%M:%S',
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 4,
          symbol: 'circle',
        },
      },
      series: {
        marker: {
          enabled: true,
        },
        animation: {
          duration: 2000,
        },
        states: {
          hover: {
            enabled: false,
          },
          normal: {
            animation: {
              duration: 50,
            },
          },
          select: {
            enabled: true,
          },
        },
      },
    },
    rangeSelector: {
      enabled: false,
    },
    stockTools: {
      gui: {
        buttons: ['indicators', 'typeChange', 'simpleShapes', 'lines'],
      },
    },
    navigator: {
      enabled: true,
    },
    scrollbar: {
      enabled: true,
    },
    xAxis: {
      visible: true,
      plotBands: trades_list,
      type: 'datetime'

    },
    yAxis: [
      {
        // max: maxy,
        // min: miny,
        opposite: false,
        gridLineWidth: 0,
        lineColor: '#cccccc',
        lineWidth: 1,
        labels: {
          align: 'right',
        },
        height: '100%',
        resize: {
          enabled: false,
        },
      },
      {
        visible: false,
        crosshair: true,
        min: 0,
        max: 1
      }
    ],
    series: [
      {
        type: 'candlestick',
        id: symbol,
        name: symbol,
        data: series,
        color: '#e24648',
        upColor: '#21cc6d',
        lineColor: '#e24648',
        upLineColor: '#21cc6d',
        pointPadding: 0.0,
      },
      {
        name: "",
        type: 'scatter',
        data: dots,
        yAxis: 1,
        xAxis: 0,
        marker: {
          radius: 4, // Set the desired marker size here
          symbol: 'circle'
        },
        tooltip: {
          pointFormatter: function() {
            return 'Prediction: ' + this.prediction +
                   '<br/>Actual: ' + this.actual +
                   '<br/>Probability: ' + this.probability;
          }
        }
      }

    ],
  };
};


